import {Button} from 'components/page';
import styled from 'styled-components';

type StyledDefaultProgramButtonProps = {
  isCurrentDefaultProgram: boolean;
  willBeDefaultProgram?: boolean;
};

export const DefaultProgramButton = styled(Button)`
  background: transparent;
  color: ${(props: StyledDefaultProgramButtonProps) =>
    props.isCurrentDefaultProgram ? '#21324c' : props.willBeDefaultProgram ? '#EB5757' : '#2c98f0'};
  cursor: ${(props: StyledDefaultProgramButtonProps) => props.isCurrentDefaultProgram && 'default'};
  margin-bottom: 8px;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  justify-content: flex-start;
  width: max-content;
  user-select: none;

  :hover {
    text-decoration: ${(props: StyledDefaultProgramButtonProps) => (props.isCurrentDefaultProgram ? 'none' : 'underline')}
`;

export const DefaultProgramButtonContainer = styled.div`
  margin-bottom: 38px;
`;

export const DefaultProgramChoiceButtonsContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  margin-top: 40px;
`;

export const DefaultProgramSelectCancelButton = styled(Button)`
  background: #ffffff;
  border: 1px solid #2c98f0;
  color: #2c98f0;
`;

export const DefaultProgramSelectYesButton = styled(Button)`
  background: #2c98f0;
  color: #ffffff;
`;

export const modalStyle = {
  content: {
    border: 'none',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    opacity: '100%',
    width: '500px',
    transform: 'translate(-50%, -50%)',
  },
  overlay: {
    backgroundColor: 'rgba(30, 30, 30, 0.50)',
  },
};
