import React from 'react';
import {
  SidebarContainer,
  SidebarBorder,
  SidebarMargin,
  SidebarLine,
  SidebarButton,
  SidebarButtonSpan,
  SidebarSVG,
  SidebarContent,
} from './styles';

type GeneralSidebarProps = {
  isActive?: boolean;
  onClick: any;
};

export const GeneralSidebar: React.FC<GeneralSidebarProps> = ({isActive, children, onClick}) => {
  //isActive is a boolean indicating if some Item is selected so it displays Sidebar content.
  //onClick must set to null the selected item (that is stored in state variable).
  return (
    <React.Fragment>
      <SidebarContainer>
        <SidebarBorder onClick={onClick}>
          <SidebarMargin />
          <SidebarLine />
          <SidebarButton isActive={isActive}>
            <SidebarButtonSpan>
              <SidebarSVG>
                <path
                  d='M10.294 9.698a.988.988 0 0 1 0-1.407 1.01 1.01 0 0 1 1.419 0l2.965 2.94a1.09 1.09 0 0 1 0 1.548l-2.955 2.93a1.01 1.01 0 0 1-1.42 0 .988.988 0 0 1 0-1.407l2.318-2.297-2.327-2.307z'
                  fill='currentColor'
                  fillRule='evenodd'
                ></path>
              </SidebarSVG>
            </SidebarButtonSpan>
          </SidebarButton>
        </SidebarBorder>
        <SidebarContent isActive={isActive}>{isActive && children}</SidebarContent>
      </SidebarContainer>
    </React.Fragment>
  );
};
export default GeneralSidebar;
