import React, {FC, createContext, useState} from 'react';

export interface AuthContextProps {
  isAuthenticated: boolean;
  setIsAuthenticated: (isAuthenticated: boolean) => void;
}

const initialContext: AuthContextProps = {
  isAuthenticated: false,
  setIsAuthenticated: () => {},
};

export const AuthContext = createContext<AuthContextProps>(initialContext);

export const AuthProvider: FC = ({children}) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const auth = {isAuthenticated, setIsAuthenticated};

  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};
