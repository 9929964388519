import React, {useEffect, useState} from 'react';
import {
  Image,
  CardThumbnail,
  CardThumbnailVideo,
  ActionButtonsContainer,
  FilterContainer,
  SelectImageBox,
  CloseModal,
} from './styles';
import Modal from 'react-modal';
import {CancelButton, SaveButton} from 'components/page';
import SearchBar from 'components/search-bar';
import {Checkbox} from 'primereact/checkbox';
import {Button} from 'primereact/button';
import {listMedias} from 'api/chatowl.api';
import {ImageCropperManager} from 'pages/media-bank/crop-manager';
import {capitalize} from 'utils/helpers';
import VideoPlayer from 'components/media/video-player';
import AudioPlayer from 'components/media/audio-player';
import {selectMedia} from 'store/action-creators';
const style = {
  content: {
    border: 'none',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    opacity: '100%',
    width: '650px',
    height: '650px',
    transform: 'translate(-50%, -50%)',
  },
  overlay: {
    backgroundColor: 'rgba(30, 30, 30, 0.50)',
  },
};

type MediaChooserProps = {
  setMediaId: React.Dispatch<React.SetStateAction<number | undefined>>;
  mediaType: MediaType;
  media?: MediaDto;
  setNewCrops?: React.Dispatch<React.SetStateAction<Crop[]>>;
  imagesTypes?: CropType[];
};

export const MediaChooser: React.FC<MediaChooserProps> = ({setMediaId, media, mediaType, setNewCrops, imagesTypes}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [hideUsed, setHideUsed] = useState(true);
  const [medias, setMedias] = useState<MediaDto[]>([]);
  const [selectedMedia, setSelectedMedia] = useState<MediaDto>();
  const [originalImage, setOriginalImage] = useState<Blob>();
  const [crops, setCrops] = useState<Crop[]>([]);

  useEffect(() => {
    if (media) setSelectedMedia(media);
  }, [media]); //When it was "[]", media was undefined

  const Card = (media: MediaDto) => {
    const selectMedia = () => {
      setSelectedMedia(media);
      setMediaId(media.id);
      onCancelModal();
    };
    return (
      <>
        {media.type === 'image' && (
          <div className='p-col-fixed'>
            <Image onClick={selectMedia}>
              <CardThumbnail width='100%' height='100%' src={media?.thumbnailUrl} />{' '}
            </Image>
          </div>
        )}
        {media.type === 'audio' && (
          <div className='p-col-fixed'>
            <Image onClick={selectMedia}>
              <CardThumbnailVideo width='100%' height='100%' poster={media.thumbnailUrl} controls key={media.url}>
                <source src={media.url} type='audio/mp3'></source>
                Your browser does not support the video tag.
              </CardThumbnailVideo>
            </Image>
          </div>
        )}
        {media.type === 'video' && (
          <div className='p-col-fixed'>
            <Image onClick={selectMedia}>
              <CardThumbnailVideo width='100%' height='100%' poster={media.thumbnailUrl} controls key={media.url}>
                <source src={media.url} type='video/mp4'></source>
                Your browser does not support the video tag.
              </CardThumbnailVideo>
            </Image>
          </div>
        )}
      </>
    );
  };

  const EmptyCard = () => {
    return (
      <div className='p-col-fixed' style={{visibility: 'hidden'}}>
        <Image>
          <CardThumbnail width='100%' height='100%' src='' />
        </Image>
      </div>
    );
  };

  const onOpenModal = () => {
    //TODO: get images from media-bank
    fetchImages();
    setNewCrops && setNewCrops([]);
    setIsOpen(true);
  };

  const onCancelModal = () => {
    setIsOpen(false);
  };

  const onChangeSearchValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const fetchImages = async () => {
    try {
      const response = (await listMedias(undefined, 'image')) as any;
      const mediasResponse = response.data ? response.data.data : [];
      setMedias(mediasResponse);
    } catch (error) {
      throw error; // TODO
    }
  };

  const onChangeHideUsedValue = () => {
    setHideUsed(!hideUsed);
  };

  const addCrop = (newCrop: Crop) => {
    const newCrops = crops.some((crop) => crop.type === newCrop.type) // if exist crop
      ? crops.map((crop) => (crop.type === newCrop.type ? newCrop : crop)) // replace crop
      : [...crops, newCrop]; // else push crop
    setCrops(newCrops);
    setNewCrops && setNewCrops(newCrops);
  };

  const mediasFilter = medias
    .filter((x) => (hideUsed ? x.isInUse === false : x))
    .filter((x) => x.name.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()))
    .filter((media) => media.type === mediaType);

  return (
    <div>
      {selectedMedia ? (
        <>
          <ImageCropperManager
            mediaType={mediaType}
            setOriginalImage={setOriginalImage}
            addCrop={addCrop}
            image={selectedMedia.thumbnailUrl}
            imagesCrop={selectedMedia.crops}
            onUpdateImage={onOpenModal}
            imagesTypes={imagesTypes}
          >
            <SelectImageBox onClick={onOpenModal}>
              <p>Select an image queq</p>
              <Button className='p-button-outlined p-mr-2 p-mb-2'> Select an image </Button>
            </SelectImageBox>
          </ImageCropperManager>

          {selectedMedia.type === 'audio' && (
            <>
              <label>Media File</label>
              <div>
                <AudioPlayer audioSrc={selectedMedia.url} />
              </div>
            </>
          )}

          {selectedMedia.type === 'video' && (
            <>
              <label>Media File</label>
              <div>
                <VideoPlayer videoSrc={selectedMedia.url} />
                {selectedMedia.portraitVideoUrl && <VideoPlayer videoSrc={selectedMedia.portraitVideoUrl} />}
              </div>
            </>
          )}
        </>
      ) : (
        //Agregar Media
        <SelectImageBox onClick={onOpenModal}>
          <p>
            Select {mediaType === 'video' ? 'a ' : 'an '} {capitalize(mediaType)}
          </p>
          <Button className='p-button-outlined p-mr-2 p-mb-2'>
            Select {mediaType === 'video' ? 'a ' : 'an '} {capitalize(mediaType)}{' '}
          </Button>
        </SelectImageBox>
      )}

      <Modal isOpen={isOpen} onRequestClose={onCancelModal} contentLabel='Select image' style={style}>
        <CloseModal onClick={onCancelModal}>x</CloseModal>
        <div style={{textAlign: 'center', margin: '20px'}}>
          <h5>
            Select {mediaType === 'video' ? 'a ' : 'an '} {capitalize(mediaType)}
          </h5>
        </div>
        <FilterContainer>
          <SearchBar value={searchValue} onChange={onChangeSearchValue} />
          <div>
            <Checkbox checked={hideUsed} onChange={onChangeHideUsedValue} />
            <label style={{marginLeft: '8px'}}>Hide in use</label>
          </div>
        </FilterContainer>
        <div className='p-grid p-justify-center' style={{height: '75%', overflow: 'auto'}}>
          {/* Add new image 
          <div className='p-col-fixed'>
            <Image>
              <div style={{marginTop: '25%'}}>
                <svg width='33' height='32' viewBox='0 0 33 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
                  <path
                    d='M16.4688 31.4062C25.0469 31.4062 32.1406 24.3125 32.1406 15.7344C32.1406 7.15625 25.0312 0.0625 16.4531 0.0625C7.875 0.0625 0.796875 7.15625 0.796875 15.7344C0.796875 24.3125 7.875 31.4062 16.4688 31.4062ZM16.4688 29.3906C8.90625 29.3906 2.82812 23.2969 2.82812 15.7344C2.82812 8.17188 8.89062 2.09375 16.4531 2.09375C24.0156 2.09375 30.1094 8.17188 30.1094 15.7344C30.125 23.2969 24.0156 29.3906 16.4688 29.3906ZM16.4375 23.1562C17.0781 23.1562 17.4688 22.7188 17.4688 22.0469V16.75H22.9219C23.5781 16.75 24.0469 16.375 24.0469 15.7656C24.0469 15.125 23.6094 14.7344 22.9219 14.7344H17.4688V9.26562C17.4688 8.57812 17.0781 8.14062 16.4375 8.14062C15.8125 8.14062 15.4531 8.59375 15.4531 9.26562V14.7344H9.98438C9.3125 14.7344 8.85938 15.125 8.85938 15.7656C8.85938 16.375 9.34375 16.75 9.98438 16.75H15.4531V22.0469C15.4531 22.7031 15.8125 23.1562 16.4375 23.1562Z'
                    fill='#2C98F0'
                  />
                </svg>
              </div>
            </Image>
          </div> */}
          {mediasFilter.map(Card)}
          <EmptyCard />
        </div>
        <ActionButtonsContainer>
          <CancelButton onClick={onCancelModal}>Cancel</CancelButton>
          <SaveButton>Next</SaveButton>
        </ActionButtonsContainer>
      </Modal>
    </div>
  );
};
export default MediaChooser;
