/* eslint-disable react-hooks/exhaustive-deps */
import {getTherapyPrograms} from 'api/chatowl.api';
import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import styled from 'styled-components';
import {Button, PageContainer, PageHeader, PageTitle, PageListWrapper as ProgramListWrapper} from 'components/page';
import {useHistory} from 'react-router-dom';

import {RootState} from 'index';
import {useAppDispatch, useAppSelector} from 'hooks';
import {shallowEqual} from 'react-redux';
import {selectProgram, unselectProgram} from 'store/action-creators';

type ProgramCardContainerType = {
  isSelected: boolean;
};

type StyledFilterProps = {
  isSelected?: boolean;
};

const ProgramCardContainer = styled.div`
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  border-color: ${(props: ProgramCardContainerType) => (props.isSelected ? '#2196F3' : 'transparent')};
  border-style: solid;
  border-width: 2px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  min-height: 120px;
  margin-top: 8px;
  margin-bottom: 8px;
  max-height: 120px;
  min-width: 0;
  padding: 16px;
  transition: 0.1s;
  user-select: none;

  :hover {
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
  }
`;

type ProgramImageProps = {
  isSidebarOpen: boolean;
};

const ProgramImage = styled.img`
  align-self: center;
  border-radius: 4px;
  height: 88px;
  min-width: ${(props: ProgramImageProps) => (props.isSidebarOpen ? '88px' : '247px')};
  max-width: ${(props: ProgramImageProps) => (props.isSidebarOpen ? '88px' : '247px')};
  object-fit: cover;
`;

const ProgramTitle = styled.span`
  display: block;
  font-style: normal;
  font-weight: bold;
  font-size: 21px;
  margin-left: 8px;
  margin-right: 16px;
  max-height: 26px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const Label = styled.span`
  align-items: center;
  background-color: ${(props) => props.color};
  border-radius: 3px;
  color: white;
  display: flex;
  font-style: normal;
  font-weight: bold;
  font-size: 10.5px;
  flex-direction: row;
  flex-shrink: 0;
  height: 18px;
  justify-content: center;
  left: 0px;
  line-height: 10px;
  margin: 5px 16px;
  padding: 4px 8px;
  text-transform: capitalize;
  top: 0px;
  width: 66px;
`;

const ProgramDescription = styled.div`
  color: #6c757d;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  margin: 8px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  @supports (-webkit-line-clamp: 2) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
`;

const Filters = styled.div`
  display: flex;
  flex-direction: row;
`;

const StyledFilter = styled.div`
  align-items: center;
  background: ${(props: StyledFilterProps) => (props.isSelected ? '#293b55' : 'white')};
  border-color: ${(props: StyledFilterProps) => (props.isSelected ? '#293b55' : '#ced4da')};
  border-width: 1px;
  border-style: solid;
  border-radius: 20px;
  box-shadow: ${(props: StyledFilterProps) => props.isSelected && '0px 2px 8px rgba(0, 0, 0, 0.1)'};
  color: ${(props: StyledFilterProps) => (props.isSelected ? 'white' : '#293b55')};
  cursor: pointer;
  display: flex;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  transition: 0.1s;
  margin: 1px 4px;
  justify-content: center;
  padding: 4px 24px;
  text-align: center;
  text-transform: capitalize;
  transition: 0.2s;
  user-select: none;
  width: 100px;

  :not(:hover) {
    box-shadow: inset 0px 2px 8px rgba(0, 0, 0, 0.01);
  }

  :hover {
    background: ${(props: StyledFilterProps) => !props.isSelected && '#fafafa'};
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.03);
  }
`;

const ProgramList = styled.div`
  display: table;
  table-layout: fixed;
  width: 100%;
`;

type SidebarProps = {
  isActive?: boolean;
};

const SidebarContent = styled.div`
  bottom: 0px;
  top: 0px;
  right: 0px;
  max-width: 500px;
  min-width: 75px;
  width: ${(props: SidebarProps) => (props.isActive ? '500px' : '75px')};
  padding: 32px 28px 32px 40px;
  position: relative;
  transition: left 0.3s ease-in-out;
`;

const SidebarLine = styled.div`
  background: rgba(0, 0, 0, 0)
    linear-gradient(to left, rgba(0, 0, 0, 0.2) 0px, rgba(0, 0, 0, 0.2) 1px, rgba(0, 0, 0, 0.1) 1px, rgba(0, 0, 0, 0) 100%) repeat
    scroll 0% 0%;
  bottom: 0px;
  right: -3px;
  opacity: 0.5;
  pointer-events: none;
  position: absolute;
  top: 0px;
  transition-duration: 0.22s;
  transition-property: right, opacity, width;
  transition-timing-function: cubic-bezier(0.2, 0, 0, 1);
  width: 3px;
`;

type SelectedProgramSidebarProps = {
  isActive?: boolean;
};

const SidebarContainer = styled.div`
  display: flex;
  position: relative;
  top: 0px;
  bottom: 0px;
  right: 0px;
`;

const SidebarButton = styled.button`
  background: white none repeat scroll 0px center;
  border: 0px none;
  border-radius: 50%;
  box-shadow: rgba(9, 30, 66, 0.08) 0px 0px 0px 1px, rgba(9, 30, 66, 0.08) 0px 2px 4px 1px;
  color: rgb(107, 119, 140);
  cursor: ${(props: SidebarProps) => props.isActive && 'pointer'};
  height: 24px;
  opacity: ${(props: SidebarProps) => (props.isActive ? '1' : '0')};
  outline: currentcolor none 0px;
  padding: 0px;
  position: absolute;
  top: 32px;
  transition: background-color 100ms linear 0s, color 100ms linear 0s, opacity 300ms cubic-bezier(0.2, 0, 0, 1) 0s,
    transform 300ms cubic-bezier(0.2, 0, 0, 1) 0s;
  transform: translateX(50%);
  width: 24px;

  :hover {
    background: #293b55 none repeat scroll 0px center;
    color: white;
  }
`;

const SidebarButtonSpan = styled.span`
  color: currentcolor;
  display: inline-block;
  fill: rgb(255, 255, 255);
  flex-shrink: 0;
  line-height: 1;
  height: 45px;
`;

const SidebarSVG = styled.svg`
  max-height: 100%;
  max-width: 100%;
  overflow: hidden;
  pointer-events: none;
  vertical-align: bottom;
`;

const SidebarBorder = styled.div`
  bottom: 0px;
  position: relative;
  top: 0px;
  transform: translateZ(0px);
  width: 24px;
  z-index: 3;
`;

const SidebarMargin = styled.div`
  right: -4px;
  position: relative;
  width: 24px;
  height: 100%;
`;

const PDHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const PDTitle = styled.div`
  color: #010101;
  font-style: normal;
  font-size: 24px;
  font-weight: 500;
  line-height: 29px;
  margin-bottom: 8px;
`;

const PDButtonLink = styled.div`
  color: #2c98f0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-size: 14px;
  font-weight: normal;
  line-height: 17px;
  padding: 10px;
  user-select: none;

  :hover {
    text-decoration: underline;
  }
`;

const PDID = styled.div`
  color: #6c757d;
  font-style: normal;
  font-size: 14px;
  font-weight: normal;
  line-height: 21px;
  margin-bottom: 24px;

  :before {
    content: 'ID: ';
  }
`;

const PDField = styled.div`
  margin-bottom: 32px;
`;

const PDFieldLabel = styled.div`
  color: #6c757d;
  font-style: normal;
  font-weight: bold;
  line-height: 16px;
  font-size: 12px;
  margin-bottom: 8px;
  user-select: none;
`;

const PDFieldValue = styled.div`
  color: #010101;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  font-size: 16px;
`;

const PDFieldImage = styled.img`
  width: 400px;
`;

const ProgramCardInfo = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 0px;
`;

const ProgramCardHeader = styled.div`
  display: flex;
  min-width: 0;
  max-width: 100%;
`;

const ListControls = styled.div`
  display: flex;
  margin-bottom: 24px;
  justify-content: space-between;
`;

const StyledSearchBar = styled.div`
  align-items: center;
  background-color: white;
  border: 1px solid #ced4da;
  box-sizing: border-box;
  border-radius: 3px;
  color: #495057;
  cursor: text;
  display: inline-flex;
  flex: 1;
  margin-left: 20px;
  min-width: 50px;
  max-width: 400px;
  overflow: hidden;
  position: relative;
  transition: 0.1s;

  :hover:not(:focus-within) {
    background-color: #fafafa;
  }

  i {
    cursor: text;
    display: flex;
    margin: 0 8px 0 20px;
  }

  input {
    background: transparent;
    border: 0;
    font-size: 14px;
    line-height: 17px;
    padding: 8px;
    position: relative;
    width: 100%;
  }

  input:focus {
    outline: none;
  }

  :focus-within {
    border: 1px solid #293b55;
  }
`;

type SearchBarProps = {
  value?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const SearchBar: React.FC<SearchBarProps> = ({value, onChange}) => {
  return (
    <StyledSearchBar>
      <label htmlFor='inputSearch'>
        <i className='pi pi-search'></i>
      </label>
      <input type='search' id='inputSearch' placeholder='Search' value={value} onChange={onChange} />
    </StyledSearchBar>
  );
};

type ProgramDetailsProps = {
  program: ProgramDto | null;
};

const ProgramDetails: React.FC<ProgramDetailsProps> = ({program}) => {
  const history = useHistory();

  const media = program?.media;

  return (
    <div>
      <div>
        <PDHeader>
          <PDTitle>Therapy Program Info</PDTitle>
          <PDButtonLink onClick={() => history.push('/therapy-program/' + program?.id)}>Detail View</PDButtonLink>
        </PDHeader>
        <PDID>{program?.id}</PDID>
      </div>
      <div>
        <PDField>
          <PDFieldLabel>Name</PDFieldLabel>
          <PDFieldValue>{program?.name}</PDFieldValue>
        </PDField>
        <PDField>
          <PDFieldLabel>Description</PDFieldLabel>
          <PDFieldValue>{program?.description}</PDFieldValue>
        </PDField>
        <PDField>
          <PDFieldLabel>Image formats</PDFieldLabel>
          <div style={{display: 'flex', flexDirection: 'column'}}>
            {media?.crops.find((crop) => crop.type === 'full_width_regular') && (
              <img
                style={{width: '90%', marginTop: '0px'}}
                src={media?.crops.find((crop) => crop.type === 'full_width_regular')?.url}
                alt={media?.name}
              ></img>
            )}
            {media?.crops.find((crop) => crop.type === 'full_width_tall') && (
              <img
                style={{maxWidth: '60%', marginTop: '10px', marginBottom: '10px'}}
                src={media?.crops.find((crop) => crop.type === 'full_width_tall')?.url}
                alt={media?.name}
              ></img>
            )}
          </div>
        </PDField>
      </div>
    </div>
  );
};

const AddProgramButton = styled(Button)`
  background: #2c98f0;
  color: white;
  text-decoration: none;

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

    :focus,
    :hover,
    :visited,
    :link,
    :active {
      color: white;
      text-decoration: none;
    }
  }
`;

const ProgramNotFound = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #495057;
  height: 50vh;

  :after {
    content: 'No Therapy Programs';
  }
`;

export const TherapyPrograms: React.FC = () => {
  const [therapyPrograms, setTherapyPrograms] = useState<ProgramDto[]>([]);
  const [selectedFilters, setSelectedFilters] = useState<ProgramStatus[]>([]);
  const [searchValue, setSearchValue] = useState<string>('');
  const selectedProgram: ProgramDto | null = useAppSelector((state: RootState) => state.programs.selectedProgram, shallowEqual);
  const dispatch = useAppDispatch();

  const SelectedProgramSidebar: React.FC<SelectedProgramSidebarProps> = ({isActive, children}) => {
    const onClick = () => {
      dispatch(unselectProgram());
    };

    return (
      <React.Fragment>
        <SidebarContainer>
          <SidebarBorder onClick={onClick}>
            <SidebarMargin />
            <SidebarLine />
            <SidebarButton isActive={isActive}>
              <SidebarButtonSpan>
                <SidebarSVG>
                  <path
                    d='M10.294 9.698a.988.988 0 0 1 0-1.407 1.01 1.01 0 0 1 1.419 0l2.965 2.94a1.09 1.09 0 0 1 0 1.548l-2.955 2.93a1.01 1.01 0 0 1-1.42 0 .988.988 0 0 1 0-1.407l2.318-2.297-2.327-2.307z'
                    fill='currentColor'
                    fillRule='evenodd'
                  ></path>
                </SidebarSVG>
              </SidebarButtonSpan>
            </SidebarButton>
          </SidebarBorder>
          <SidebarContent isActive={isActive}>{isActive && children}</SidebarContent>
        </SidebarContainer>
      </React.Fragment>
    );
  };

  const Filter = (filter: ProgramStatus) => {
    const toggleFilters = () => {
      selectedFilters.some((f) => f === filter)
        ? setSelectedFilters(selectedFilters.filter((f) => f !== filter)) // turn off
        : setSelectedFilters([...selectedFilters, filter]); // turn on
    };

    return (
      <StyledFilter key={filter} onClick={toggleFilters} isSelected={selectedFilters.some((f) => f === filter)}>
        {filter}
      </StyledFilter>
    );
  };

  const filters: ProgramStatus[] = ['archived', 'published', 'draft'];

  const filterByStatus = (program: ProgramDto) => !selectedFilters.length || selectedFilters.some((f) => f === program.status);

  const filterBySearchValue = (program: ProgramDto) =>
    !searchValue.toLowerCase() ||
    program.name.toLowerCase().includes(searchValue.toLowerCase()) ||
    program.description?.toLowerCase().includes(searchValue.toLowerCase()) ||
    program.status.toLowerCase().includes(searchValue.toLowerCase());

  useEffect(() => {
    const fetchTherapyPrograms = async () => {
      try {
        const response = (await getTherapyPrograms()) as any;
        const therapyPrograms = response.data.data;
        setTherapyPrograms(therapyPrograms);
      } catch (error) {
        throw error; // TODO
      }
    };
    fetchTherapyPrograms();
  }, []);

  useEffect(() => {
    if (!selectedFilters.some((f) => f === selectedProgram?.status)) {
      dispatch(unselectProgram());
    }
  }, [selectedFilters]);

  const onSearchValueChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setSearchValue(e.target.value);
  };

  const ProgramCard = (program: ProgramDto) => {
    const getColorByStatus = (status: ProgramStatus): string => {
      switch (status) {
        case 'archived':
          return '#D563FF';
        case 'draft':
          return '#038C8D';
        case 'published':
          return '#02ACEC';
        default:
          return '#02ACEC';
      }
    };

    const isSelected = selectedProgram?.id === program.id;

    const onProgramClick = (): void => {
      program.id !== selectedProgram?.id ? dispatch(selectProgram(program)) : dispatch(unselectProgram());
    };

    return (
      <ProgramCardContainer key={program.id} onClick={onProgramClick} isSelected={isSelected}>
        <ProgramCardInfo>
          <ProgramCardHeader>
            <ProgramTitle title={program.name}>{program.name}</ProgramTitle>
            <Label color={getColorByStatus(program.status)}>{program.status}</Label>
          </ProgramCardHeader>
          <ProgramDescription title={program.description}>{program.description}</ProgramDescription>
        </ProgramCardInfo>
        <ProgramImage isSidebarOpen={!!selectedProgram} src={program.media?.thumbnailUrl} alt={program.name} />
      </ProgramCardContainer>
    );
  };

  const therapyProgramsResult = therapyPrograms
    .filter(filterByStatus)
    .filter(filterBySearchValue)
    .sort((a, b) => a.name.localeCompare(b.name));

  return (
    <PageContainer>
      <ProgramListWrapper>
        <PageHeader>
          <PageTitle>Therapy Programs</PageTitle>
          <AddProgramButton>
            <Link to='/therapy-programs/add'>+ Add Program</Link>
          </AddProgramButton>
        </PageHeader>
        <ListControls>
          <Filters>{filters.map(Filter)}</Filters>
          <SearchBar value={searchValue} onChange={onSearchValueChange} />
        </ListControls>
        <ProgramList>{therapyProgramsResult.length ? therapyProgramsResult.map(ProgramCard) : <ProgramNotFound />}</ProgramList>
      </ProgramListWrapper>
      <SelectedProgramSidebar isActive={!!selectedProgram}>
        <ProgramDetails program={selectedProgram} />
      </SelectedProgramSidebar>
    </PageContainer>
  );
};
