import React, {useEffect, useState, useLayoutEffect} from 'react';
import {ActionButtonsContainer, CloseModal, ButtonLink, ModalBody} from './styles';
import Modal from 'react-modal';
import {CancelButton, SaveButton} from 'components/page';
import {Slider} from 'primereact/slider';

import ImageEditor from 'tui-image-editor';

const style = {
  content: {
    border: 'none',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    opacity: '100%',
    width: '650px',
    transform: 'translate(-50%, -50%)',
  },
  overlay: {
    backgroundColor: 'rgba(30, 30, 30, 0.50)',
    zIndex: 255,
  },
};

const applyBrightness = (data: any, brightness: number) => {
  for (var i = 0; i < data.length; i += 4) {
    data[i] += 255 * (brightness / 100);
    data[i + 1] += 255 * (brightness / 100);
    data[i + 2] += 255 * (brightness / 100);
  }
};

const applyContrast = (data: any, contrast: number) => {
  var factor = (259.0 * (contrast + 255.0)) / (255.0 * (259.0 - contrast));

  for (var i = 0; i < data.length; i += 4) {
    data[i] = truncateColor(factor * (data[i] - 128.0) + 128.0);
    data[i + 1] = truncateColor(factor * (data[i + 1] - 128.0) + 128.0);
    data[i + 2] = truncateColor(factor * (data[i + 2] - 128.0) + 128.0);
  }
};

const truncateColor = (value: number) => {
  if (value < 0) {
    value = 0;
  } else if (value > 255) {
    value = 255;
  }

  return value;
};

type MediaSettingsProps = {
  mediaUrl: string;
  onSaveSettings: (brightnessValue: number, contrastValue: number, url: string) => void;
};

export const MediaSettings: React.FC<MediaSettingsProps> = ({mediaUrl, onSaveSettings}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [imgUrl, setImgUrl] = useState('');
  const [brightnessValue, setBrightnessValue] = useState(0);
  const [contrastValue, setContrastValue] = useState(0);

  // useLayoutEffect(() => {
  //   setBrightness();
  // }, []);

  useEffect(() => {
    const instance = new ImageEditor(document.querySelector('#tui-image-editor') || '', {
      cssMaxWidth: 0,
      cssMaxHeight: 0,
      selectionStyle: {
        cornerSize: 0,
        rotatingPointOffset: 0,
      },
    });

    instance.loadImageFromURL(mediaUrl, 'My Sample Image');
  }, []);

  const onOpenModal = () => {
    setBrightness();
    setIsOpen(true);
  };

  const onCancelModal = () => {
    setIsOpen(false);
  };

  const setBrightness = async () => {
    var img = new Image();
    var canvas: any = document.getElementById('canvas');
    var ctx = canvas && canvas.getContext('2d');

    img.crossOrigin = 'anonymous';
    img.src = mediaUrl;

    canvas.width = img.width + 1;
    canvas.height = img.height + 1;

    img.onload = async function () {
      img.crossOrigin = 'anonymous';
      img.setAttribute('crossOrigin', 'anonymous');

      ctx.drawImage(img, 0, 0);

      var imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
      applyBrightness(imageData.data, brightnessValue);

      applyContrast(imageData.data, contrastValue);

      ctx.putImageData(imageData, 0, 0);

      setImgUrl(canvas?.toDataURL());
    };
  };

  const changeBrightness = (e: any) => {
    const value = e.value;
    setBrightnessValue(value);
    setBrightness();
  };

  const changeContrast = (e: any) => {
    const value = e.value;
    setContrastValue(value);
    setBrightness();
  };

  const onSave = () => {
    onSaveSettings(brightnessValue, contrastValue, imgUrl);
    setIsOpen(false);
  };

  return (
    <div>
      <canvas id='canvas' style={{display: 'none'}}></canvas>

      <div id='tui-image-editor' style={{display: 'none'}}></div>

      <ButtonLink onClick={onOpenModal}>
        <i className='pi pi-cog' style={{marginRight: '3px'}} /> Brightness setting
      </ButtonLink>
      <Modal isOpen={isOpen} onRequestClose={onCancelModal} contentLabel='Select image' style={style}>
        <CloseModal onClick={onCancelModal}>x</CloseModal>
        <div style={{textAlign: 'center', margin: '20px'}}>
          <ModalBody>
            <div>
              <img crossOrigin='anonymous' src={imgUrl} width='80%' />
            </div>
            <div style={{width: '50%'}}>
              <label>Brightness</label>
              <Slider min={0} max={100} value={brightnessValue} onChange={changeBrightness} />
            </div>
            <div style={{width: '50%'}}>
              <label>Contrast</label>
              <Slider min={0} max={100} value={contrastValue} onChange={changeContrast} />
            </div>
          </ModalBody>
        </div>
        <ActionButtonsContainer>
          <CancelButton onClick={onCancelModal}>Cancel</CancelButton>
          <SaveButton onClick={onSave}>Save</SaveButton>
        </ActionButtonsContainer>
      </Modal>
    </div>
  );
};
export default MediaSettingsProps;
