import {createAction} from '@reduxjs/toolkit';
export const selectActivity = createAction<ActivityDto>('SELECT_ACTIVITY');
export const unselectActivity = createAction('UNSELECT_ACTIVITY');
export const selectProgram = createAction<ProgramDto>('SELECT_PROGRAM');
export const unselectProgram = createAction('UNSELECT_PROGRAM');
export const selectExercise = createAction<ExerciseDto>('SELECT_EXERCISE');
export const unselectExercise = createAction('UNSELECT_EXERCISE');
export const selectMedia = createAction<MediaDto>('SELECT_MEDIA');
export const unselectMedia = createAction('UNSELECT_MEDIA');
export const sendMessage = createAction<ToastMessage>('SEND_MESSAGE');
