import React, {useState} from 'react';
import {
  Button,
  PageContainer,
  PageHeader,
  PageTitle,
  PageBody,
  AddProgramForm,
  PageFooter,
  CancelButton,
  SaveButton,
  Label,
} from 'components/page';
import styled from 'styled-components';
import GeneralSidebar from '../components/general-sidebar';

const StyledSearchBar = styled.div`
  align-items: center;
  background-color: white;
  border: 1px solid #ced4da;
  box-sizing: border-box;
  border-radius: 3px;
  color: #495057;
  cursor: text;
  display: inline-flex;
  flex: 1;
  margin-left: 20px;
  min-width: 50px;
  max-width: 400px;
  overflow: hidden;
  position: relative;
  transition: 0.1s;

  :hover:not(:focus-within) {
    background-color: #fafafa;
  }

  i {
    cursor: text;
    display: flex;
    margin: 0 8px 0 20px;
  }

  input {
    background: transparent;
    border: 0;
    font-size: 14px;
    line-height: 17px;
    padding: 8px;
    position: relative;
    width: 100%;
  }

  input:focus {
    outline: none;
  }

  :focus-within {
    border: 1px solid #293b55;
  }
`;

const PDHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const PDTitle = styled.div`
  color: #010101;
  font-style: normal;
  font-size: 24px;
  font-weight: 500;
  line-height: 29px;
  margin-bottom: 8px;
`;

const PageContainerBody = styled(PageContainer)`
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  padding-left: 60px;
`;

type StyledFilterProps = {
  isSelected?: boolean;
};

const StyledFilter = styled.div`
  align-items: center;
  background: ${(props: StyledFilterProps) => (props.isSelected ? '#293b55' : 'white')};
  border-color: ${(props: StyledFilterProps) => (props.isSelected ? '#293b55' : '#ced4da')};
  border-width: 1px;
  border-style: solid;
  border-radius: 20px;
  box-shadow: ${(props: StyledFilterProps) => props.isSelected && '0px 2px 8px rgba(0, 0, 0, 0.1)'};
  color: ${(props: StyledFilterProps) => (props.isSelected ? 'white' : '#293b55')};
  cursor: pointer;
  display: flex;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  transition: 0.1s;
  margin: 1px 4px;
  justify-content: center;
  padding: 4px 24px;
  text-align: center;
  text-transform: capitalize;
  transition: 0.2s;
  user-select: none;
  width: 100px;

  :not(:hover) {
    box-shadow: inset 0px 2px 8px rgba(0, 0, 0, 0.01);
  }

  :hover {
    background: ${(props: StyledFilterProps) => !props.isSelected && '#fafafa'};
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.03);
  }
`;

export const Clients: React.FC = () => {
  const onClick = (): void => setTrya(!trya);

  const [trya, setTrya] = useState<boolean>(false);

  return (
    <React.Fragment>
      <PageContainer>
        <PageContainerBody>
          <PageHeader>
            <PageTitle>Clients</PageTitle>
            <StyledFilter isSelected={false}>Filter</StyledFilter>
            <StyledSearchBar>
              <label htmlFor='inputSearch'>
                <i className='pi pi-search'></i>
              </label>
              <input type='search' id='inputSearch' placeholder='Search' />
            </StyledSearchBar>
          </PageHeader>
          <PageBody>
            <Button onClick={() => setTrya(!trya)}> Simulate Select Client </Button>
            Lista aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa
          </PageBody>
        </PageContainerBody>

        <GeneralSidebar isActive={trya} onClick={onClick}>
          <div>
            <PDHeader>
              <PDTitle>Chosen Client Info</PDTitle>
            </PDHeader>
          </div>
        </GeneralSidebar>
      </PageContainer>
    </React.Fragment>
  );
};
// value={value} onChange={onChange}, al input type
/*      <div>
        <PDHeader>
          <PDTitle>Therapy Program Info</PDTitle> 
        </PDHeader>

          </div>
          
          */
