import React, {useState} from 'react';
import {StyledFormElement} from 'components/form-element';
import {PageContainer, PageHeader, PageTitle} from 'components/page';
import {SelectButton as MediaTypeSelector} from 'primereact/selectbutton';
import {ImageDetails} from './image';
// import {AddAudio as AudioDetails} from './audio';
import {VideoDetails} from './video';
import {AudioDetails} from './audio';
import {useLocation, useParams} from 'react-router-dom';
import {capitalize} from 'utils/helpers';
import {useAppSelector} from '../../../hooks';
import {RootState} from '../../../index';
import {shallowEqual} from 'react-redux';

const mediaTypeOptions = [
  {name: 'Audio', value: 'audio'},
  {name: 'Video', value: 'video'},
  {name: 'Image', value: 'image'},
];

export type MediaDetailsFormType = 'add' | 'edit';

export const MediaDetails: React.FC = () => {
  const [mediaType, setMediaType] = useState<MediaType>('video');
  const {formType = 'add'} = useParams<{formType: MediaDetailsFormType}>();
  const selectedMedia: MediaDto | null = useAppSelector((state: RootState) => state.mediaBank.selectedMedia, shallowEqual);
  if (selectedMedia && formType === 'edit') {
    selectedMedia?.type !== mediaType && setMediaType(selectedMedia?.type);
  }

  const handleSetMediaType = (e: {value: MediaType}): void => {
    e.value && setMediaType(e.value);
  };

  // Add Media | Edit Audio | Edit Video | Edit Image
  const title = `${capitalize(formType)} ${formType === 'add' ? 'Media' : capitalize(mediaType)}`;

  return (
    <PageContainer>
      <div style={{width: '85%', overflow: 'auto'}}>
        <PageHeader>
          <PageTitle>{title}</PageTitle>
        </PageHeader>
        {formType === 'add' && (
          <StyledFormElement>
            <MediaTypeSelector value={mediaType} onChange={handleSetMediaType} options={mediaTypeOptions} optionLabel='name' />
          </StyledFormElement>
        )}

        {mediaType === 'video' && <VideoDetails formType={formType} />}
        {mediaType === 'audio' && <AudioDetails formType={formType} />}
        {mediaType === 'image' && <ImageDetails formType={formType} />}
      </div>
    </PageContainer>
  );
};
