import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserPool,
  CognitoUserSession,
  ICognitoUserPoolData,
} from 'amazon-cognito-identity-js';

const POOL_ID = process.env.REACT_APP_COGNITO_POOL_ID!;
const CLIENT_ID = process.env.REACT_APP_COGNITO_CLIENT_ID!;

const poolCredentials: ICognitoUserPoolData = {
  UserPoolId: POOL_ID,
  ClientId: CLIENT_ID,
};

export const initiateAuth = async (user: CognitoUser, email: string) => {
  const authenticationData = {Username: email};
  const authenticationDetails = new AuthenticationDetails(authenticationData);
  user.setAuthenticationFlowType('CUSTOM_AUTH');

  return await new Promise((resolve, reject) => {
    user.initiateAuth(authenticationDetails, {
      onSuccess: () => {},
      onFailure: (err) => {
        console.log('initiateAuth error', err);
        reject(err);
      },
      customChallenge: (challengeParameters) => {
        console.log('initiateAuth ok. challengeParameteres:', challengeParameters);
        resolve(challengeParameters);
      },
    });
  });
};

export const createUser = (email: string): CognitoUser => {
  const userPool = new CognitoUserPool(poolCredentials);
  const userData = {Username: email, Pool: userPool};
  return new CognitoUser(userData);
};

export const getSession = (): Promise<CognitoUserSession> => {
  const userPool = new CognitoUserPool(poolCredentials);
  const user = userPool.getCurrentUser();

  return new Promise((resolve, reject) => {
    if (!user) {
      return reject('NotUserException');
    }

    user.getSession((err: Error | null, session: CognitoUserSession | null) => {
      if (err) {
        return reject(err);
      }

      if (session) {
        return resolve(session);
      }

      return reject();
    });
  });
};

export const signOut = () => {
  const userPool = new CognitoUserPool(poolCredentials);
  const user = userPool.getCurrentUser();
  user?.signOut();
};
