import {AddProgramForm, CancelButton, PageBody, PageFooter, SaveButton, ArchiveButton as DeleteButton} from 'components/page';
import {FormElement, StyledFormElement} from 'components/form-element';
import {MediaDetailsFormType} from '../index';
import React, {useEffect, useState} from 'react';
import {Link, useHistory} from 'react-router-dom';
import {createAudio, updateAudio, deleteMedia} from 'api/chatowl.api';
import {ImageCropperManager} from '../../crop-manager';
import {AddInput, InputButton, InputComplete, Tag, CursorLoading} from '../styles';
import {StyleAddAudioFiles, Header, ButtonLink} from './styles';
import {useTags} from 'hooks';
import AudioUploader from 'components/media/audio-uploader';
import AudioPlayer from 'components/media/audio-player';

import {useAppSelector, useAppDispatch} from '../../../../hooks';
import {sendMessage} from '../../../../store/action-creators';

import {RootState} from '../../../../index';
import {shallowEqual} from 'react-redux';
import {Button} from 'primereact/button';

function validateSave(
  name: string,
  audioBlob: Blob | undefined,
  audioUrl: string | undefined,
  crops: number,
  imagesCrops: number
): boolean {
  return !!name && (!!audioBlob || !!audioUrl) && (!!crops || !!imagesCrops);
}

export const AudioDetails: React.FC<{formType: MediaDetailsFormType}> = ({formType}) => {
  const history = useHistory();

  const [name, setName] = useState('');
  const tags = useTags();
  const [currentTag, setCurrentTag] = useState<Tag>();
  const [originalAudio, setOriginalAudio] = useState<Blob>();
  const [originalAudioURL, setOriginalAudioURL] = useState<string>();
  const [originalImage, setOriginalImage] = useState<Blob>();
  const [crops, setCrops] = useState<Crop[]>([]);
  const [imagesCrop, setImagesCrop] = useState<CropDto[]>([]);
  const selectedMedia: MediaDto | null = useAppSelector((state: RootState) => state.mediaBank.selectedMedia, shallowEqual);
  const [saving, setSaving] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const [userClickedDelete, setUserClickedDelete] = useState<boolean>(false);

  useEffect(() => {
    if (formType === 'edit') {
      if (selectedMedia) {
        setName(selectedMedia.name);
        tags.initTag(selectedMedia.tags!);
        setOriginalAudio(new Blob());
        setOriginalAudioURL(selectedMedia.url);
        setImagesCrop(selectedMedia.crops);
        console.log(selectedMedia.thumbnailUrl);
      }
    }
  }, []);

  useEffect(() => setCurrentTag(''), [tags.list]);

  const addCrop = (newCrop: Crop) => {
    const newCrops = crops.some((crop) => crop.type === newCrop.type) // if exist crop
      ? crops.map((crop) => (crop.type === newCrop.type ? newCrop : crop)) // replace crop
      : [...crops, newCrop]; // else push crop
    setCrops(newCrops);
    console.log({newCrops});
  };

  const sendAudio = async () => {
    if (validateSave(name, originalAudio, originalAudioURL, crops.length, imagesCrop.length)) {
      setSaving(true);
      if (formType === 'add') {
        const newAudio: CreateMediaAudioRequest = {
          name,
          tags: tags.list,
          originalAudio: originalAudio!,
          fullScreenLandscape: crops!.find((crop) => crop.type === 'full_screen_landscape')!.blob,
          fullWidthTall: crops!.find((crop) => crop.type === 'full_width_tall')!.blob,
          fullWidthRegular: crops!.find((crop) => crop.type === 'full_width_regular')!.blob,
          fullScreenPortrait: crops!.find((crop) => crop.type === 'full_screen_portrait')!.blob,
          crops: [],
          originalImage: originalImage!,
        };
        try {
          const mediaAudio = await createAudio(newAudio);
          dispatch(sendMessage({severity: 'success', summary: 'SUCCESS', detail: 'Audio successfully created'}));
          history.push('/media-bank/');
        } catch (e) {
          dispatch(sendMessage({severity: 'error', summary: 'ERROR', detail: 'Something went wrong'}));
        }
      }

      if (formType === 'edit') {
        let newAudio: UpdateMediaAudioRequest;
        if (crops.length) {
          newAudio = {
            name,
            tags: tags.list,
            crops: [],
            originalAudio: originalAudio,
            originalImage: originalImage!,
            fullScreenLandscape: crops!.find((crop) => crop.type === 'full_screen_landscape')!.blob,
            fullWidthTall: crops!.find((crop) => crop.type === 'full_width_tall')!.blob,
            fullWidthRegular: crops!.find((crop) => crop.type === 'full_width_regular')!.blob,
            fullScreenPortrait: crops!.find((crop) => crop.type === 'full_screen_portrait')!.blob,
          };
        } else {
          newAudio = {
            name,
            tags: tags.list,
            crops: [],
            originalAudio: originalAudio,
            originalImage: originalImage!,
          };
        } //hay q ver si cambian las imagenes tambien, para ver si las mando o no.
        try {
          const mediaAudio = await updateAudio(selectedMedia!.id, newAudio);
          dispatch(sendMessage({severity: 'success', summary: 'SUCCESS', detail: 'Audio successfully edited'}));
          history.push('/media-bank/');
        } catch (e) {
          dispatch(sendMessage({severity: 'error', summary: 'ERROR', detail: 'Something went wrong'}));
        }
      }
    } else {
      dispatch(sendMessage({severity: 'warn', summary: 'WARNING', detail: 'Name and Audio can not be empty.'}));
    }
  };

  const onCancelAudioDetails = () => history.push('/media-bank');

  const onDeleteAudio = async () => {
    if (!userClickedDelete) setUserClickedDelete(true);
    else {
      //hacer la llamada correspondiente
      try {
        await deleteMedia(selectedMedia!.id);
        dispatch(sendMessage({severity: 'success', summary: 'SUCCESS', detail: 'Audio successfully deleted'}));
        history.push('/media-bank');
      } catch (e) {
        dispatch(sendMessage({severity: 'error', summary: 'ERROR', detail: 'Something went wrong'}));
      }
    }
  };

  const handleOnClickNewAudio = () => {
    setOriginalAudio(undefined);
    setOriginalAudioURL('');
  };

  return (
    <CursorLoading disable={saving}>
      <PageBody>
        <AddProgramForm>
          <FormElement type='input' label='Name' value={name} placeholder='The name of the audio' onChange={setName} />
          <StyledFormElement>
            {/* <AutoComplete /> */}
            <label htmlFor={`input Tags`}>{'Tags'}</label>
            <InputButton>
              <InputComplete value={currentTag} onChange={(e) => setCurrentTag(e.target.value.toLowerCase())} />
              <AddInput onClick={() => currentTag && tags.push(currentTag)}>Add</AddInput>
            </InputButton>
            <div style={{display: 'flex', flexDirection: 'row'}}>
              {tags.list?.map((tag) => (
                <Tag id={`${tag}_audio`} key={`${tag}_audio`} color={'#008C8C'} onClick={() => tags.drop(tag)}>
                  {tag} x
                </Tag>
              ))}
            </div>
          </StyledFormElement>
        </AddProgramForm>

        <StyleAddAudioFiles>
          <StyledFormElement>
            <Header>
              <label>Audio</label>
              {originalAudioURL && <ButtonLink onClick={handleOnClickNewAudio}>Upload New Audio</ButtonLink>}
            </Header>

            {!originalAudio ? (
              <AudioUploader
                onUploadAudio={(audio: File) => {
                  setOriginalAudio(audio);
                  setOriginalAudioURL(URL.createObjectURL(audio));
                }}
              >
                <p style={{color: 'red'}}>*Required</p>
                <p>Upload an audio file (.mp3)</p>
                <Button className='p-button-outlined p-mr-2 p-mb-2'> Upload File </Button>
              </AudioUploader>
            ) : (
              originalAudioURL && <AudioPlayer audioSrc={originalAudioURL} />
            )}
          </StyledFormElement>
          <StyledFormElement>
            {formType === 'edit' ? (
              <ImageCropperManager
                mediaType='audio'
                setOriginalImage={setOriginalImage}
                addCrop={addCrop}
                imagesCrop={imagesCrop}
                image={selectedMedia?.thumbnailUrl}
              />
            ) : (
              <ImageCropperManager mediaType='audio' setOriginalImage={setOriginalImage} addCrop={addCrop} />
            )}
          </StyledFormElement>
        </StyleAddAudioFiles>
      </PageBody>
      <PageFooter>
        {formType === 'edit' && (
          <DeleteButton onClick={onDeleteAudio} userClicked={userClickedDelete}>
            {userClickedDelete ? 'Delete ?' : 'Delete'}
          </DeleteButton>
        )}
        <CancelButton onClick={onCancelAudioDetails}>
          <Link to='/media-bank/'>Cancel</Link>
        </CancelButton>
        <SaveButton disabled={saving} onClick={sendAudio} disable={saving}>
          {saving ? 'Saving...' : 'Save'}
        </SaveButton>
      </PageFooter>
    </CursorLoading>
  );
};
