import styled from 'styled-components';

export const SidebarButton = styled.button`
  background: white none repeat scroll 0px center;
  border: 0px none;
  border-radius: 50%;
  box-shadow: rgba(9, 30, 66, 0.08) 0px 0px 0px 1px, rgba(9, 30, 66, 0.08) 0px 2px 4px 1px;
  color: rgb(107, 119, 140);
  cursor: ${(props: SidebarProps) => props.isActive && 'pointer'};
  height: 24px;
  opacity: ${(props: SidebarProps) => (props.isActive ? '1' : '0')};
  outline: currentcolor none 0px;
  padding: 0px;
  position: absolute;
  top: 32px;
  transition: background-color 100ms linear 0s, color 100ms linear 0s, opacity 300ms cubic-bezier(0.2, 0, 0, 1) 0s,
    transform 300ms cubic-bezier(0.2, 0, 0, 1) 0s;
  transform: translateX(50%);
  width: 24px;

  :hover {
    background: #293b55 none repeat scroll 0px center;
    color: white;
  }
`;

export const SidebarButtonSpan = styled.span`
  color: currentcolor;
  display: inline-block;
  fill: rgb(255, 255, 255);
  flex-shrink: 0;
  line-height: 1;
  height: 45px;
`;

export const SidebarSVG = styled.svg`
  max-height: 100%;
  max-width: 100%;
  overflow: hidden;
  pointer-events: none;
  vertical-align: bottom;
`;

export const SidebarMargin = styled.div`
  right: -4px;
  position: relative;
  width: 24px;
  height: 100%;
`;

export const SidebarContainer = styled.div`
  display: flex;
  position: relative;
  top: 0px;
  bottom: 0px;
  right: 0px;
`;

export const SidebarBorder = styled.div`
  bottom: 0px;
  position: relative;
  top: 0px;
  transform: translateZ(0px);
  width: 24px;
  z-index: 3;
`;

export const SidebarLine = styled.div`
  background: rgba(0, 0, 0, 0)
    linear-gradient(to left, rgba(0, 0, 0, 0.2) 0px, rgba(0, 0, 0, 0.2) 1px, rgba(0, 0, 0, 0.1) 1px, rgba(0, 0, 0, 0) 100%) repeat
    scroll 0% 0%;
  bottom: 0px;
  right: -3px;
  opacity: 0.5;
  pointer-events: none;
  position: absolute;
  top: 0px;
  transition-duration: 0.22s;
  transition-property: right, opacity, width;
  transition-timing-function: cubic-bezier(0.2, 0, 0, 1);
  width: 3px;
`;
type SidebarProps = {
  isActive?: boolean;
};

export const SidebarContent = styled.div`
  bottom: 0px;
  top: 0px;
  right: 0px;
  max-width: 591px;
  min-width: 75px;
  width: ${(props: SidebarProps) => (props.isActive ? '591px' : '75px')};
  padding: 32px 28px 32px 40px;
  position: relative;
  transition: left 0.3s ease-in-out;
`;
