import React, {FC, useState} from 'react';
import Modal from 'react-modal';
import styled from 'styled-components';
import {Button} from './page';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';

type ImageCropperProps = {
  imageSrc: string;
  isOpen: boolean;
  onCancelCrop: any;
  onSaveCrop: (image: Blob, cropper?: any) => void;
  aspectRatio?: number;
  minHeight?: number;
  minWidth?: number;
};

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  margin-top: 40px;
`;

const CancelButton = styled(Button)`
  background: #ffffff;
  border: 1px solid #2c98f0;
  color: #2c98f0;
`;

const SaveButton = styled(Button)`
  background: #2c98f0;
  color: #ffffff;
`;

const style = {
  content: {
    border: 'none',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    opacity: '100%',
    width: '500px',
    transform: 'translate(-50%, -50%)',
  },
  overlay: {
    zIndex: 255,
    backgroundColor: 'rgba(30, 30, 30, 0.50)',
  },
};

export const ImageCropper: FC<ImageCropperProps> = ({
  imageSrc,
  isOpen,
  onCancelCrop,
  onSaveCrop,
  aspectRatio,
  minHeight,
  minWidth,
}) => {
  const [cropper, setCropper] = useState<any>();

  const getCropData = () => {
    if (typeof cropper !== 'undefined') {
      try {
        cropper.getCroppedCanvas().toBlob((croppedImage: Blob) => onSaveCrop(croppedImage, cropper.getData()));
      } catch (e) {
        console.log(e);
      }
    }
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={onCancelCrop} contentLabel='Edit Crop' style={style}>
      <h5 style={{textAlign: 'center'}}>Edit Crop</h5>
      <Cropper
        style={{width: '100%'}}
        initialAspectRatio={1}
        preview='.img-preview'
        src={imageSrc}
        viewMode={1}
        guides={true}
        minCropBoxHeight={minHeight ? minHeight : 20}
        minCropBoxWidth={minWidth ? minWidth : 20}
        background={false}
        responsive={true}
        autoCropArea={1}
        aspectRatio={aspectRatio ? aspectRatio : undefined}
        checkOrientation={false}
        onInitialized={(instance) => {
          setCropper(instance);
        }}
      />
      <ButtonsContainer>
        <CancelButton onClick={onCancelCrop}>Cancel</CancelButton>
        <SaveButton onClick={getCropData}>Save</SaveButton>
      </ButtonsContainer>
    </Modal>
  );
};
