import React from 'react';
import styled from 'styled-components';

type FormElementProps = {
  type: string;
  label: string;
  placeholder?: string;
  value: string | number;
  onChange: (value: string) => void;
};

export const StyledFormElement = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;

  label {
    color: #010101;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    margin-bottom: 8px;
  }

  input,
  textarea {
    background: #ffffff;
    border: 1px solid #ced4da;
    box-sizing: border-box;
    border-radius: 3px;

    color: #495057;
    font-family: inherit;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;

    padding: 8px 16px;
    transition: 0.1s;

    :hover:not(:focus-within) {
      background-color: #fafafa;
      z-index: 2;
    }

    :focus {
      outline: none;
    }

    :focus-within {
      border: 1px solid #293b55;
    }
  }

  textarea {
    resize: vertical;
    height: 200px;
  }
`;

export const FormElement: React.FC<FormElementProps> = ({type, label, placeholder, value, onChange}) => {
  return (
    <StyledFormElement>
      <label htmlFor={`input${label}`}>{label}</label>
      {type === 'text-area' ? (
        <textarea id={`input${label}`} value={value} placeholder={placeholder} onChange={(e) => onChange(e.target.value)} />
      ) : (
        <input
          id={`input${label}`}
          type={type}
          value={value}
          placeholder={placeholder}
          onChange={(e) => onChange(e.target.value)}
        />
      )}
    </StyledFormElement>
  );
};
