import styled from 'styled-components';

export const StyleAddVideoFilesCol = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  label {
    color: #010101;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    margin-bottom: 8px;
  }
`;

export const StyleAddVideoFiles = styled.div`
  display: flex;
  flex-direction: row;
  min-width: 250px;
  max-width: 250px;
  width: 100%;
  margin-bottom: 20px;

  label {
    color: #010101;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    margin-bottom: 8px;
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const ButtonLink = styled.div`
  color: #2c98f0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-size: 14px;
  font-weight: normal;
  line-height: 17px;
  padding: 10px;
  user-select: none;

  :hover {
    text-decoration: underline;
  }
`;
