import React, {useState, useEffect} from 'react';

import {PageContainer, PageHeader, PageTitle} from '../../../components/page';
import styled from 'styled-components';
import GeneralSidebar from '../../../components/general-sidebar';
import {
  ListWrapper as ExerciseListWrapper,
  AddButton as AddExerciseButton,
  ListControls,
  ListGeneral as ExerciseList,
} from '../../../components/tools/main-page-components';
import GeneralFilters from '../../../components/tools/filters';
import ExerciseCard from '../../../components/tools/general-card-list';

import SearchBar from '../../../components/search-bar';
import {Link} from 'react-router-dom';
import {listExercises} from '../../../api/chatowl.api';

import {useAppDispatch, useAppSelector} from '../../../hooks';
import {RootState} from '../../../index';
import {shallowEqual} from 'react-redux';
import {selectExercise, unselectExercise} from '../../../store/action-creators';

import ExerciseDetails from './exercise-detail';

const ExerciseNotFound = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #495057;
  height: 50vh;

  :after {
    content: 'No Exercises';
  }
`;

export const Exercises: React.FC = () => {
  const [exercises, setExercises] = useState<ExerciseDto[]>([]);

  const [selectedFilters, setSelectedFilters] = useState<string[]>(['Active']);
  const [searchValue, setSearchValue] = useState<string>('');
  const selectedExercise: ExerciseDto | null = useAppSelector(
    (state: RootState) => state.exercises.selectedExercise,
    shallowEqual
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(unselectExercise());
    const fetchExercises = async () => {
      try {
        const response = (await listExercises()) as any;
        const exercisesResponse = response.data.data;
        setExercises(exercisesResponse);
      } catch (error) {
        throw error; // TODO
      }
    };
    fetchExercises();
  }, []);

  useEffect(() => {
    if (
      (selectedExercise?.isArchived && !selectedFilters.includes('Archived')) ||
      (!selectedExercise?.isArchived && !selectedFilters.includes('Active'))
    ) {
      dispatch(unselectExercise());
    }
  }, [selectedFilters]);

  const filters: string[] = ['Active', 'Archived'];

  const onExerciseClick = (toolID: number): void => {
    const exerciseSelected = exercises.find((exercise) => exercise.id === toolID);
    if (exerciseSelected)
      toolID !== selectedExercise?.id ? dispatch(selectExercise(exerciseSelected)) : dispatch(unselectExercise());
  };

  const onSearchValueChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setSearchValue(e.target.value);
  };

  const onClick = (): void => {
    dispatch(unselectExercise());
  };

  const toggleFilters = (filter: string): void => {
    selectedFilters.some((f) => f === filter)
      ? setSelectedFilters(selectedFilters.filter((f) => f !== filter)) // turn off
      : setSelectedFilters([...selectedFilters, filter]); // turn on
  };

  const filterByStatus = (exercise: ExerciseDto) =>
    !selectedFilters.length ||
    (selectedFilters.includes('Active') && !exercise.isArchived) ||
    (selectedFilters.includes('Archived') && exercise.isArchived);

  const filterBySearchValue = (exercise: ExerciseDto) =>
    !searchValue.toLowerCase() ||
    exercise.title.toLowerCase().includes(searchValue.toLowerCase()) ||
    exercise.description?.toLowerCase().includes(searchValue.toLowerCase());

  const exercisesFiltered = exercises
    .filter(filterByStatus)
    .filter(filterBySearchValue)
    .sort((a, b) => a.title.localeCompare(b.title));

  return (
    <PageContainer>
      <ExerciseListWrapper>
        <PageHeader>
          <PageTitle>Exercises</PageTitle>
          <AddExerciseButton>
            <Link to='/tools/exercises/add'>+ Add Exercise</Link>
          </AddExerciseButton>
        </PageHeader>
        <ListControls>
          <GeneralFilters filters={filters} selectedFilters={selectedFilters} onClickToggle={toggleFilters}></GeneralFilters>
          <SearchBar value={searchValue} onChange={onSearchValueChange} />
        </ListControls>
        <ExerciseList>
          {exercisesFiltered.length ? (
            exercisesFiltered.map((exercise) => (
              <ExerciseCard
                key={exercise.id}
                selectedToolID={selectedExercise?.id}
                toolID={exercise.id}
                toolTitle={exercise.title}
                toolDescription={exercise.description}
                toolCategory={exercise.toolCategory.name}
                onClick={onExerciseClick}
                toolImage={exercise.imageLandscapeUrl}
                //toolImage={exercise.media.imageLandscapeUrl}
              />
            ))
          ) : (
            <ExerciseNotFound />
          )}
        </ExerciseList>
      </ExerciseListWrapper>
      <GeneralSidebar isActive={!!selectedExercise} onClick={onClick}>
        <ExerciseDetails exercise={selectedExercise} />
      </GeneralSidebar>
    </PageContainer>
  );
};
