import React, {useEffect, useState} from 'react';
import {
  PageContainer as GlobalPageContaner,
  PageHeader,
  PageTitle,
  PageBody,
  AddProgramForm,
  PageFooter,
  CancelButton,
  SaveButton,
  ArchiveButton,
  CursorLoading,
} from 'components/page';
import styled from 'styled-components';
import {Link, useHistory} from 'react-router-dom';
import {Dropdown} from 'primereact/dropdown';
import {FormElement, StyledFormElement} from 'components/form-element';
import {useParams} from 'react-router-dom';

import {useAppSelector, useAppDispatch} from 'hooks';
import {selectExercise, sendMessage, unselectExercise} from 'store/action-creators';

import {RootState} from 'index';
import {shallowEqual} from 'react-redux';

import {mediaTypesDropdown, repeatLimitDropdown, resumeLimitDropdown} from './exercise-detail';

import {StyleCheckboxElements} from 'components/tools/general-checkbox/style';
import GeneralCheckbox from 'components/tools/general-checkbox';
import {addExercise, editExercise, getCategories, updateAudio, updateVideo} from 'api/chatowl.api';
import MediaChooser from 'components/media-chooser';
import {MediaType} from 'pages/media-bank/media-list/styles';
import {DropdownType} from './exercise-detail';

const PageContainer = styled(GlobalPageContaner)`
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  padding-left: 60px;
`;

const StyleAddVideoFilesCol = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  label {
    color: #010101;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    margin-bottom: 8px;
  }
`;
const StyleAddVideoFiles = styled.div`
  display: flex;
  flex-direction: row;
  min-width: 250px;
  max-width: 250px;
  width: 100%;
  margin-bottom: 20px;

  label {
    color: #010101;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    margin-bottom: 8px;
  }
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  label {
    color: #010101;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    margin-bottom: 8px;
  }
`;

const ButtonLink = styled.div`
  color: #2c98f0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-size: 14px;
  font-weight: normal;
  line-height: 17px;
  padding: 10px;
  user-select: none;

  :hover {
    text-decoration: underline;
  }
`;
//ESOS ULTIMOS 4 STYLED COMPONENTS, SON DE MEDIA BANK TAMBIEN.

type CheckboxEvent = {
  originalEvent: Event;
  value: any;
  checked: boolean;
  target: {
    type: string;
    name: string;
    id: string;
    value: any;
    checked: boolean;
  };
};

function validateSave(name: string, mediaId: number | undefined): boolean {
  return !!name && mediaId !== 0;
}

const passToDropdownType = (categories: ToolCategoryType[]): DropdownType[] => {
  let dropdown: DropdownType[] = [];
  categories.map((category) => {
    const {name, categoryId} = category;
    const newObj = {label: name, value: categoryId};
    dropdown = [...dropdown, newObj];
    return category;
  });
  return dropdown;
};

type MediaDetailsFormType = 'add' | 'edit';

export const AddExercise: React.FC = () => {
  const {formType = 'add'} = useParams<{formType: MediaDetailsFormType}>();
  const selectedExercise: ExerciseDto | null = useAppSelector(
    (state: RootState) => state.exercises.selectedExercise,
    shallowEqual
  );
  const [exerciseName, setExerciseName] = useState<string>('');
  const [exerciseDescription, setExerciseDescription] = useState<string>('');
  const [exerciseCategory, setExerciseCategory] = useState<number>(0);
  const [repeatLimit, setRepeatLimit] = useState<number>(0);
  const [resumeLimit, setResumeLimit] = useState<number>(0);
  const [exerciseMediaType, setExerciseMediaType] = useState<MediaTypes>('audio');
  const [instructions, setInstructions] = useState<InstructionType[]>([]);

  const [categoriesDropdown, setCategoriesDropdown] = useState<DropdownType[]>([]);

  const [mediaId, setMediaId] = useState<number | undefined>(0);
  const [newCrops, setNewCrops] = useState<Crop[]>([]);
  const editing: boolean = formType === 'edit' && !!selectedExercise;

  const [saving, setSaving] = useState(false);

  const history = useHistory();
  const dispatch = useAppDispatch();

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = (await getCategories()) as any;
        const categories: ToolCategoryType[] = response.data ? response.data.data : [];

        const DropdownType = passToDropdownType(categories); //to display in dropdown.
        setCategoriesDropdown(DropdownType);
      } catch (error) {
        throw error; // TODO
      }
    };
    fetchCategories();
    if (selectedExercise && formType === 'edit') {
      setExerciseName(selectedExercise.title);
      setExerciseDescription(selectedExercise.description);
      setExerciseCategory(selectedExercise.toolCategory.categoryId);
      setRepeatLimit(selectedExercise.repeatLimit);
      setResumeLimit(selectedExercise.resumeLimit);
      setExerciseMediaType(selectedExercise.mediaType);
      setInstructions(selectedExercise.instructions);
      setMediaId(selectedExercise.mediaId);
      //Ver si debo guardar el media en el state tmb
    }
    // Y CARGAR DINÁMICAMENTE categoriesDropdown.
  }, []);

  useEffect(() => {
    setMediaId(0);
    setNewCrops([]);
  }, [exerciseMediaType]);

  const onCancelExercise = () => {
    dispatch(unselectExercise());
    setSaving(false);
    history.push('/tools/exercises');
  };

  const onInstructionChange = (event: CheckboxEvent) => {
    let selectedInstructions = [...instructions];
    if (event.checked) selectedInstructions.push(event.value);
    else selectedInstructions.splice(selectedInstructions.indexOf(event.value), 1);
    setInstructions(selectedInstructions);
  };

  const onArchiveExercise = () => {
    setSaving(false);
    dispatch(unselectExercise());
    //Despacho funcion de archivar.
    history.push('/tools/exercises');
  };

  const onSaveExercise = async () => {
    if (validateSave(exerciseName, mediaId)) {
      let errorEditingMedia = false;
      setSaving(true);
      if (newCrops.length > 0) {
        if (exerciseMediaType === 'audio') {
          const crops: UpdateMediaAudioRequest = {
            fullScreenLandscape: newCrops!.find((crop) => crop.type === 'full_screen_landscape')!.blob,
            fullWidthTall: newCrops!.find((crop) => crop.type === 'full_width_tall')!.blob,
            fullWidthRegular: newCrops!.find((crop) => crop.type === 'full_width_regular')!.blob,
            fullScreenPortrait: newCrops!.find((crop) => crop.type === 'full_screen_portrait')!.blob,
          };
          const mediaAudio = (await updateAudio(mediaId!, crops)) as any;
          if (!(mediaAudio.response.status >= 200 && mediaAudio.response.status < 300)) {
            dispatch(sendMessage({severity: 'error', summary: 'ERROR', detail: 'Something went wrong while editing Media.'}));
            setSaving(false);
            errorEditingMedia = true;
          }
        } else {
          const crops: UpdateMediaVideoRequest = {
            fullWidthTall: newCrops!.find((crop) => crop.type === 'full_width_tall')!.blob,
            fullWidthRegular: newCrops!.find((crop) => crop.type === 'full_width_regular')!.blob,
          };
          const mediaVideo = (await updateVideo(mediaId!, crops)) as any;
          if (!(mediaVideo.response.status >= 200 && mediaVideo.response.status < 300)) {
            dispatch(sendMessage({severity: 'error', summary: 'ERROR', detail: 'Something went wrong while editing Media.'}));
            setSaving(false);
            errorEditingMedia = true;
          }
        }
      }
      //ADDING
      if (!errorEditingMedia) {
        if (!editing) {
          const newExercise: CreateExercise = {
            title: exerciseName,
            description: exerciseDescription,
            language: 'en-US',
            repeatLimit,
            resumeLimit,
            categoryId: exerciseCategory,
            instructions,
            mediaType: exerciseMediaType,
            mediaId: mediaId!,
          };
          try {
            const createdExercise = (await addExercise(newExercise)) as any;
            if (createdExercise.response.status >= 200 && createdExercise.response.status < 300) {
              //dispatch(selectExercise(createdExercise.data.data));
              dispatch(sendMessage({severity: 'success', summary: 'SUCCESS', detail: 'Exercise successfully created'}));
              history.push('/tools/exercises');
            } else {
              dispatch(sendMessage({severity: 'error', summary: 'ERROR', detail: 'Something went wrong'}));
              setSaving(false);
            }
          } catch (e) {
            dispatch(sendMessage({severity: 'error', summary: 'ERROR', detail: 'Something went wrong'}));
          }
        } else {
          //EDITING
          const newExercise: UpdateExercise = {
            title: exerciseName,
            description: exerciseDescription,
            language: 'en-US',
            repeatLimit,
            resumeLimit,
            categoryId: exerciseCategory,
            instructions,
            mediaType: exerciseMediaType,
            mediaId: mediaId,
          };
          try {
            const createdExercise = (await editExercise(selectedExercise?.id!, newExercise)) as any;
            if (createdExercise.response.status >= 200 && createdExercise.response.status < 300) {
              //dispatch(selectExercise(createdExercise.data.data));
              dispatch(sendMessage({severity: 'success', summary: 'SUCCESS', detail: 'Exercise successfully edited'}));
              history.push('/tools/exercises');
            } else {
              dispatch(sendMessage({severity: 'error', summary: 'ERROR', detail: 'Something went wrong'}));
              setSaving(false);
            }
          } catch (e) {
            dispatch(sendMessage({severity: 'error', summary: 'ERROR', detail: 'Something went wrong'}));
          }
        }
      }
    } else {
      dispatch(sendMessage({severity: 'warn', summary: 'WARNING', detail: 'Name and Media can not be empty.'}));
    }
  };

  // const handleOnClickNewVideo = () => {
  //   setMediaLandscape(undefined);
  //   setMediaPortrait(undefined);
  //   setMediaLandscapeURL('');
  //   setMediaPortraitURL('');
  //   // setEditedImage(true);
  // };

  // const handleOnClickNewAudio = () => {
  //   setMediaAudio(undefined);
  //   setMediaAudioURL('');
  //   // setEditedImage(true);
  // };

  return (
    <CursorLoading disable={saving}>
      <PageContainer>
        <PageHeader>{editing ? <PageTitle>Edit Exercise</PageTitle> : <PageTitle>Add Exercise</PageTitle>}</PageHeader>
        <PageBody>
          <AddProgramForm>
            <FormElement
              type='input'
              label='Name'
              value={exerciseName}
              placeholder='Exercise name'
              onChange={(inputValue) => setExerciseName(inputValue)}
            />
            <FormElement
              type='text-area'
              label='Description'
              value={exerciseDescription}
              placeholder='Exercise description'
              onChange={(inputValue) => setExerciseDescription(inputValue)}
            />
            <StyledFormElement>
              <label htmlFor={`inputCategory`}>{'Category'}</label>
              <Dropdown
                value={exerciseCategory}
                options={categoriesDropdown}
                onChange={(e) => setExerciseCategory(e.value)}
                placeholder='Exercise category'
              />
            </StyledFormElement>
            <StyledFormElement>
              <label htmlFor={`input Repeat Limit`}>{'Repeat Limit'}</label>
              <Dropdown
                value={repeatLimit}
                options={repeatLimitDropdown}
                onChange={(e) => setRepeatLimit(e.value)}
                placeholder='Select Repeat Limit'
              />
            </StyledFormElement>
            <StyledFormElement>
              <label htmlFor={`input Resume Limit`}>{'Resume Limit'}</label>
              <Dropdown
                value={resumeLimit}
                options={resumeLimitDropdown}
                onChange={(e) => setResumeLimit(e.value)}
                placeholder='Select Resume Limit'
              />
            </StyledFormElement>
            <StyledFormElement>
              <label htmlFor={`inputType`}>{'Type'}</label>
              <Dropdown
                value={exerciseMediaType}
                options={mediaTypesDropdown}
                onChange={(e) => setExerciseMediaType(e.value)}
                placeholder='Select Type'
              />
            </StyledFormElement>
            <StyledFormElement style={{marginBottom: 8}}>
              <label htmlFor={`inputType`}>{'Instructions'}</label>
            </StyledFormElement>
            <StyleCheckboxElements>
              <div>
                <GeneralCheckbox
                  label={'Quiet place'}
                  formValues={instructions}
                  value={'quiet-place'}
                  onChange={onInstructionChange}
                ></GeneralCheckbox>
              </div>
              <div>
                <GeneralCheckbox
                  label={'Headphones'}
                  formValues={instructions}
                  value={'headphones'}
                  onChange={onInstructionChange}
                ></GeneralCheckbox>
              </div>
              <div>
                <GeneralCheckbox
                  label={'Lie down'}
                  formValues={instructions}
                  value={'lie-down'}
                  onChange={onInstructionChange}
                ></GeneralCheckbox>
              </div>
            </StyleCheckboxElements>
          </AddProgramForm>
          <StyledFormElement>
            {mediaId === 0 && (
              <Header>
                <label>Media File</label>
              </Header>
            )}
            {
              exerciseMediaType === 'audio' ? (
                <>
                  {editing ? (
                    <MediaChooser
                      setMediaId={setMediaId}
                      media={selectedExercise!.media}
                      mediaType={'audio'}
                      key={'Audio'}
                      setNewCrops={setNewCrops}
                    />
                  ) : (
                    <MediaChooser setMediaId={setMediaId} mediaType={'audio'} key={'Audio'} setNewCrops={setNewCrops} />
                  )}
                </>
              ) : (
                <>
                  {editing ? (
                    <MediaChooser
                      setMediaId={setMediaId}
                      media={selectedExercise!.media}
                      mediaType={'video'}
                      key={'Video'}
                      setNewCrops={setNewCrops}
                    />
                  ) : (
                    <MediaChooser setMediaId={setMediaId} mediaType={'video'} key={'Video'} setNewCrops={setNewCrops} />
                  )}
                </>
              ) //There are no repeated keys because you are always editing or not, and video is selected or audio.
            }
          </StyledFormElement>
        </PageBody>
        <PageFooter>
          {editing && <ArchiveButton onClick={onArchiveExercise}>Archive</ArchiveButton>}

          <CancelButton onClick={onCancelExercise}>
            <Link to='/tools/exercises/'>Cancel</Link>
          </CancelButton>
          <SaveButton disabled={saving} onClick={onSaveExercise} disable={saving}>
            {saving ? 'Saving...' : 'Save'}
          </SaveButton>
        </PageFooter>
      </PageContainer>
    </CursorLoading>
  );
};
