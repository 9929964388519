import React, {ChangeEvent, createRef, useEffect, useState} from 'react';
import styled from 'styled-components';
import {ImageCropper} from 'components/image-cropper';
import {Button} from 'primereact/button';
import {getCanvasBlob} from 'utils/helpers';
import {ButtonLink} from '../media-details/audio/styles';
import {MediaSettings} from 'components/media-settings';

type SelectImageBoxProps = {
  isVisible: boolean;
  image: boolean;
};

const CroppedImage = styled.img`
  cursor: pointer;
  max-height: 500px;
  max-width: 500px;
  :hover {
    opacity: 70%;
  }
  transition: 0.1s;
`;

const SelectImageBox = styled.div`
  align-items: center;
  border: 2px dashed #bcc1c7;
  box-sizing: border-box;
  border-radius: 8px;
  display: ${(props: SelectImageBoxProps) => (props.isVisible ? 'flex' : 'none')};
  flex-direction: column;
  height: ${(props: SelectImageBoxProps) => (props.image ? '550px' : '275px')};
  justify-content: center;
  width: 700px;

  p {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    /* identical to box height, or 150% */

    color: #777c7c;
  }

  :hover:not(:focus-within) {
    background-color: #fafafa;
    cursor: pointer;
    transition: 0.1s;
  }
`;

const CropContainer = styled.div``;

const getAspectRatioCropParams = (originalWidth: number, originalHeight: number, transformedRatio: number, fill = false) => {
  let sx = 0;
  let sy = 0;
  let sWidth = originalWidth;
  let sHeight = originalHeight;
  let dx = 0;
  let dy = 0;
  let dWidth = originalWidth;
  let dHeight = originalHeight;
  let canvasWidth = originalWidth;
  let canvasHeight = originalHeight;

  const originalRatio = originalWidth / originalHeight;

  if (originalRatio > transformedRatio) {
    if (fill) {
      canvasHeight = originalWidth / transformedRatio;
      dy = Math.abs(canvasHeight - originalHeight) / 2;
    } else {
      sWidth = originalWidth * transformedRatio;
      canvasWidth = sWidth;
      dWidth = sWidth;
      sx = Math.abs(originalWidth - sWidth) / 2;
    }
  }

  if (originalRatio < transformedRatio) {
    if (fill) {
      canvasWidth = originalHeight * transformedRatio;
      dx = Math.abs(canvasWidth - originalWidth) / 2;
    } else {
      sHeight = originalWidth / transformedRatio;
      canvasHeight = sHeight;
      dHeight = sHeight;
      sy = Math.abs(originalHeight - sHeight) / 2;
    }
  }

  return {
    sx,
    sy,
    sWidth,
    sHeight,
    dx,
    dy,
    dWidth,
    dHeight,
    canvasWidth,
    canvasHeight,
  };
};

type ImageCropperManagerProps = {
  mediaType: MediaType;
  setOriginalImage: (image: Blob) => void;
  addCrop: (crop: Crop) => void;
  imagesCrop?: CropDto[];
  image?: string;
  imageForSettings?: string;
  onUpdateImage?: () => void;
  imagesTypes?: CropType[];
};

export const ImageCropperManager: React.FC<ImageCropperManagerProps> = ({
  mediaType,
  setOriginalImage,
  addCrop,
  imagesCrop,
  image,
  imageForSettings,
  children,
  onUpdateImage,
  imagesTypes,
}) => {
  const [originalImageUrl, setOriginalImageUrl] = useState('');
  const [isImageCropperOpen, setIsImageCropperOpen] = useState(false);
  const fileSelectorRef = createRef<HTMLInputElement>();

  const [squaredImg, setSquaredImg] = useState('');
  const [fullWidthImg, setFullWidthImg] = useState('');
  const [landScapeImg, setLandScapeImg] = useState('');
  const [portraitImg, setPortraitImg] = useState('');
  const [tallImg, setTallImg] = useState('');

  const [squaredBlob, setSquaredBlob] = useState<Blob>();
  const [fullWidthBlob, setFullWidthBlob] = useState<Blob>();
  const [landScapeBlob, setLandScapeBlob] = useState<Blob>();
  const [portraitBlob, setPortraitBlob] = useState<Blob>();
  const [tallBlob, setTallBlob] = useState<Blob>();

  const [squaredCrop, setSquaredCrop] = useState<CanvasData>();
  const [fullWidthCrop, setFullWidthCrop] = useState<CanvasData>();
  const [landScapeCrop, setLandScapeCrop] = useState<CanvasData>();
  const [portraitCrop, setPortraitCrop] = useState<CanvasData>();
  const [tallCrop, setTallCrop] = useState<CanvasData>();

  const [editingCropType, setEditingCropType] = useState<CropType>();
  const [aspectRatioSelected, setAspectRatioSelected] = useState<number>();
  const [minHeigthSelected, setMinHeigthSelected] = useState<number>();
  const [minWidthSelected, setMinWidthSelected] = useState<number>();

  const [originalImageSettings, setOriginalImageSettings] = useState('');

  useEffect(() => {
    if (imagesCrop && image) {
      imageForSettings && setOriginalImageSettings(imageForSettings);
      setOriginalImageUrl(image);
      if (mediaType !== 'video' && mediaType !== 'audio') {
        const squared = imagesCrop.find((x) => x.type === 'squared')?.url;
        setSquaredImg(squared ? squared : '');
      }

      const fullWidth = imagesCrop.find((x) => x.type === 'full_width_regular')?.url;
      setFullWidthImg(fullWidth ? fullWidth : '');

      if (mediaType !== 'video') {
        const landScape = imagesCrop.find((x) => x.type === 'full_screen_landscape')?.url;
        setLandScapeImg(landScape ? landScape : '');
      }

      if (mediaType !== 'video') {
        const portrait = imagesCrop.find((x) => x.type === 'full_screen_portrait')?.url;
        setPortraitImg(portrait ? portrait : '');
      }
      const tall = imagesCrop.find((x) => x.type === 'full_width_tall')?.url;
      setTallImg(tall ? tall : '');
    }
  }, [imagesCrop, image]);

  useEffect(() => {
    squaredBlob && addCrop({type: 'squared', blob: squaredBlob});
  }, [squaredBlob]);

  useEffect(() => {
    fullWidthBlob && addCrop({type: 'full_width_regular', blob: fullWidthBlob});
  }, [fullWidthBlob]);

  useEffect(() => {
    landScapeBlob && addCrop({type: 'full_screen_landscape', blob: landScapeBlob});
  }, [landScapeBlob]);

  useEffect(() => {
    portraitBlob && addCrop({type: 'full_screen_portrait', blob: portraitBlob});
  }, [portraitBlob]);

  useEffect(() => {
    tallBlob && addCrop({type: 'full_width_tall', blob: tallBlob});
  }, [tallBlob]);

  const onChooseImage = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const blob = event.target.files[0];
      const image = URL.createObjectURL(blob);
      setOriginalImage(blob);
      setOriginalImageUrl(image);
      setOriginalImageSettings(image);
      createCanvas(image);
    }
  };

  const createCanvas = (image: string) => {
    createSquared(image);
    createFullWidth(image);
    createLandscape(image);
    createPortrait(image);
    createTall(image);
  };

  const createSquared = async (image: string) => {
    var img = new Image();
    var canvas: any = document.getElementById('squared');
    var ctx = canvas && canvas.getContext('2d');

    img.onload = async function () {
      const size = Math.min(img.width, img.height);

      if (squaredCrop) {
        ctx.drawImage(img, squaredCrop.left, squaredCrop.top, squaredCrop.width, squaredCrop.height, 0, 0, 300, 300);
      } else ctx.drawImage(img, 0, 0, size, size, 0, 0, 300, 300);

      setSquaredImg(canvas?.toDataURL());

      if (mediaType === 'image') {
        const blob = await getCanvasBlob(canvas);
        setSquaredBlob(blob);
      }
    };
    img.src = image;
  };

  const createFullWidth = async (image: string) => {
    var img = new Image();
    var canvas: any = document.getElementById('fullWidth');
    var ctx = canvas && canvas.getContext('2d');

    img.onload = async function () {
      // 25 : 8 proportion
      const size = img.width * (8 / 25);

      if (fullWidthCrop)
        ctx.drawImage(img, fullWidthCrop.left, fullWidthCrop.top, fullWidthCrop.width, fullWidthCrop.height, 0, 0, 400, 128);
      else ctx.drawImage(img, 0, 0, img.width, size, 0, 0, 400, 128);

      setFullWidthImg(canvas?.toDataURL());
      const blob = await getCanvasBlob(canvas);
      setFullWidthBlob(blob);
    };
    img.src = image;
  };

  const createLandscape = async (image: string) => {
    var img = new Image();
    var canvas: any = document.getElementById('landScape');
    var ctx = canvas && canvas.getContext('2d');

    img.onload = async () => {
      const {sx, sy, sWidth, sHeight, dx, dy, dWidth, dHeight, canvasWidth, canvasHeight} = getAspectRatioCropParams(
        img.width,
        img.height,
        16 / 9,
        false
      );

      canvas.width = canvasWidth;
      canvas.height = canvasHeight;

      ctx.fillStyle = '#000000';
      ctx.fillRect(0, 0, canvasWidth, canvasHeight);

      console.log('landscapeCrop', landScapeCrop);

      if (landScapeCrop)
        ctx.drawImage(
          img,
          landScapeCrop.left,
          landScapeCrop.top,
          landScapeCrop.width,
          landScapeCrop.height,
          0,
          0,
          dWidth,
          dHeight
        );
      else ctx.drawImage(img, sx, sy, sWidth, sHeight, dx, dy, dWidth, dHeight);

      setLandScapeImg(canvas?.toDataURL());

      if (mediaType === 'audio' || mediaType === 'image') {
        const blob = await getCanvasBlob(canvas);
        setLandScapeBlob(blob);
      }
    };
    img.src = image;
  };

  const createPortrait = (image: string) => {
    var img = new Image();
    var canvas: any = document.getElementById('portrait');
    var ctx = canvas && canvas.getContext('2d');

    img.onload = async () => {
      const {sx, sy, sWidth, sHeight, dx, dy, dWidth, dHeight, canvasWidth, canvasHeight} = getAspectRatioCropParams(
        img.width,
        img.height,
        9 / 16,
        false
      );

      canvas.width = canvasWidth;
      canvas.height = canvasHeight;

      ctx.fillStyle = '#000000';
      ctx.fillRect(0, 0, canvasWidth, canvasHeight);

      console.log('portraitCrop', portraitCrop);

      if (portraitCrop)
        ctx.drawImage(img, portraitCrop.left, portraitCrop.top, portraitCrop.width, portraitCrop.height, 0, 0, dWidth, dHeight);
      else ctx.drawImage(img, sx, sy, sWidth, sHeight, dx, dy, dWidth, dHeight);

      setPortraitImg(canvas?.toDataURL());

      if (mediaType === 'audio' || mediaType === 'image') {
        const blob = await getCanvasBlob(canvas);
        setPortraitBlob(blob);
      }
    };
    img.src = image;
  };

  const createTall = (image: string) => {
    var img = new Image();
    var canvas: any = document.getElementById('tall');
    var ctx = canvas && canvas.getContext('2d');

    ctx.clearRect(0, 0, canvas.width, canvas.height);

    img.onload = async () => {
      // 12 : 7 proportion

      const {sx, sy, sWidth, sHeight, dx, dy, dWidth, dHeight, canvasWidth, canvasHeight} = getAspectRatioCropParams(
        img.width,
        img.height,
        12 / 7,
        false
      );

      if (tallCrop) ctx.drawImage(img, tallCrop.left, tallCrop.top, tallCrop.width, tallCrop.height, 0, 0, dWidth, dHeight);
      else ctx.drawImage(img, sx, sy, sWidth, sHeight, dx, dy, dWidth, dHeight);

      setTallImg(canvas?.toDataURL());
      const blob = await getCanvasBlob(canvas);
      setTallBlob(blob);
    };
    img.src = image;
  };

  const onCancelCrop = () => {
    setIsImageCropperOpen(false);
    setEditingCropType(undefined);
  };

  const onSaveCrop = (blob?: Blob, cropper?: any) => {
    // console.log('canvasData', cropper);

    if (blob) {
      const imageSrcCropped = URL.createObjectURL(blob);

      const canvasData: CanvasData = {
        height: cropper.height,
        width: cropper.width,
        top: cropper.y,
        left: cropper.x,
      };

      switch (editingCropType) {
        case 'full_screen_landscape':
          setLandScapeImg(imageSrcCropped);
          setLandScapeBlob(blob);
          setLandScapeCrop(canvasData);
          break;
        case 'full_screen_portrait':
          setPortraitImg(imageSrcCropped);
          setPortraitBlob(blob);
          setPortraitCrop(canvasData);
          break;
        case 'full_width_regular':
          setFullWidthImg(imageSrcCropped);
          setFullWidthBlob(blob);
          setFullWidthCrop(canvasData);
          break;
        case 'full_width_tall':
          setTallImg(imageSrcCropped);
          setTallBlob(blob);
          setTallCrop(canvasData);
          break;
        case 'squared':
          setSquaredImg(imageSrcCropped);
          setSquaredBlob(blob);
          console.log('canvas', canvasData);
          setSquaredCrop(canvasData);
          break;
        default:
          break;
      }
    }
    setEditingCropType(undefined);
    setIsImageCropperOpen(false);
  };

  const openFileSelector = () => {
    console.log('file', fileSelectorRef);
    if (fileSelectorRef && fileSelectorRef.current) {
      fileSelectorRef.current.click();
    }
  };

  const onClickImage = (cropType: CropType) => {
    setEditingCropType(cropType);

    if (cropType === 'squared') {
      setAspectRatioSelected(1 / 1);
      // setMinHeigthSelected(300);
      // setMinWidthSelected(300);
    } else if (cropType === 'full_width_regular') {
      setAspectRatioSelected(25 / 8);
      // setMinHeigthSelected(128);
      // setMinWidthSelected(400);
    } else if (cropType === 'full_screen_landscape') {
      setAspectRatioSelected(16 / 9);
      // setMinWidthSelected(2500);
    } else if (cropType === 'full_screen_portrait') {
      setAspectRatioSelected(9 / 16);
    } else if (cropType === 'full_width_tall') {
      setAspectRatioSelected(12 / 7);
      // setMinHeigthSelected(525);
      // setMinWidthSelected(900);
    }
    setIsImageCropperOpen(true);
  };

  const onSaveSettings = (brightnessValue: number, contrastValue: number, urlImage: string) => {
    //TODO: Save settings values
    setOriginalImageUrl(urlImage);
    createCanvas(urlImage);
  };

  return (
    <div>
      <canvas id='squared' width='300px' height='300px' style={{display: 'none'}}></canvas>
      <canvas id='fullWidth' width='400px' height='128px' style={{display: 'none'}}></canvas>
      <canvas id='landScape' width='854px' height='480px' style={{display: 'none'}}></canvas>
      <canvas id='portrait' width='480px' height='854px' style={{display: 'none'}}></canvas>
      <canvas id='tall' width='900px' height='525px' style={{display: 'none'}}></canvas>
      <input ref={fileSelectorRef} type='file' accept='.jpg' onChange={onChooseImage} style={{visibility: 'hidden'}} />

      {originalImageUrl === '' ? (
        <>
          {children ? (
            children
          ) : (
            <SelectImage
              mediaType={mediaType}
              openFileSelector={openFileSelector}
              fileSelectorRef={fileSelectorRef}
              onChooseImage={onChooseImage}
            />
          )}
        </>
      ) : (
        <>
          <div style={{display: 'flex', justifyContent: 'space-between'}}>
            <label>Images formats</label>
            <MediaSettings mediaUrl={originalImageSettings} onSaveSettings={onSaveSettings} />
            <ButtonLink onClick={onUpdateImage ? onUpdateImage : openFileSelector}>Update new Image</ButtonLink>
          </div>
          <CropContainer>
            {(!imagesTypes || imagesTypes.includes('full_width_regular')) && (
              <img src={fullWidthImg} width='600' onClick={() => onClickImage('full_width_regular')} alt={'Alt'} />
            )}

            {(!imagesTypes || imagesTypes.includes('squared')) && mediaType !== 'video' && mediaType !== 'audio' && (
              <img src={squaredImg} width='600' onClick={() => onClickImage('squared')} />
            )}

            {(!imagesTypes || imagesTypes.includes('full_screen_landscape')) && mediaType !== 'video' && (
              <img src={landScapeImg} width='600' onClick={() => onClickImage('full_screen_landscape')} />
            )}

            {(!imagesTypes || imagesTypes.includes('full_screen_portrait')) && mediaType !== 'video' && (
              <img src={portraitImg} width='300' onClick={() => onClickImage('full_screen_portrait')} />
            )}
            {(!imagesTypes || imagesTypes.includes('full_width_tall')) && (
              <img src={tallImg} width='450' onClick={() => onClickImage('full_width_tall')} />
            )}
          </CropContainer>
        </>
      )}

      <ImageCropper
        imageSrc={originalImageUrl}
        isOpen={isImageCropperOpen}
        onCancelCrop={onCancelCrop}
        onSaveCrop={onSaveCrop}
        aspectRatio={aspectRatioSelected}
        minHeight={minHeigthSelected}
        minWidth={minWidthSelected}
      />
    </div>
  );
};

type SelectImageProps = {
  mediaType: MediaType;
  openFileSelector: () => void;
  onChooseImage: (event: ChangeEvent<HTMLInputElement>) => void;
  fileSelectorRef: React.RefObject<HTMLInputElement>;
};

const SelectImage: React.FC<SelectImageProps> = ({mediaType, openFileSelector, onChooseImage, fileSelectorRef}) => {
  return (
    <>
      <label>Image</label>
      <SelectImageBox image={mediaType === 'image'} onClick={openFileSelector} isVisible={true}>
        <p>Select an image</p>
        <Button className='p-button-outlined p-mr-2 p-mb-2'> Select an image </Button>
        <input ref={fileSelectorRef} accept='.jpg' type='file' onChange={onChooseImage} style={{visibility: 'hidden'}} />
      </SelectImageBox>
    </>
  );
};
