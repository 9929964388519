import styled from 'styled-components';

export const StyleCheckboxElements = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  & > div {
    flex: 1;
  }
`;

export const SpaceCheckboxes = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
`;
