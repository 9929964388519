import React from 'react';
import ReactDOM from 'react-dom';

import {Provider} from 'react-redux';
import {activityReducer, programReducer, exerciseReducer, mediaBankReducer, generalToastReducer} from './store/reducers';
import {configureStore} from '@reduxjs/toolkit';

import AppWrapper from './components/app/app.wrapper';
import {AuthProvider} from 'contexts/auth.context';

import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'styles/index.scss';
import {BrowserRouter as Router} from 'react-router-dom';

const store = configureStore({
  reducer: {
    activities: activityReducer,
    programs: programReducer,
    exercises: exerciseReducer,
    mediaBank: mediaBankReducer,
    toast: generalToastReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

ReactDOM.render(
  <React.StrictMode>
    <AuthProvider>
      <Provider store={store}>
        <Router>
          <AppWrapper />
        </Router>
      </Provider>
    </AuthProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
