import React, {ChangeEvent, createRef} from 'react';
import styled from 'styled-components';

type SelectAudioBoxProps = {
  isVisible: boolean;
  isPortrait: boolean;
};

const SelectAudioBox = styled.div`
  align-items: center;
  border: 2px dashed #bcc1c7;
  box-sizing: border-box;
  border-radius: 8px;
  display: ${(props: SelectAudioBoxProps) => (props.isVisible ? 'flex' : 'none')};
  flex-direction: column;
  justify-content: center;
  height: 220px;
  width: 700;
  p {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;

    color: #777c7c;
  }

  :hover:not(:focus-within) {
    background-color: #fafafa;
    cursor: pointer;
    transition: 0.1s;
  }
`;

type AudioUploaderProps = {
  isVisible?: boolean;
  isPortrait?: boolean;
  onUploadAudio: (audio: File) => void;
};

const AudioUploader: React.FC<AudioUploaderProps> = ({children, isVisible = true, isPortrait = false, onUploadAudio}) => {
  const audioUploader = createRef<HTMLInputElement>();

  const openAudioUploader = () => {
    if (audioUploader && audioUploader.current) {
      audioUploader.current.click();
    }
  };

  const onChangeEvent = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      onUploadAudio(event.target.files[0]);
    }
  };

  return (
    <SelectAudioBox onClick={openAudioUploader} isVisible={isVisible} isPortrait={isPortrait}>
      {children}
      <input ref={audioUploader} type='file' accept='.mp3' onChange={onChangeEvent} style={{visibility: 'hidden'}} />
    </SelectAudioBox>
  );
};

export default AudioUploader;
