import React, {useState, useEffect} from 'react';
import {
  PageContainer as GlobalPageContaner,
  PageHeader,
  PageTitle,
  PageBody,
  AddProgramForm,
  PageFooter,
  CancelButton,
  SaveButton,
  Label,
} from 'components/page';
import {SelectButton} from 'primereact/selectbutton';
import styled from 'styled-components';
import {getActivityTypesTools, addActivityToProgram} from 'api/chatowl.api';
import {Link, useHistory} from 'react-router-dom';
import {Dropdown} from 'primereact/dropdown';
import {StyledFormElement} from 'components/form-element';
import {InputSwitch} from 'primereact/inputswitch';
import {priorityValues, repeatValues} from '../../../utils/values';
import {useAppSelector} from 'hooks';
import {RootState} from 'index';
import {shallowEqual} from 'react-redux';

const PageContainer = styled(GlobalPageContaner)`
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  padding-left: 60px;
`;

const SwitchContainer = styled(StyledFormElement)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-right: 25%;
`;

export const AddActivity: React.FC = () => {
  const [savingProgram, setSavingProgram] = useState(false);
  const [moment, setMoment] = useState('morning');
  const [type, setType] = useState<ActivityType>('add');
  const [tool, setTool] = useState(1);
  const [priority, setPriority] = useState(1);
  const [repeat, setRepeat] = useState(86400);
  const [mandatory, setMandatory] = useState(false);
  const [free, setFree] = useState<boolean | undefined>(false);
  const [locked, setLocked] = useState<boolean | undefined>(false);
  const [notifications, setNotifications] = useState<boolean | undefined>(false);
  const [emails, setEmails] = useState<boolean | undefined>(false);
  const [toolValues, setToolValues] = useState<ComboOptionDto[]>([]);
  const [typeValues, setTypeValues] = useState<ComboOptionDto[]>([]);

  const selectedActivity: ActivityDto | null = useAppSelector(
    (state: RootState) => state.activities.selectedActivity,
    shallowEqual
  );

  const selectedProgram: ProgramDto | null = useAppSelector((state: RootState) => state.programs.selectedProgram, shallowEqual);

  const history = useHistory();

  const fetchActivityTypesTools = async () => {
    try {
      const response = (await getActivityTypesTools()) as any;
      const types = response.data.data;
      console.log(types);
      setTypeValues(types);
      setType(types[0].value);
      setToolValues(types[0].labels);
      setTool(types[0].labels[0].value);
    } catch (error) {
      throw error; // TODO
    }
  };

  useEffect(() => {
    fetchActivityTypesTools();
  }, []);

  const onCancelProgram = () => {};

  const onSaveProgram = async () => {
    setSavingProgram(true);

    try {
      const activity = {
        toolId: tool,
        type,
        isFree: free,
        isLocked: locked,
        isMandatory: mandatory,
        repeatInterval: repeat,
        priority,
        timeDue: {
          day: selectedActivity?.timeDue.day,
          moment: moment,
          order: 1,
        },
        sendsEmail: emails,
        sendsPushNotification: notifications,
      };

      const programId = selectedProgram?.id;

      if (!programId) throw Error();

      await addActivityToProgram(programId, activity);

      setSavingProgram(false);
      history.push('/therapy-programs');
    } catch {
      //TODO: handle error
      setSavingProgram(false);
    }
  };

  const selectMomentValues = [
    {name: 'Morning', value: 'morning'},
    {name: 'Midday', value: 'midday'},
    {name: 'Afternoon', value: 'afternoon'},
    {name: 'Evening', value: 'evening'},
  ];

  const onChangeType = (e: any) => {
    setType(e.value);
    const type = typeValues.find((x) => x.value === e.value);
    if (!!type && type.labels) {
      setToolValues(type.labels);
      setTool(parseInt(type.labels[0].value.toString()));
    }
  };

  return (
    <PageContainer>
      <PageHeader>
        <PageTitle>Add Activity</PageTitle>
      </PageHeader>
      <PageBody>
        <AddProgramForm>
          <StyledFormElement>
            <SelectButton value={moment} onChange={(e) => setMoment(e.value)} options={selectMomentValues} optionLabel='name' />
          </StyledFormElement>
          <StyledFormElement>
            <Label>Type</Label>
            <Dropdown value={type} onChange={onChangeType} options={typeValues} optionLabel='name' />
          </StyledFormElement>
          <StyledFormElement>
            <Label>Tool</Label>
            <Dropdown value={tool} onChange={(e) => setTool(e.value)} options={toolValues} optionLabel='name' />
          </StyledFormElement>
          <StyledFormElement>
            <Label>Priority</Label>
            <Dropdown value={priority} onChange={(e) => setPriority(e.value)} options={priorityValues} optionLabel='name' />
          </StyledFormElement>
          {type === 'due' && (
            <>
              <StyledFormElement>
                <Label>Repeat</Label>
                <Dropdown value={repeat} onChange={(e) => setRepeat(e.value)} options={repeatValues} optionLabel='name' />
              </StyledFormElement>
              <SwitchContainer>
                <Label>Mandatory</Label>
                <InputSwitch checked={mandatory} onChange={(e) => setMandatory(e.value)} />
              </SwitchContainer>
            </>
          )}
          {type === 'add' && (
            <>
              <SwitchContainer>
                <Label>Free</Label>
                <InputSwitch checked={free} onChange={(e) => setFree(e.value)} />
              </SwitchContainer>
              <SwitchContainer>
                <Label>Locked</Label>
                <InputSwitch checked={locked} onChange={(e) => setLocked(e.value)} />
              </SwitchContainer>
            </>
          )}
          <SwitchContainer>
            <Label>Notifications</Label>
            <InputSwitch checked={notifications} onChange={(e) => setNotifications(e.value)} />
          </SwitchContainer>
          <SwitchContainer>
            <Label>Emails</Label>
            <InputSwitch checked={emails} onChange={(e) => setEmails(e.value)} />
          </SwitchContainer>
        </AddProgramForm>
        <StyledFormElement></StyledFormElement>
      </PageBody>
      <PageFooter>
        <CancelButton onClick={onCancelProgram}>
          <Link to='/therapy-programs/'>Cancel</Link>
        </CancelButton>
        <SaveButton onClick={onSaveProgram}>Save</SaveButton>
      </PageFooter>
    </PageContainer>
  );
};
