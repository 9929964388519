import styled from 'styled-components';

export const ActionButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10px;
`;

export const CloseModal = styled.div`
  position: absolute;
  top: 10px;
  right: 20px;
  color: #9ba2aa;
  font-size: 28px;
  cursor: pointer;
`;

export const ButtonLink = styled.div`
  color: #2c98f0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-size: 14px;
  font-weight: normal;
  line-height: 17px;
  padding: 10px;
  user-select: none;

  :hover {
    text-decoration: underline;
  }
`;

export const ModalBody = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;
