import {useState, useEffect} from 'react';
import {TypedUseSelectorHook, useDispatch, useSelector} from 'react-redux';
import type {RootState, AppDispatch} from './index';

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const useTags = () => {
  const [tags, setTags] = useState<Tag[]>();

  // add tag only if not exists
  const pushTag = (newTag: Tag) => {
    const newTags = tags?.includes(newTag) ? tags : [...(tags || []), newTag];
    setTags(newTags);
  };

  const dropTag = (deleted: Tag) => {
    const newTags = tags?.filter((tag) => tag !== deleted) || [];
    setTags(newTags);
  };

  const addTagsList = (tagList: Tag[]) => {
    setTags(tagList);
  };

  return {
    list: tags,
    push: pushTag,
    drop: dropTag,
    initTag: addTagsList,
  };
};
