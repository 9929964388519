import GeneralSidebar from 'components/general-sidebar';
import {AddButton, PageContainer, PageHeader, PageTitle} from 'components/page';
import {SearchBar} from 'components/search-bar';
import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {Dropdown} from 'primereact/dropdown';
import FilterToggle from 'components/filter-toggle';
import {useAppSelector, useAppDispatch} from 'hooks';
import {RootState} from 'index';
import {shallowEqual} from 'react-redux';
import {selectMedia, unselectMedia} from 'store/action-creators';
import {listMedias, getMediaTags} from 'api/chatowl.api';
import {capitalize} from 'utils/helpers';
import {
  CardContent,
  CardImage,
  CardThumbnail,
  CardsWrapper,
  CardTitle,
  ImageTag,
  MediaType,
  FilterWrapper,
  ToggleContainer,
  PDButtonLink,
  PDTitle,
  PDID,
  PDHeader,
  PDField,
  PDFieldValue,
  Label,
  PDFieldLabel,
  MediasNotFound,
} from './styles';

const typest = [
  {name: 'All Types', value: 'all-types'},
  {name: 'Audio', value: 'audio'},
  {name: 'Video', value: 'video'},
  {name: 'Image', value: 'image'},
];

const tagst = [
  {name: 'All tags', value: 0},
  {name: 'Tag 1', value: 1},
  {name: 'Tag 2', value: 2},
];

const filter = ['Used', 'Available'];
export const MediaBank: React.FC = () => {
  const selectedMedia: MediaDto | null = useAppSelector((state: RootState) => state.mediaBank.selectedMedia, shallowEqual);
  const [medias, setMedias] = useState<MediaDto[]>([]); //Load with API call in useEffect

  const [searchValue, setSearchValue] = useState<string>('');
  // const [types, setTypes] = useState();
  // const [tags, setTags] = useState();
  const [typeValue, setTypeValue] = useState('all-types');
  const [tagValue, setTagValue] = useState('All Tags');
  const [tagOptions, setTagOptions] = useState<LabelDto[]>([]);
  const [selectedFilter, setSelectedFilter] = useState<string[]>([]);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(unselectMedia());
    const fetchMedia = async () => {
      try {
        const response = (await listMedias()) as any;
        const mediasResponse = response.data ? response.data.data : [];
        setMedias(mediasResponse);
      } catch (error) {
        throw error; // TODO
      }
    };
    const fetchTags = async () => {
      try {
        const response = (await getMediaTags()) as any;
        const mediasResponse = response.data ? response.data.data : [];
        const tagsString: string[] = mediasResponse;
        const tagOptionsNew: LabelDto[] = tagsString.map((tag) => {
          return {name: capitalize(tag), value: tag};
        });
        setTagOptions([{name: 'All Tags', value: 'All Tags'}, ...tagOptionsNew]);
      } catch (error) {
        throw error; // TODO
      }
    };
    fetchMedia();
    fetchTags();
  }, []);

  useEffect(() => {
    if (selectedMedia) {
      if (!mediasFiltered.find((media) => media.id === selectedMedia.id)) {
        dispatch(unselectMedia());
      }
    }
  }, [typeValue, searchValue, selectedFilter, tagValue]);

  const history = useHistory();

  const Card = (media: MediaDto) => {
    const isSelected = selectedMedia?.id === media.id;
    const onClick = () => {
      media.id !== selectedMedia?.id
        ? dispatch(selectMedia(media)) //ESTO CAMBIARLO POR dispatch(selectMedia(media)). Es solo de prueba
        : dispatch(unselectMedia());
    };

    return (
      <div className={'p-col-fixed'}>
        <CardContent className='box p-shadow-6' onClick={onClick} isSelected={isSelected}>
          <CardImage>
            <CardThumbnail src={media.crops.find((c) => c.type === 'squared')?.url} />
            {media.isInUse && (
              <ImageTag>
                <i className='pi pi-check-circle' style={{alignSelf: 'center', marginRight: '5px'}}></i>In use
              </ImageTag>
            )}
            <MediaType>
              {media.type === 'video' && (
                <svg width='28' height='30' viewBox='0 0 18 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
                  <path
                    d='M1.56438 19.7544C2.02896 19.7544 2.42385 19.57 2.88842 19.3049L16.4308 11.5369C17.3948 10.9722 17.7316 10.6034 17.7316 9.99252C17.7316 9.38169 17.3948 9.01288 16.4308 8.45967L2.88842 0.680146C2.42385 0.415066 2.02896 0.242188 1.56438 0.242188C0.704917 0.242188 0.170654 0.8876 0.170654 1.89029V18.0948C0.170654 19.0974 0.704917 19.7544 1.56438 19.7544Z'
                    fill='white'
                  />
                </svg>
              )}
              {media.type === 'image' && (
                <svg width='30' height='25' viewBox='0 0 24 19' fill='none' xmlns='http://www.w3.org/2000/svg'>
                  <path
                    d='M3.53191 19H20.4787C22.766 19 24 17.7684 24 15.5263V3.46316C24 1.23158 22.766 0 20.4787 0H3.53191C1.24468 0 0 1.22105 0 3.46316V15.5263C0 17.7684 1.24468 19 3.53191 19ZM8.30851 9.61053C6.91489 9.61053 5.76596 8.47368 5.76596 7.08421C5.76596 5.71579 6.91489 4.56842 8.30851 4.56842C9.70213 4.56842 10.8404 5.71579 10.8404 7.08421C10.8404 8.47368 9.70213 9.61053 8.30851 9.61053ZM3.87234 16.4842C3.04255 16.4842 2.54255 15.9895 2.54255 15.1579V14.0947L5.02128 11.9474C5.44681 11.5684 5.8617 11.3789 6.35106 11.3789C6.82979 11.3789 7.31915 11.5789 7.75532 11.9579L9.64894 13.6421L14.3298 9.51579C14.8085 9.09474 15.3298 8.90526 15.8936 8.90526C16.4255 8.90526 16.9894 9.10526 17.4362 9.52632L21.4681 13.2105V15.1684C21.4681 15.9895 20.9149 16.4842 20.1383 16.4842H3.87234Z'
                    fill='white'
                  />
                </svg>
              )}
              {media.type === 'audio' && (
                <svg width='30' height='28' viewBox='0 0 24 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
                  <path
                    d='M13.6547 22C14.8547 22 15.7768 21.101 15.7768 19.9149V2.1101C15.7768 0.92395 14.8547 0 13.6547 0C12.9095 0 12.3032 0.274688 11.52 0.998865L6.79579 5.34393C6.73263 5.39387 6.66947 5.43133 6.59368 5.43133H3.30947C1.17474 5.43133 0 6.64245 0 8.82747V13.185C0 15.37 1.17474 16.5812 3.30947 16.5812H6.58105C6.65684 16.5812 6.72 16.5936 6.79579 16.6561L11.52 21.0261C12.2653 21.7128 12.8716 22 13.6547 22ZM20.0084 16.8683C20.8042 17.3428 21.7768 17.1555 22.2695 16.4688C23.3558 14.9705 24 13.0352 24 10.9875C24 8.92735 23.3558 7.00454 22.2695 5.49376C21.7768 4.81952 20.8042 4.61975 20.0084 5.1067C19.1495 5.61862 18.9347 6.62997 19.6547 7.8286C20.2232 8.7151 20.5389 9.82633 20.5389 10.9875C20.5389 12.1487 20.2232 13.2474 19.6547 14.1464C18.9347 15.3451 19.1495 16.3439 20.0084 16.8683Z'
                    fill='white'
                  />
                </svg>
              )}
            </MediaType>
          </CardImage>
          <CardTitle>{media.name}</CardTitle>
        </CardContent>
      </div>
    );
  };

  const EmptyCard = () => {
    return (
      <div className={'p-col-fixed'} style={{visibility: 'hidden'}}>
        <CardContent className='box p-shadow-6' onClick={onClick} isSelected={false}>
          <CardImage>
            <CardThumbnail src='' />
            <MediaType></MediaType>
          </CardImage>
          <CardTitle>Hola</CardTitle>
        </CardContent>
      </div>
    );
  };

  const onChangeSearchBar = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const onClick = (): void => {
    dispatch(unselectMedia());
  };

  const filterByStatus = (media: MediaDto) =>
    !selectedFilter.length ||
    (selectedFilter.includes('Used') && media.isInUse) ||
    (selectedFilter.includes('Available') && !media.isInUse);

  const filterBySearchValue = (media: MediaDto) =>
    !searchValue.toLowerCase() ||
    media.name.toLowerCase().includes(searchValue.toLowerCase()) ||
    media?.tags?.toString()?.toLowerCase().includes(searchValue.toLowerCase());
  //Ver si esta bien poder buscar por media Tag.

  const filterByType = (media: MediaDto) =>
    typeValue === 'all-types' ||
    (typeValue === 'audio' && media.type === 'audio') ||
    (typeValue === 'video' && media.type === 'video') ||
    (typeValue === 'image' && media.type === 'image');

  const filterByTag = (media: MediaDto) =>
    tagValue === 'All Tags' || media!.tags!.find((tag) => tag.toUpperCase() === tagValue.toUpperCase());

  const mediasFiltered = medias
    .filter(filterByStatus)
    .filter(filterBySearchValue)
    .filter(filterByType)
    .filter(filterByTag)
    .sort((a, b) => a.name.localeCompare(b.name));

  return (
    <PageContainer>
      <div style={{width: '100%', overflow: 'auto', padding: '0 20px'}}>
        <PageHeader>
          <PageTitle>Media Bank</PageTitle>
          <AddButton onClick={() => history.push('/media-bank/detail/add')}>+ Add Media</AddButton>
        </PageHeader>
        <ToggleContainer>
          {filter.map((x) => (
            <FilterToggle filter={x} selectedFilter={selectedFilter} setSelectedFilter={setSelectedFilter} />
          ))}
        </ToggleContainer>
        <FilterWrapper>
          <SearchBar onChange={onChangeSearchBar} />

          <div>
            <Dropdown
              style={{width: '200px', marginRight: ' 10px'}}
              value={typeValue}
              onChange={(e) => setTypeValue(e.value)}
              options={typest}
              optionLabel='name'
            />
            <Dropdown
              style={{width: '200px', marginRight: ' 10px'}}
              value={tagValue}
              onChange={(e) => setTagValue(e.value)}
              options={tagOptions}
              optionLabel='name'
            />
          </div>
        </FilterWrapper>
        {mediasFiltered.length ? (
          <CardsWrapper className='p-grid'>
            {mediasFiltered.map(Card)}
            <EmptyCard />
          </CardsWrapper>
        ) : (
          <MediasNotFound />
        )}
      </div>
      <GeneralSidebar isActive={!!selectedMedia} onClick={onClick}>
        <MediaDetail media={selectedMedia} />
      </GeneralSidebar>
    </PageContainer>
  );
};

interface MediaDetailProps {
  media: MediaDto | null;
}

const MediaDetail: React.FC<MediaDetailProps> = ({media}) => {
  const history = useHistory();
  return (
    <div>
      <div>
        <PDHeader>
          {media?.type && <PDTitle>{capitalize(media?.type) + ' Info'}</PDTitle>}
          <PDButtonLink onClick={() => history.push('/media-bank/detail/edit/')}>
            <i className='pi pi-external-link' style={{marginRight: '10px'}}></i>Edit
          </PDButtonLink>
        </PDHeader>
        <PDID>{media?.id}</PDID>
      </div>
      <div>
        <PDField>
          <PDFieldLabel>Name</PDFieldLabel>
          <PDFieldValue>{media?.name}</PDFieldValue>
        </PDField>
        <PDField>
          <PDFieldLabel>Tags</PDFieldLabel>
          {media?.tags?.length ? (
            <PDFieldValue>
              {media?.tags?.map((tag) => (
                <Label id={tag} color={'#008C8C'}>
                  {tag}
                </Label>
              ))}
            </PDFieldValue>
          ) : (
            <PDFieldValue>None</PDFieldValue>
          )}
        </PDField>
        <PDField>
          <PDFieldLabel>Used for</PDFieldLabel>

          {media?.usedFor?.length ? (
            media?.usedFor?.map((tag) => <PDFieldValue>{tag}</PDFieldValue>)
          ) : (
            <PDFieldValue>None</PDFieldValue>
          )}
        </PDField>
        {media?.type === 'audio' && (
          <PDField>
            <PDFieldLabel>Audio File</PDFieldLabel>
            <audio controls key={media.url}>
              <source src={media.url} type='audio/mpeg' />
              Your browser does not support the audio tag.
            </audio>
          </PDField>
        )}
        {media?.type === 'video' && (
          <>
            <PDField>
              <PDFieldLabel>Video formats</PDFieldLabel>
              <video width='300' controls key={media.url}>
                <source src={media.url} type='video/mp4' />
                Your browser does not support the video tag.
              </video>
            </PDField>
            <PDField>
              <video width='300' controls key={media.url}>
                <source src={media.portraitVideoUrl} type='video/mp4' />
                Your browser does not support the video tag.
              </video>
            </PDField>
          </>
        )}
        <PDField>
          <PDFieldLabel>Image formats</PDFieldLabel>
          <div style={{display: 'flex', flexDirection: 'column'}}>
            {media?.crops.find((crop) => crop.type === 'squared') && (
              <img
                style={{maxWidth: '40%', marginTop: '20px'}}
                src={media?.crops.find((crop) => crop.type === 'squared')?.url}
                alt={media?.name}
              ></img>
            )}

            {media?.crops.find((crop) => crop.type === 'full_width_regular') && (
              <img
                style={{width: '90%', marginTop: '20px'}}
                src={media?.crops.find((crop) => crop.type === 'full_width_regular')?.url}
                alt={media?.name}
              ></img>
            )}
            {media?.crops.find((crop) => crop.type === 'full_screen_landscape') && (
              <img
                style={{maxWidth: '100%', marginTop: '20px'}}
                src={media?.crops.find((crop) => crop.type === 'full_screen_landscape')?.url}
                alt={media?.name}
              ></img>
            )}

            {media?.crops.find((crop) => crop.type === 'full_screen_portrait') && (
              <img
                style={{maxWidth: '50%', marginTop: '20px', marginBottom: '10px'}}
                src={media?.crops.find((crop) => crop.type === 'full_screen_portrait')?.url}
                alt={media?.name}
              ></img>
            )}
            {media?.crops.find((crop) => crop.type === 'full_width_tall') && (
              <img
                style={{maxWidth: '60%', marginTop: '20px', marginBottom: '10px'}}
                src={media?.crops.find((crop) => crop.type === 'full_width_tall')?.url}
                alt={media?.name}
              ></img>
            )}
          </div>
        </PDField>
      </div>
    </div>
  );
};
