export const priorityValues = [
  {name: 'Low', value: 1},
  {name: 'Medium', value: 2},
  {name: 'High', value: 3},
];

export const repeatValues = [
  {name: 'Every day', value: 86400},
  {name: 'Every 2 days', value: 172800},
];
