import React, {useState} from 'react';
import {
  PageContainer as GlobalPageContaner,
  PageHeader,
  PageTitle,
  PageBody,
  AddProgramForm,
  PageFooter,
  CancelButton,
  SaveButton,
} from 'components/page';
import {ImageManager} from 'components/image-manager';
import styled from 'styled-components';
import {addTherapyProgram, updateImage} from 'api/chatowl.api';
import {Link, useHistory} from 'react-router-dom';
import {FormElement, StyledFormElement} from 'components/form-element';
import {DefaultProgramAssignment} from 'components/therapy-programs/default-program-assignment';
import MediaChooser from 'components/media-chooser';
import {useAppDispatch} from 'hooks';
import {sendMessage} from 'store/action-creators';

const PageContainer = styled(GlobalPageContaner)`
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  padding-left: 60px;
`;

export const AddProgram: React.FC = () => {
  const [programName, setProgramName] = useState('');
  const [programDescription, setProgramDescription] = useState('');
  const [willBeDefaultProgram, setWillBeDefaultProgram] = useState(false);
  const [programImageBlob, setProgramImageBlob] = useState<Blob>(new Blob());
  const [savingProgram, setSavingProgram] = useState(false);
  const [weeksValue, setweeksValue] = useState(1);
  const history = useHistory();
  const dispatch = useAppDispatch();
  const [mediaId, setMediaId] = useState<number>();
  const [newCrops, setNewCrops] = useState<Crop[]>([]);

  const onProgramNameChange = (programName: string) => {
    setProgramName(programName);
  };

  const onProgramDescriptionChange = (programDescription: string) => {
    setProgramDescription(programDescription);
  };

  const onCancelProgram = () => {
    setProgramName('');
    setProgramDescription('');
    setWillBeDefaultProgram(false);
    setSavingProgram(false);
  };

  const onSaveProgram = async () => {
    let errorEditingMedia = false;
    setSavingProgram(true);
    if (newCrops.length > 0) {
      const fullWidthTall = newCrops!.find((crop) => crop.type === 'full_width_tall');
      const fullWidthRegular = newCrops!.find((crop) => crop.type === 'full_width_regular');
      const crops: UpdateMediaImageRequest = {
        fullWidthTall: fullWidthTall ? fullWidthTall.blob : undefined,
        fullWidthRegular: fullWidthRegular ? fullWidthRegular.blob : undefined,
      };
      const mediaImage = (await updateImage(mediaId!, crops)) as any;
      if (!(mediaImage.response.status >= 200 && mediaImage.response.status < 300)) {
        dispatch(sendMessage({severity: 'error', summary: 'ERROR', detail: 'Something went wrong while editing Media.'}));
        setSavingProgram(false);
        errorEditingMedia = true;
      }
    }
    if (!errorEditingMedia) {
      try {
        const newProgram: CreateProgramRequest = {
          name: programName,
          description: programDescription,
          language: 'en-US',
          status: 'draft',
          isDefault: willBeDefaultProgram,
          weeks: weeksValue,
          mediaId: mediaId!,
        };

        await addTherapyProgram(newProgram);
        dispatch(sendMessage({severity: 'success', summary: 'SUCCESS', detail: 'Program successfully created'}));
        history.push('/therapy-programs');
      } catch (error) {
        dispatch(sendMessage({severity: 'error', summary: 'ERROR', detail: 'Something went wrong'}));
        console.error(error);
      } finally {
        setSavingProgram(false);
      }
    }
  };

  return (
    <PageContainer>
      <PageHeader>
        <PageTitle>Add Therapy Program</PageTitle>
      </PageHeader>
      <PageBody>
        <AddProgramForm>
          <FormElement
            type='input'
            label='Name'
            value={programName}
            placeholder='Therapy program name'
            onChange={onProgramNameChange}
          />
          <FormElement
            type='text-area'
            label='Description'
            value={programDescription}
            placeholder='Therapy program description'
            onChange={onProgramDescriptionChange}
          />
          <DefaultProgramAssignment
            isCurrentDefaultProgram={false}
            willBeDefaultProgram={willBeDefaultProgram}
            setWillBeDefaultProgram={setWillBeDefaultProgram}
          />
        </AddProgramForm>
        <StyledFormElement>
          {/* <label>Image</label> */}
          <MediaChooser
            setMediaId={setMediaId}
            mediaType={'image'}
            imagesTypes={['full_width_regular', 'full_width_tall']}
            setNewCrops={setNewCrops}
          />
        </StyledFormElement>
      </PageBody>
      <PageFooter>
        <CancelButton onClick={onCancelProgram}>
          <Link to='/therapy-programs/'>Cancel</Link>
        </CancelButton>
        <SaveButton onClick={onSaveProgram}>Save</SaveButton>
      </PageFooter>
    </PageContainer>
  );
};
