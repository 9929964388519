import {AddProgramForm, CancelButton, PageBody, PageFooter, SaveButton, ArchiveButton as DeleteButton} from 'components/page';
import {FormElement, StyledFormElement} from 'components/form-element';
import {MediaDetailsFormType} from '../index';
import React, {useEffect, useState} from 'react';
import VideoUploader from 'components/media/video-uploader';
import VideoPlayer from 'components/media/video-player';
import {Link, useHistory} from 'react-router-dom';
import {createVideo, updateVideo, deleteMedia} from 'api/chatowl.api';
import {ImageCropperManager} from '../../crop-manager';
import {StyleAddVideoFiles, StyleAddVideoFilesCol, Header, ButtonLink} from './styles';
import {AddInput, InputButton, Tag, InputComplete, CursorLoading} from '../styles';

import {useTags} from 'hooks';
import {useAppSelector, useAppDispatch} from '../../../../hooks';
import {sendMessage} from '../../../../store/action-creators';

import {RootState} from '../../../../index';
import {shallowEqual} from 'react-redux';
import {Button} from 'primereact/components/button/Button';

function validateSave(
  name: string,
  videoBlob: Blob | undefined,
  videoUrl: string | undefined,
  crops: number,
  imagesCrops: number
): boolean {
  return !!name && (!!videoBlob || !!videoUrl) && (!!crops || !!imagesCrops);
}

export const VideoDetails: React.FC<{formType: MediaDetailsFormType}> = ({formType}) => {
  const history = useHistory();
  const [name, setName] = useState('');
  const tags = useTags();
  const [currentTag, setCurrentTag] = useState<Tag>();
  const [originalVideo, setOriginalVideo] = useState<Blob>();
  const [originalVideoURL, setOriginalVideoURL] = useState<string>('');
  const [portraitVideo, setPortraitVideo] = useState<Blob>();
  const [portraitVideoURL, setPortraitVideoURL] = useState<string | undefined>('');
  const [originalImage, setOriginalImage] = useState<Blob>();
  const [crops, setCrops] = useState<Crop[]>([]);
  const [imagesCrop, setImagesCrop] = useState<CropDto[]>([]);
  // const [editedImage, setEditedImage] = useState<boolean>(false);
  const [saving, setSaving] = useState<boolean>(false);
  const [userClickedDelete, setUserClickedDelete] = useState<boolean>(false);

  const dispatch = useAppDispatch();
  const selectedMedia: MediaDto | null = useAppSelector((state: RootState) => state.mediaBank.selectedMedia, shallowEqual);

  useEffect(() => {
    if (formType === 'edit') {
      if (selectedMedia) {
        setName(selectedMedia.name);
        tags.initTag(selectedMedia.tags!);
        setOriginalVideoURL(selectedMedia.url);
        setPortraitVideoURL(selectedMedia.portraitVideoUrl);
        setImagesCrop(selectedMedia.crops);
      }
    }
  }, []);

  useEffect(() => setCurrentTag(''), [tags.list]);

  const addCrop = (newCrop: Crop) => {
    const newCrops = crops.some((crop) => crop.type === newCrop.type) // if exist crop
      ? crops.map((crop) => (crop.type === newCrop.type ? newCrop : crop)) // replace crop
      : [...crops, newCrop]; // else push crop
    setCrops(newCrops);
    console.log({newCrops});
  };

  const sendVideo = async () => {
    if (validateSave(name, originalVideo, originalVideoURL, crops.length, imagesCrop.length)) {
      setSaving(true);
      if (formType === 'add') {
        const newVideo: CreateMediaVideoRequest = {
          name,
          tags: tags.list,
          originalVideo: originalVideo!,
          portraitVideo: portraitVideo,
          originalImage: originalImage!,
          fullWidthRegular: crops!.find((crop) => crop.type === 'full_width_regular')!.blob,
          fullWidthTall: crops!.find((crop) => crop.type === 'full_width_tall')!.blob,
          crops: [],
        };
        try {
          const mediaVideo = await createVideo(newVideo);
          dispatch(sendMessage({severity: 'success', summary: 'SUCCESS', detail: 'Video successfully created'}));
          history.push('/media-bank/');
        } catch (e) {
          dispatch(sendMessage({severity: 'error', summary: 'ERROR', detail: 'Something went wrong'}));
        }
      }

      if (formType === 'edit') {
        let newVideo: UpdateMediaVideoRequest;
        if (crops.length) {
          newVideo = {
            name,
            tags: tags.list,
            crops: [],
            originalVideo: originalVideo,
            portraitVideo: portraitVideo,
            originalImage: originalImage,
            fullWidthRegular: crops!.find((crop) => crop.type === 'full_width_regular')!.blob,
            fullWidthTall: crops!.find((crop) => crop.type === 'full_width_tall')!.blob,
          };
        } else {
          debugger;
          newVideo = {
            name,
            tags: tags.list,
            crops: [],
            originalVideo: originalVideo,
            portraitVideo: portraitVideo,
            originalImage: originalImage,
          };
        }
        try {
          const mediaVideo = await updateVideo(selectedMedia!.id, newVideo);
          dispatch(sendMessage({severity: 'success', summary: 'SUCCESS', detail: 'Video successfully edited'}));
          history.push('/media-bank/');
        } catch (e) {
          dispatch(sendMessage({severity: 'error', summary: 'ERROR', detail: 'Something went wrong'}));
        }
      }
    } else {
      dispatch(sendMessage({severity: 'warn', summary: 'WARNING', detail: 'Name, Video and Image can not be empty.'}));
      //toast warning to user
    }
  };
  const onCancelVideoDetails = () => history.push('/media-bank');

  const onDeleteVideo = async () => {
    if (!userClickedDelete) setUserClickedDelete(true);
    else {
      //hacer la llamada correspondiente
      try {
        await deleteMedia(selectedMedia!.id);
        dispatch(sendMessage({severity: 'success', summary: 'SUCCESS', detail: 'Video successfully deleted'}));
        history.push('/media-bank');
      } catch (e) {
        dispatch(sendMessage({severity: 'error', summary: 'ERROR', detail: 'Something went wrong'}));
      }
    }
  };

  const handleOnClickNewVideo = () => {
    setOriginalVideo(undefined);
    setPortraitVideo(undefined);
    setOriginalVideoURL('');
    setPortraitVideoURL('');
    // setEditedImage(true);
  };

  return (
    <CursorLoading disable={saving}>
      <PageBody>
        <AddProgramForm>
          <FormElement type='input' label='Name' value={name} placeholder='The name of the video' onChange={setName} />
          <StyledFormElement>
            {/* <AutoComplete /> */}
            <label htmlFor={`input Tags`}>{'Tags'}</label>
            <InputButton>
              <InputComplete value={currentTag} onChange={(e) => setCurrentTag(e.target.value.toLowerCase())} />
              <AddInput
                onClick={() => {
                  debugger;
                  currentTag && tags.push(currentTag);
                }}
              >
                Add
              </AddInput>
            </InputButton>
            <div style={{display: 'flex', flexDirection: 'row'}}>
              {tags.list?.map((tag) => (
                <Tag
                  id={`${tag}_video`}
                  key={`${tag}_video`}
                  color={'#008C8C'}
                  onClick={() => {
                    debugger;
                    tags.drop(tag);
                  }}
                >
                  {tag} x
                </Tag>
              ))}
            </div>
          </StyledFormElement>
        </AddProgramForm>
        <StyleAddVideoFilesCol>
          <Header>
            <label>Video</label>
            {originalVideoURL && <ButtonLink onClick={handleOnClickNewVideo}>Upload New Video</ButtonLink>}
          </Header>
          <StyleAddVideoFiles>
            {/* <label>Video</label> */}
            <div style={{marginRight: '20px'}}>
              {!originalVideoURL ? (
                <VideoUploader
                  onUploadVideo={(video: Blob) => {
                    setOriginalVideo(video);
                    setOriginalVideoURL(URL.createObjectURL(video));
                  }}
                >
                  <p style={{color: 'red'}}>*Required</p>
                  <p>Upload a video file (.mp4)</p>
                  <Button className='p-button-outlined p-mr-2 p-mb-2'> Upload File </Button>
                </VideoUploader>
              ) : (
                <VideoPlayer videoSrc={originalVideoURL} />
              )}
            </div>
            <div>
              {!portraitVideoURL ? (
                <VideoUploader
                  onUploadVideo={(video: Blob) => {
                    setPortraitVideo(video);
                    setPortraitVideoURL(URL.createObjectURL(video));
                  }}
                  isPortrait
                >
                  <p>Upload vertical view</p>
                  <Button className='p-button-outlined p-mr-2 p-mb-2'> Upload </Button>
                </VideoUploader>
              ) : (
                <VideoPlayer videoSrc={portraitVideoURL} />
              )}
            </div>
          </StyleAddVideoFiles>

          <StyledFormElement>
            {formType === 'edit' ? (
              <ImageCropperManager
                mediaType='video'
                setOriginalImage={setOriginalImage}
                addCrop={addCrop}
                imagesCrop={imagesCrop}
                image={selectedMedia?.thumbnailUrl}
              />
            ) : (
              <ImageCropperManager mediaType='video' setOriginalImage={setOriginalImage} addCrop={addCrop} />
            )}
          </StyledFormElement>
        </StyleAddVideoFilesCol>
      </PageBody>
      <PageFooter>
        {formType === 'edit' && (
          <DeleteButton onClick={onDeleteVideo} userClicked={userClickedDelete}>
            {userClickedDelete ? 'Delete ?' : 'Delete'}
          </DeleteButton>
        )}
        <CancelButton onClick={onCancelVideoDetails}>
          <Link to='/media-bank/'>Cancel</Link>
        </CancelButton>
        <SaveButton disabled={saving} onClick={sendVideo} disable={saving}>
          {saving ? 'Saving...' : 'Save'}
        </SaveButton>
      </PageFooter>
    </CursorLoading>
  );
};
