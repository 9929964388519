import styled from 'styled-components';

export const Image = styled.div`
  width: 280px;
  height: 160px;
  background-color: #e8e8e8;
  border-radius: 9px;
  display: flex;
  justify-content: center;
  cursor: pointer;

  :hover {
    opacity: 0.8;
  }
`;

export const CardThumbnail = styled.img`
  object-fit: cover;
  width: 100%;
  border-radius: 8px;
`;

export const CardThumbnailVideo = styled.video`
  width: 100%;
  border-radius: 8px;
`;

export const ActionButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10px;
`;

export const FilterContainer = styled.div`
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
`;

export const SelectImageBox = styled.div`
  align-items: center;
  border: 2px dashed #bcc1c7;
  box-sizing: border-box;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  height: 550px;
  justify-content: center;
  width: 700px;

  p {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    /* identical to box height, or 150% */

    color: #777c7c;
  }

  :hover:not(:focus-within) {
    background-color: #fafafa;
    cursor: pointer;
    transition: 0.1s;
  }
`;

export const CloseModal = styled.div`
  position: absolute;
  top: 10px;
  right: 20px;
  color: #9ba2aa;
  font-size: 28px;
  cursor: pointer;
`;
