import React, {useState, useEffect, useRef} from 'react';
import {
  PageContainer as GlobalPageContaner,
  PageHeader,
  PageTitle,
  PageBody,
  AddProgramForm,
  PageFooter,
  CancelButton,
  SaveButton,
  Label,
  DefaultProgramButtonHelpText,
} from 'components/page';
import {ImageManager} from 'components/image-manager';
import styled from 'styled-components';
import {getProgramDetail, patchTherapyProgram, updateImage} from 'api/chatowl.api';
import {Link, useHistory} from 'react-router-dom';
import {useParams} from 'react-router-dom';
import {Dropdown} from 'primereact/dropdown';
import {SelectButton} from 'primereact/selectbutton';
import {FormElement, StyledFormElement} from 'components/form-element';
import {Toast} from 'primereact/toast';
import {DefaultProgramAssignment} from 'components/therapy-programs/default-program-assignment';
import MediaChooser from 'components/media-chooser';
import {useAppDispatch} from 'hooks';
import {sendMessage} from 'store/action-creators';

const PageContainer = styled(GlobalPageContaner)`
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  padding-left: 60px;
`;
interface RouteParam {
  id: string;
}

export const EditProgram: React.FC = () => {
  const [programId, setProgramId] = useState<number>();
  const [programName, setProgramName] = useState('');
  const [programDescription, setProgramDescription] = useState('');
  const [isCurrentDefaultProgram, setIsCurrentDefaultProgram] = useState(false); // state of the default program setting after edition
  const [willBeDefaultProgram, setWillBeDefaultProgram] = useState(false); // state of the default program setting while editing
  const [programImageBlob, setProgramImageBlob] = useState<Blob>(new Blob());
  const [savingProgram, setSavingProgram] = useState(false);
  const [weeksValue, setweeksValue] = useState(1);
  const [status, setStatus] = useState<ProgramStatus>();
  const [programImageUrl, setProgramImageUrl] = useState('');
  const [initialStatus, setInitialStatus] = useState('');
  const [newCrops, setNewCrops] = useState<Crop[]>([]);

  const toast: React.MutableRefObject<any> = useRef(null);
  const [mediaId, setMediaId] = useState<number>();
  const [media, setMedia] = useState<MediaDto>();

  const history = useHistory();

  const params = useParams<RouteParam>();

  const dispatch = useAppDispatch();

  useEffect(() => {
    const fetchProgramDetail = async () => {
      try {
        const response = (await getProgramDetail(params.id)) as any;
        const therapyProgram = response.data.data;
        setProgramId(+therapyProgram.id);
        setProgramName(therapyProgram.name);
        setProgramDescription(therapyProgram.description);
        setIsCurrentDefaultProgram(therapyProgram.isDefault);
        setWillBeDefaultProgram(therapyProgram.isDefault);
        setStatus(therapyProgram.status);
        setProgramImageUrl(therapyProgram.imageUrl);
        setInitialStatus(therapyProgram.status);
        setMedia(therapyProgram.media);
        setMediaId(therapyProgram.mediaId);
      } catch (error) {
        throw error; // TODO
      }
    };
    fetchProgramDetail();
  }, []);

  const onProgramNameChange = (programName: string) => {
    setProgramName(programName);
  };

  const onProgramDescriptionChange = (programDescription: string) => {
    setProgramDescription(programDescription);
  };

  const onCancelProgram = () => {
    setProgramName('');
    setProgramDescription('');
    setIsCurrentDefaultProgram(false);
    setSavingProgram(false);
  };

  const showError = (title: string, message: string) => {
    toast.current.show({
      severity: 'error',
      summary: title,
      detail: message,
      life: 3000,
    });
  };

  const onSaveProgram = async () => {
    let errorEditingMedia = false;
    setSavingProgram(true);
    if (newCrops.length > 0) {
      const fullWidthTall = newCrops!.find((crop) => crop.type === 'full_width_tall');
      const fullWidthRegular = newCrops!.find((crop) => crop.type === 'full_width_regular');
      const crops: UpdateMediaImageRequest = {
        fullWidthTall: fullWidthTall ? fullWidthTall.blob : undefined,
        fullWidthRegular: fullWidthRegular ? fullWidthRegular.blob : undefined,
      };
      const mediaImage = (await updateImage(mediaId!, crops)) as any;
      if (!(mediaImage.response.status >= 200 && mediaImage.response.status < 300)) {
        dispatch(sendMessage({severity: 'error', summary: 'ERROR', detail: 'Something went wrong while editing Media.'}));
        setSavingProgram(false);
        errorEditingMedia = true;
      }
    }
    if (!errorEditingMedia) {
      try {
        const newProgram: UpdateProgramRequest = {
          name: programName,
          description: programDescription,
          language: 'en-US',
          status: status,
          isDefault: willBeDefaultProgram,
          weeks: weeksValue,
          mediaId: mediaId!,
        };

        programId && (await patchTherapyProgram(programId, newProgram));
        dispatch(sendMessage({severity: 'success', summary: 'SUCCESS', detail: 'Program successfully updated'}));
        history.push('/therapy-programs');
      } catch (error) {
        dispatch(sendMessage({severity: 'error', summary: 'ERROR', detail: 'Something went wrong'}));
        console.error(error);
      } finally {
        setSavingProgram(false);
      }
    }
  };

  // Get from back or set default count
  const dropdownValues = [
    {
      name: '1 week',
      value: 1,
    },
    {
      name: '2 weeks',
      value: 2,
    },
    {
      name: '3 weeks',
      value: 3,
    },
  ];

  const selectButtonValues1 = [
    {name: 'Draft', value: 'draft', disabled: initialStatus === 'published'},
    {name: 'Published', value: 'published'},
    {name: 'Archived', value: 'archived'},
  ];

  const onChangeStatus = (e: any) => {
    if (!!e.value) setStatus(e.value);
  };

  return (
    <PageContainer>
      <PageHeader>
        <PageTitle>Edit Therapy Programs</PageTitle>
      </PageHeader>
      <PageBody>
        <AddProgramForm>
          <div style={{marginBottom: '10px', width: '100%'}}>
            <SelectButton
              style={{width: 'fit-content'}}
              value={status}
              onChange={onChangeStatus}
              options={selectButtonValues1}
              optionLabel='name'
              tooltip={initialStatus === 'published' ? 'This program is already published and cannot be draft.' : null}
              tooltipOptions={{position: 'left'}}
            />
          </div>
          <FormElement
            type='input'
            label='Name'
            value={programName}
            placeholder='Therapy program name'
            onChange={onProgramNameChange}
          />
          <FormElement
            type='text-area'
            label='Description'
            value={programDescription}
            placeholder='Therapy program description'
            onChange={onProgramDescriptionChange}
          />
          <DefaultProgramAssignment
            isCurrentDefaultProgram={isCurrentDefaultProgram}
            willBeDefaultProgram={willBeDefaultProgram}
            setWillBeDefaultProgram={setWillBeDefaultProgram}
          />
          <Label>Therapy Program length in weeks</Label>
          <Dropdown value={weeksValue} onChange={(e) => setweeksValue(e.value)} options={dropdownValues} optionLabel='name' />
          <DefaultProgramButtonHelpText>The length can be changed later if necessary.</DefaultProgramButtonHelpText>
        </AddProgramForm>
        <StyledFormElement>
          <MediaChooser
            setMediaId={setMediaId}
            media={media}
            mediaType={'image'}
            imagesTypes={['full_width_regular', 'full_width_tall']}
            setNewCrops={setNewCrops}
          />
        </StyledFormElement>
      </PageBody>
      <PageFooter>
        <CancelButton onClick={onCancelProgram}>
          <Link to='/therapy-programs/'>Cancel</Link>
        </CancelButton>
        <SaveButton onClick={onSaveProgram}>Save</SaveButton>
      </PageFooter>
      <Toast ref={toast} />
    </PageContainer>
  );
};
