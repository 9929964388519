import {SpaceCheckboxes} from './style';
import {Checkbox} from 'primereact/checkbox';

type CheckboxEvent = {
  originalEvent: Event;
  value: any;
  checked: boolean;
  target: {
    type: string;
    name: string;
    id: string;
    value: any;
    checked: boolean;
  };
};

interface GeneralCheckboxTypes {
  formValues: InstructionType[];
  value: InstructionType;
  label: string;
  onChange: (event: CheckboxEvent) => void;
}

export const GeneralCheckbox: React.FC<GeneralCheckboxTypes> = ({formValues, value, label, onChange}) => {
  return (
    <SpaceCheckboxes>
      <Checkbox
        inputId={value}
        value={value}
        onChange={(e: CheckboxEvent) => onChange(e)}
        checked={formValues.includes(value)}
      ></Checkbox>
      <label htmlFor={value} className='p-checkbox-label'>
        {label}
      </label>
    </SpaceCheckboxes>
  );
};
export default GeneralCheckbox;
