import {
  DetailFieldValue as EDFieldValue,
  DetailButtonLink as EDButtonLink,
  DetailField as EDField,
  DetailFieldImage as EDFieldImage,
  DetailFieldLabel as EDFieldLabel,
  DetailFieldRow as EDFieldRow,
  DetailHeader as EDHeader,
  DetailID as EDID,
  DetailTitle as EDTitle,
  Label,
} from '../../../components/tools/main-page-components';
import {useHistory} from 'react-router-dom';

type ExerciseDetailsProps = {
  exercise: ExerciseDto | null;
};

export type DropdownType = {
  label: string;
  value: string | number;
};

export const mediaTypesDropdown: DropdownType[] = [
  {label: 'Audio File', value: 'audio'},
  {label: 'Video File', value: 'video'},
];

export const repeatLimitDropdown: DropdownType[] = [
  {label: '0', value: 0},
  {label: '6 hours', value: 21600},
  {label: '24 hours', value: 86400},
  {label: '2 days', value: 172800},
];

export const resumeLimitDropdown: DropdownType[] = [
  {label: '0', value: 0},
  {label: '10 minutes', value: 600},
  {label: '30 minutes', value: 1800},
  {label: '1 hour', value: 3600},
  {label: '2 hours', value: 7200},
  {label: '3 hours', value: 10800},
  {label: '4 hours', value: 14400},
];

export const InstructionsLabelValue: DropdownType[] = [
  {label: 'Lie down', value: 'lie-down'},
  {label: 'Headphones', value: 'headphones'},
  {label: 'Quiet place', value: 'quiet-place'},
];

const translateMediaType = (media: MediaTypes | undefined): string => {
  if (media) {
    const mediaSelected: DropdownType | undefined = mediaTypesDropdown.find((mediaIterator) => mediaIterator.value === media);
    return mediaSelected ? mediaSelected.label : media;
  } else return 'No media';
};

const translateSecondsResume = (seconds: number | undefined): string => {
  if (seconds) {
    const resumeLimitTime: DropdownType | undefined = resumeLimitDropdown.find((time) => time.value === seconds);
    return resumeLimitTime ? resumeLimitTime.label : seconds.toString();
  } else return '0';
};

const translateSecondsRepeat = (seconds: number | undefined): string => {
  if (seconds) {
    const repeatLimitTime: DropdownType | undefined = repeatLimitDropdown.find((time) => time.value === seconds);
    return repeatLimitTime ? repeatLimitTime.label : seconds.toString();
  } else return '0';
};

export const ExerciseDetails: React.FC<ExerciseDetailsProps> = ({exercise}) => {
  const history = useHistory();
  let instructionsString: string | undefined = '';
  if (exercise?.instructions) {
    const instructionsLength: number = exercise?.instructions.length;
    exercise?.instructions.map((instructionValue, iterator) => {
      const LabelValueAux = InstructionsLabelValue.find((labelValue) => instructionValue === labelValue.value);
      instructionsString += LabelValueAux ? LabelValueAux.label : 'None';
      instructionsString += instructionsLength > 1 && iterator + 1 < instructionsLength ? ', ' : ''; //When there's more than 1 instruction
      return instructionsString;
    });
  }

  return (
    <div>
      <div>
        <EDHeader>
          <div style={{display: 'flex'}}>
            <EDTitle>Exercise Info</EDTitle>
            {exercise?.isFree && <Label color={'#20C5A0'}>Free</Label>}
          </div>
          <div>
            <EDButtonLink onClick={() => history.push('/tools/exercises/edit/')}>
              <i className='pi pi-external-link' style={{marginRight: '10px'}}></i>Edit
            </EDButtonLink>
          </div>
        </EDHeader>
        <EDID>{exercise?.id}</EDID>
      </div>
      <div>
        <EDField>
          <EDFieldLabel>Name</EDFieldLabel>
          <EDFieldValue>{exercise?.title}</EDFieldValue>
        </EDField>
        <EDFieldRow>
          <EDField>
            <EDFieldLabel>Category</EDFieldLabel>
            <EDFieldValue>{exercise?.toolCategory.name}</EDFieldValue>
          </EDField>
          <EDField>
            <EDFieldLabel>Type</EDFieldLabel>
            <EDFieldValue>{translateMediaType(exercise?.mediaType)}</EDFieldValue>
          </EDField>
        </EDFieldRow>
        <EDField>
          <EDFieldLabel>Description</EDFieldLabel>
          <EDFieldValue>{exercise?.description}</EDFieldValue>
        </EDField>
        <EDFieldRow>
          <EDField>
            <EDFieldLabel>Repeat Limit</EDFieldLabel>
            <EDFieldValue>{translateSecondsRepeat(exercise?.repeatLimit)}</EDFieldValue>
          </EDField>

          <EDField>
            <EDFieldLabel>Resume Limit</EDFieldLabel>
            <EDFieldValue>{translateSecondsResume(exercise?.resumeLimit)}</EDFieldValue>
          </EDField>
        </EDFieldRow>
        <EDField>
          <EDFieldLabel>Instructions</EDFieldLabel>

          <EDFieldValue>{instructionsString}</EDFieldValue>
        </EDField>

        <EDField>
          <EDFieldLabel>Image</EDFieldLabel>
          <EDFieldImage src={exercise?.imageLandscapeUrl} alt={exercise?.title} />
        </EDField>
        {exercise?.mediaType === 'audio' && (
          <EDField>
            <EDFieldLabel>Audio File</EDFieldLabel>
            <audio controls key={exercise.mediaLandscapeUrl}>
              <source src={exercise.mediaLandscapeUrl} type='audio/mpeg' />
              Your browser does not support the audio tag.
            </audio>
          </EDField>
        )}

        {
          exercise?.mediaType === 'video' && (
            <>
              <EDField>
                <EDFieldLabel>Video formats</EDFieldLabel>
                <video width='300' controls key={exercise.mediaLandscapeUrl}>
                  <source src={exercise.mediaLandscapeUrl} type='video/mp4' />
                  Your browser does not support the video tag.
                </video>
              </EDField>
              <EDField>
                <video width='300' controls key={exercise.mediaPortraitUrl}>
                  <source src={exercise.mediaPortraitUrl} type='video/mp4' />
                  Your browser does not support the video tag.
                </video>
              </EDField>
            </>
          )
          //ESOS LINKS DE AUDIO Y VIDEO CAMBIARAN CUANDO VENGA EL OBJETO "media"
        }
      </div>
    </div>
  );
};
export default ExerciseDetails;
