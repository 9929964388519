import {
  GeneralCardContainer,
  GeneralCardHeader,
  GeneralCardInfo,
  GeneralDescription,
  GeneralImage,
  GeneralTitle,
  GeneralCategory,
} from './styles';

interface GeneralCardProps {
  selectedToolID: number | undefined; //selected Tool ID, state variable in the component.
  toolID: number;
  toolTitle: string;
  toolDescription: string;
  toolCategory: string;
  onClick: (toolID: number) => void; //If already selected unselect, else, select.
  toolImage: string | undefined;
}

export const GeneralCard: React.FC<GeneralCardProps> = ({
  selectedToolID, //selected Tool ID, state variable in the component.
  toolID,
  toolTitle,
  toolDescription,
  toolCategory,
  onClick, //If already selected unselect, else, select.
  toolImage,
}) => {
  const isSelected = selectedToolID === toolID;
  return (
    <GeneralCardContainer key={toolID} onClick={() => onClick(toolID)} isSelected={isSelected}>
      <GeneralCardInfo>
        <GeneralCardHeader>
          <GeneralTitle title={toolTitle}>{toolTitle}</GeneralTitle>
        </GeneralCardHeader>
        <GeneralDescription title={toolDescription}>{toolDescription}</GeneralDescription>
        <GeneralCategory>{toolCategory}</GeneralCategory>
      </GeneralCardInfo>
      <GeneralImage isSidebarOpen={!!selectedToolID} src={toolImage} alt={toolTitle} />
    </GeneralCardContainer>
  );
};
export default GeneralCard;
