// Here goes the chatowl api endpoints
import querystring from 'querystring';
import {CHATOWL_API_HOST} from 'utils/constants';

// Here goes the chat api endpoints
interface ApiCallConfig extends RequestInit {
  params?: Record<string, string | number | undefined | boolean>;
  token?: string;
}

type ApiCallResponse<T> = {response: Response; data: T} | {response: Response; error: Error};

const callApi = async <T>(url: string, config?: ApiCallConfig): Promise<ApiCallResponse<T>> => {
  const queryString = config?.params ? '?' + querystring.stringify(config.params) : '';

  const response = await fetch(CHATOWL_API_HOST + url + queryString, {
    headers: {
      ...(config?.token ? {Authorization: `Bearer ${config.token}`} : null),
      ...(config?.body ? {'Content-Type': `application/json`} : null),
      ...(config?.headers || null),
    },
    body: config?.body ? JSON.stringify(config.body) : undefined,
    ...config,
  });

  try {
    return {response, data: await response.json()};
  } catch (error) {
    return {response, error};
  }
};

export interface ProgramFilters {
  search?: string;
  statusIs?: ProgramStatus;
  sortBy?: ProgramSort;
  sortDir?: 'asc' | 'desc';
  withDeleted?: boolean;
}

export const getTherapyPrograms = async (filters?: ProgramFilters, requestConfig?: ApiCallConfig) =>
  callApi<ProgramDto[]>('/programs', {
    params: {...filters, ...(requestConfig?.params ? requestConfig.params : null)},
    token: 'test',
    ...(requestConfig || null),
  });

export interface ListExerciseFilters {
  search?: string;
  categoryIs?: number;
  isArchived?: boolean;
  withDeleted?: boolean;
  sortBy?: 'id' | 'title';
  sortDir?: 'asc' | 'desc';
}

export const listExercises = async (filters?: ListExerciseFilters, requestConfig?: ApiCallConfig) =>
  callApi<ExerciseDto[]>('/toolbox/exercises', {
    params: {...filters, ...(requestConfig?.params ? requestConfig.params : null)},
    token: 'test',
    ...(requestConfig || null),
  });

export interface ActivityFilters {
  search?: string;
  week?: number;
  tools?: string;
  withDeleted?: boolean;
}

export const getProgramActivities = async (id: string, filters?: ActivityFilters, requestConfig?: ApiCallConfig) =>
  callApi<ProgramDto>(`/programs/${id}/activities`, {
    params: {...filters, ...(requestConfig?.params ? requestConfig.params : null)},
    token: 'test',
    ...(requestConfig || null),
  });

export const addTherapyProgram = async (program: CreateProgramRequest, requestConfig?: ApiCallConfig) => {
  const body = JSON.stringify(program);
  return callApi<ProgramDto>('/programs', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    params: {...(requestConfig?.params ? requestConfig.params : null)},
    body,
    token: 'test',
    ...(requestConfig || null),
  });
};

export const patchTherapyProgram = async (id: number, program: UpdateProgramRequest, requestConfig?: ApiCallConfig) => {
  const body = JSON.stringify(program);

  return callApi<ProgramDto>(`/programs/${id}`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    params: {...(requestConfig?.params ? requestConfig.params : null)},
    body,
    token: 'test',
    ...(requestConfig || null),
  });
};

export interface ActivityFilters {
  search?: string;
  week?: number;
  tools?: string;
  withDeleted?: boolean;
}

export const getProgramDetail = async (id: string, requestConfig?: ApiCallConfig) =>
  callApi<ProgramDto>(`/programs/${id}`, {
    params: {...(requestConfig?.params ? requestConfig.params : null)},
    token: 'test',
    ...(requestConfig || null),
  });

export const getActivityTypesTools = async (requestConfig?: ApiCallConfig) =>
  callApi<ComboOptionDto[]>('/labels/activity-types-tools', {
    params: {...(requestConfig?.params ? requestConfig.params : null)},
    ...(requestConfig || null),
  });

export const addActivityToProgram = async (id: number, activityData: ActivityDto, requestConfig?: ApiCallConfig) => {
  const body = JSON.stringify(activityData);
  return callApi<ProgramDto>(`/programs/${id}/activities`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    params: {...(requestConfig?.params ? requestConfig.params : null)},
    body,
    token: 'test',
    ...(requestConfig || null),
  });
};

export const patchActivityProgram = async (
  idProgram: number,
  idActivity: number,
  activityData: ActivityDto,
  requestConfig?: ApiCallConfig
) => {
  const body = JSON.stringify(activityData);
  return callApi<ProgramDto>(`/programs/${idProgram}/activities/${idActivity}`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    params: {...(requestConfig?.params ? requestConfig.params : null)},
    body,
    token: 'test',
    ...(requestConfig || null),
  });
};

export const getCategories = async (requestConfig?: ApiCallConfig) =>
  callApi<ToolCategoryType[]>('/toolbox/categories', {
    params: {...(requestConfig?.params ? requestConfig.params : null)},
    token: 'test',
    ...(requestConfig || null),
  }); //Me traigo cosas innecesarias. Ver si dejamos este o modificamos el back.

export const createVideo = async (video: CreateMediaVideoRequest) => {
  const postMediaBankVideo = async (formData: FormData, requestConfig?: ApiCallConfig) => {
    return callApi<MediaDto>('/media-bank/videos', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
      },
      params: {...(requestConfig?.params ? requestConfig.params : null)},
      body: formData,
      token: 'test',
      ...(requestConfig || null),
    });
  };
  const formData = new FormData();
  const {name, originalVideo, portraitVideo, originalImage, fullWidthRegular, fullWidthTall, tags, crops} = video;
  formData.append('name', name);
  formData.append('originalVideo', originalVideo);
  portraitVideo && formData.append('portraitVideo', portraitVideo);
  formData.append('originalImage', originalImage);
  formData.append('fullWidthRegular', fullWidthRegular);
  formData.append('fullWidthTall', fullWidthTall);
  tags && formData.append('tags', JSON.stringify(tags));

  formData.append('crops', JSON.stringify(crops));
  return postMediaBankVideo(formData);
};

export const updateVideo = async (mediaId: number, video: UpdateMediaVideoRequest, requestConfig?: ApiCallConfig) => {
  const updateMediaBankVideo = async (formData: FormData, requestConfig?: ApiCallConfig) => {
    return callApi<MediaDto>(`/media-bank/videos/${mediaId}`, {
      method: 'PATCH',
      headers: {
        Accept: 'application/json',
      },
      params: {...(requestConfig?.params ? requestConfig.params : null)},
      body: formData,
      token: 'test',
      ...(requestConfig || null),
    });
  };
  const formData = new FormData();
  const {name, originalVideo, portraitVideo, originalImage, fullWidthRegular, fullWidthTall, tags, crops} = video;
  name && formData.append('name', name);
  // if (originalVideo && originalVideo.size) {
  //   //User edited originalVideo.
  //   formData.append('originalVideo', originalVideo);
  //   portraitVideo ? formData.append('portraitVideo', portraitVideo) : formData.append('portraitVideo', JSON.stringify([]));
  //   //If User edited originalVideo and edited portraitVideo, send it.
  //   //If User edited originalVideo but left portraitVideo empty, backend has to delete portraitVideo.
  // } else {
  //   portraitVideo && formData.append('portraitVideo', portraitVideo); //User didn't change originalVideo but changed portraitVideo.
  // }
  originalVideo && originalVideo.size && formData.append('originalVideo', originalVideo);
  portraitVideo && formData.append('portraitVideo', portraitVideo);
  originalImage && formData.append('originalImage', originalImage);
  fullWidthRegular && formData.append('fullWidthRegular', fullWidthRegular);
  fullWidthTall && formData.append('fullWidthTall', fullWidthTall);
  tags && formData.append('tags', JSON.stringify(tags));
  formData.append('crops', JSON.stringify(crops));
  return updateMediaBankVideo(formData);
};

export const createAudio = async (audio: CreateMediaAudioRequest) => {
  const postMediaBankAudio = async (formData: FormData, requestConfig?: ApiCallConfig) => {
    return callApi<MediaDto>('/media-bank/audios', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
      },
      params: {...(requestConfig?.params ? requestConfig.params : null)},
      body: formData,
      token: 'test',
      ...(requestConfig || null),
    });
  };
  const formData = new FormData();
  const {
    name,
    originalAudio,
    fullScreenLandscape,
    fullScreenPortrait,
    originalImage,
    fullWidthRegular,
    fullWidthTall,
    tags,
    crops,
  } = audio;
  formData.append('name', name);
  formData.append('originalAudio', originalAudio);
  formData.append('originalImage', originalImage);
  formData.append('fullScreenLandscape', fullScreenLandscape);
  formData.append('fullScreenPortrait', fullScreenPortrait);
  formData.append('fullWidthRegular', fullWidthRegular);
  formData.append('fullWidthTall', fullWidthTall);
  tags && formData.append('tags', JSON.stringify(tags));
  formData.append('crops', JSON.stringify(crops));
  return postMediaBankAudio(formData);
};

export const updateAudio = async (mediaId: number, audio: UpdateMediaAudioRequest, requestConfig?: ApiCallConfig) => {
  const updateMediaBankAudio = async (formData: FormData, requestConfig?: ApiCallConfig) => {
    return callApi<MediaDto>(`/media-bank/audios/${mediaId}`, {
      method: 'PATCH',
      headers: {
        Accept: 'application/json',
      },
      params: {...(requestConfig?.params ? requestConfig.params : null)},
      body: formData,
      token: 'test',
      ...(requestConfig || null),
    });
  };
  const formData = new FormData();
  const {
    name,
    originalAudio,
    originalImage,
    fullWidthRegular,
    fullWidthTall,
    fullScreenLandscape,
    fullScreenPortrait,
    tags,
    crops,
  } = audio;
  name && formData.append('name', name);
  originalAudio && originalAudio.size && formData.append('originalAudio', originalAudio);
  originalImage && formData.append('originalImage', originalImage);
  fullScreenLandscape && formData.append('fullScreenLandscape', fullScreenLandscape);
  fullScreenPortrait && formData.append('fullScreenPortrait', fullScreenPortrait);
  fullWidthRegular && formData.append('fullWidthRegular', fullWidthRegular);
  fullWidthTall && formData.append('fullWidthTall', fullWidthTall);
  tags && formData.append('tags', JSON.stringify(tags));
  formData.append('crops', JSON.stringify(crops));
  return updateMediaBankAudio(formData);
};

export const createImage = async (image: CreateMediaImageRequest) => {
  const postMediaBankImage = async (formData: FormData, requestConfig?: ApiCallConfig) => {
    return callApi<MediaDto>('/media-bank/images', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
      },
      params: {...(requestConfig?.params ? requestConfig.params : null)},
      body: formData,
      token: 'test',
      ...(requestConfig || null),
    });
  };
  const formData = new FormData();
  const {
    name,
    originalImage,
    fullScreenLandscape,
    fullScreenPortrait,
    fullWidthRegular,
    fullWidthTall,
    squared,
    tags,
    crops,
  } = image;
  formData.append('name', name);
  formData.append('originalImage', originalImage);
  formData.append('fullScreenLandscape', fullScreenLandscape);
  formData.append('fullScreenPortrait', fullScreenPortrait);
  formData.append('fullWidthRegular', fullWidthRegular);
  formData.append('fullWidthTall', fullWidthTall);
  formData.append('squared', squared);
  tags && formData.append('tags', JSON.stringify(tags));
  formData.append('crops', JSON.stringify(crops));
  return postMediaBankImage(formData);
};

export const updateImage = async (mediaId: number, image: UpdateMediaImageRequest, requestConfig?: ApiCallConfig) => {
  const updateMediaBankImage = async (formData: FormData, requestConfig?: ApiCallConfig) => {
    return callApi<MediaDto>(`/media-bank/images/${mediaId}`, {
      method: 'PATCH',
      headers: {
        Accept: 'application/json',
      },
      params: {...(requestConfig?.params ? requestConfig.params : null)},
      body: formData,
      token: 'test',
      ...(requestConfig || null),
    });
  };
  const formData = new FormData();
  const {
    name,
    originalImage,
    fullWidthRegular,
    fullWidthTall,
    fullScreenLandscape,
    fullScreenPortrait,
    tags,
    crops,
    squared,
  } = image;
  name && formData.append('name', name);
  originalImage && formData.append('originalImage', originalImage);
  fullScreenLandscape && formData.append('fullScreenLandscape', fullScreenLandscape);
  fullScreenPortrait && formData.append('fullScreenPortrait', fullScreenPortrait);
  fullWidthRegular && formData.append('fullWidthRegular', fullWidthRegular);
  fullWidthTall && formData.append('fullWidthTall', fullWidthTall);
  squared && formData.append('squared', squared);
  tags && formData.append('tags', JSON.stringify(tags));
  formData.append('crops', JSON.stringify(crops));
  //moodId left
  return updateMediaBankImage(formData);
};

export const listMedias = async (requestConfig?: ApiCallConfig, typels?: MediaType) =>
  callApi<MediaDto[]>('/media-bank', {
    params: {...(requestConfig?.params ? requestConfig.params : null), typels},
    token: 'test',
    ...(requestConfig || null),
  });

export const addExercise = async (exercise: CreateExercise, requestConfig?: ApiCallConfig) => {
  const body = JSON.stringify(exercise);
  return callApi<ProgramDto>('/toolbox/exercises', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    params: {...(requestConfig?.params ? requestConfig.params : null)},
    body,
    token: 'test',
    ...(requestConfig || null),
  });
};

export const editExercise = async (exerciseId: number, exercise: UpdateExercise, requestConfig?: ApiCallConfig) => {
  const body = JSON.stringify(exercise);
  return callApi<ProgramDto>(`/toolbox/exercises/${exerciseId}`, {
    method: 'PATCH',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    params: {...(requestConfig?.params ? requestConfig.params : null)},
    body,
    token: 'test',
    ...(requestConfig || null),
  });
};

export const getMediaTags = async (requestConfig?: ApiCallConfig) =>
  callApi<string[]>('/media-bank/tags', {
    params: {...(requestConfig?.params ? requestConfig.params : null)},
    token: 'test',
    ...(requestConfig || null),
  });

export const getImageMoods = async (requestConfig?: ApiCallConfig) =>
  callApi<LabelDto[]>('/media-bank/moods', {
    params: {...(requestConfig?.params ? requestConfig.params : null)},
    token: 'test',
    ...(requestConfig || null),
  });

export const deleteMedia = async (media_id: number, requestConfig?: ApiCallConfig) =>
  callApi(`/media-bank/medias/${media_id}`, {
    method: 'DELETE',
    params: {...(requestConfig?.params ? requestConfig.params : null)},
    token: 'test',
    ...(requestConfig || null),
  });

export const addSession = async (session: CreateSession) => {
  const postSession = async (formData: FormData, requestConfig?: ApiCallConfig) => {
    return callApi<ExerciseDto>('/toolbox/sessions', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
      },
      params: {...(requestConfig?.params ? requestConfig.params : null)},
      body: formData,
      token: 'test',
      ...(requestConfig || null),
    });
  };
  const formData = new FormData();
  const {title, description, language, resumeLimit, categoryId} = session;
  formData.append('title', title);
  formData.append('description', description);
  formData.append('language', language);
  formData.append('resumeLimit', resumeLimit.toString());
  formData.append('categoryId', categoryId.toString());
  return postSession(formData); //Falta appendear el Media o la Imagen.
};
