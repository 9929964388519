import styled from 'styled-components';
import {Button} from '../../page';

export const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  overflow: auto;
`;

export const AddButton = styled(Button)`
  background: #2c98f0;
  color: white;
  text-decoration: none;

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

    :focus,
    :hover,
    :visited,
    :link,
    :active {
      color: white;
      text-decoration: none;
    }
  }
`;

export const ListControls = styled.div`
  display: flex;
  margin-bottom: 24px;
  justify-content: space-between;
`;

export const Filters = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ListGeneral = styled.div`
  display: table;
  table-layout: fixed;
  width: 100%;
`;

export const DetailHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const DetailTitle = styled.div`
  color: #010101;
  font-style: normal;
  font-size: 24px;
  font-weight: 500;
  line-height: 29px;
  margin-bottom: 8px;
`;

export const DetailButtonLink = styled.div`
  color: #2c98f0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-size: 14px;
  font-weight: normal;
  line-height: 17px;
  padding: 10px;
  user-select: none;

  :hover {
    text-decoration: underline;
  }
`;

export const DetailID = styled.div`
  color: #6c757d;
  font-style: normal;
  font-size: 14px;
  font-weight: normal;
  line-height: 21px;
  margin-bottom: 24px;

  :before {
    content: 'ID: ';
  }
`;

export const DetailField = styled.div`
  margin-bottom: 32px;
`;

export const DetailFieldRow = styled.div`
  display: flex;
  flex-direction: row;
  & > div {
    flex: 1;
  }
`;

export const DetailFieldLabel = styled.div`
  color: #6c757d;
  font-style: normal;
  font-weight: bold;
  line-height: 16px;
  font-size: 12px;
  margin-bottom: 8px;
  user-select: none;
`;

export const DetailFieldValue = styled.div`
  color: #010101;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  font-size: 16px;
`;

export const DetailFieldImage = styled.img`
  width: 400px;
`;

export const Label = styled.span`
  align-items: center;
  background-color: ${(props) => props.color};
  border-radius: 3px;
  color: white;
  display: flex;
  font-style: normal;
  font-weight: bold;
  font-size: 10.5px;
  flex-direction: row;
  flex-shrink: 0;
  height: 18px;
  justify-content: center;
  left: 0px;
  line-height: 10px;
  margin: 5px 5px;
  padding: 4px 8px;
  text-transform: capitalize;
  top: 0px;
  user-select: none;
  width: 66px;
  margin-left: 16px;
`;
