import React, {useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import {useParams} from 'react-router-dom';
import {Dropdown} from 'primereact/dropdown';
import {TabView, TabPanel} from 'primereact/tabview';
import {getProgramActivities} from 'api/chatowl.api';
import {useHistory} from 'react-router-dom';
import {Button} from 'primereact/button';
import {Menu} from 'primereact/menu';
import {PageContainer, PageTitle, PageListWrapper as ActivityListWrapper} from '../../components/page';
import {RootState} from 'index';
import {useAppDispatch, useAppSelector} from 'hooks';
import {shallowEqual} from 'react-redux';
import {selectActivity, unselectActivity} from 'store/action-creators';
import {capitalize} from 'utils/helpers';

const PageHeader = styled.div`
  align-items: left;
  display: flex;
  justify-content: space-between;
  margin-bottom: 42px;
  padding-top: 32px;
  flex-direction: column;
  width: 100%;
`;

const PageId = styled.div`
  display: flex;
  font-style: normal;
  font-size: 14px;
  margin: 2px;
  align-items: center;

  color: rgb(108, 117, 125);
`;

const Label = styled.span`
  align-items: center;
  background-color: ${(props) => props.color};
  border-radius: 3px;
  color: white;
  display: flex;
  font-style: normal;
  font-weight: bold;
  font-size: 10.5px;
  flex-direction: row;
  flex-shrink: 0;
  height: 18px;
  justify-content: center;
  left: 0px;
  line-height: 10px;
  margin: 5px 5px;
  padding: 4px 8px;
  text-transform: capitalize;
  top: 0px;
  user-select: none;
  width: 66px;
`;

const PageDescription = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const PageDescriptionText = styled.p`
  display: flex;
  font-style: normal;
  font-size: 16px;
  width: 90%;
  margin-top: 16px;
  margin-right: 16px;

  color: rgb(33, 50, 76);
`;

const PageImg = styled.img`
  display: flex;
  height: 180px;
  margin-top: -10px;
  border-radius: 4px;
`;

const ListControls = styled.div`
  display: flex;
  margin-bottom: 24px;
  justify-content: space-between;
`;

const Filters = styled.div`
  display: flex;
  flex-direction: row;
`;

const StyledSearchBar = styled.div`
  align-items: center;
  background-color: white;
  border: 1px solid #ced4da;
  box-sizing: border-box;
  border-radius: 3px;
  color: #495057;
  cursor: text;
  display: inline-flex;
  flex: 1;
  min-width: 50px;
  max-width: 400px;
  overflow: hidden;
  position: relative;
  transition: 0.1s;

  :hover:not(:focus-within) {
    background-color: #fafafa;
  }

  i {
    cursor: text;
    display: flex;
    margin: 0 8px 0 20px;
  }

  input {
    background: transparent;
    border: 0;
    font-size: 14px;
    line-height: 17px;
    padding: 8px;
    position: relative;
    width: 100%;
  }

  input:focus {
    outline: none;
  }

  :focus-within {
    border: 1px solid #293b55;
  }
`;

const SidebarContainer = styled.div`
  display: flex;
  position: relative;
  top: 0px;
  bottom: 0px;
  right: 0px;
`;

const SidebarBorder = styled.div`
  bottom: 0px;
  position: relative;
  top: 0px;
  transform: translateZ(0px);
  width: 24px;
  z-index: 3;
`;

const SidebarMargin = styled.div`
  right: -4px;
  position: relative;
  width: 24px;
  height: 100%;
`;

const SidebarLine = styled.div`
  background: rgba(0, 0, 0, 0)
    linear-gradient(to left, rgba(0, 0, 0, 0.2) 0px, rgba(0, 0, 0, 0.2) 1px, rgba(0, 0, 0, 0.1) 1px, rgba(0, 0, 0, 0) 100%) repeat
    scroll 0% 0%;
  bottom: 0px;
  right: -3px;
  opacity: 0.5;
  pointer-events: none;
  position: absolute;
  top: 0px;
  transition-duration: 0.22s;
  transition-property: right, opacity, width;
  transition-timing-function: cubic-bezier(0.2, 0, 0, 1);
  width: 3px;
`;

const SidebarButton = styled.button`
  background: white none repeat scroll 0px center;
  border: 0px none;
  border-radius: 50%;
  box-shadow: rgba(9, 30, 66, 0.08) 0px 0px 0px 1px, rgba(9, 30, 66, 0.08) 0px 2px 4px 1px;
  color: rgb(107, 119, 140);
  cursor: ${(props: SidebarProps) => props.isActive && 'pointer'};
  height: 24px;
  opacity: ${(props: SidebarProps) => (props.isActive ? '1' : '0')};
  outline: currentcolor none 0px;
  padding: 0px;
  position: absolute;
  top: 32px;
  transition: background-color 100ms linear 0s, color 100ms linear 0s, opacity 300ms cubic-bezier(0.2, 0, 0, 1) 0s,
    transform 300ms cubic-bezier(0.2, 0, 0, 1) 0s;
  transform: translateX(50%);
  width: 24px;

  :hover {
    background: #293b55 none repeat scroll 0px center;
    color: white;
  }
`;

const SidebarButtonSpan = styled.span`
  color: currentcolor;
  display: inline-block;
  fill: rgb(255, 255, 255);
  flex-shrink: 0;
  line-height: 1;
  height: 45px;
`;

const PDHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 26px;
`;

const PDTitle = styled.div`
  color: #010101;
  font-style: normal;
  font-size: 28px;
  font-weight: 800;
  line-height: 29px;
`;

const PDButtonLink = styled.div`
  color: #2c98f0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-size: 14px;
  font-weight: normal;
  line-height: 17px;
  padding: 10px;
  user-select: none;

  :hover {
    text-decoration: underline;
  }
`;

const PDField = styled.div`
  margin-bottom: 32px;
`;

const PDFieldLabel = styled.div`
  color: #6c757d;
  font-style: normal;
  font-weight: bold;
  line-height: 16px;
  font-size: 16px;
  margin-bottom: 8px;
  user-select: none;
`;

const PDFieldValue = styled.div`
  color: #010101;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  font-size: 16px;
`;

const PDFieldValueContainer = styled.div`
  display: flex;
  min-width: 0;
  max-width: 100%;
  align-items: center;
`;

const PDFieldImage = styled.img`
  width: 400px;
`;

const WeekSelectorContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 24px;
`;

type SidebarProps = {
  isActive?: boolean;
};

const SidebarContent = styled.div`
  bottom: 0px;
  top: 0px;
  right: 0px;
  max-width: 500px;
  min-width: 75px;
  width: ${(props: SidebarProps) => (props.isActive ? '500px' : '75px')};
  padding: 32px 28px 32px 40px;
  position: relative;
  transition: left 0.3s ease-in-out;
`;

type StyledFilterProps = {
  isSelected?: boolean;
};

const StyledFilter = styled.div`
  align-items: center;
  background: ${(props: StyledFilterProps) => (props.isSelected ? '#293b55' : 'white')};
  border-color: ${(props: StyledFilterProps) => (props.isSelected ? '#293b55' : '#ced4da')};
  border-width: 1px;
  border-style: solid;
  border-radius: 20px;
  box-shadow: ${(props: StyledFilterProps) => props.isSelected && '0px 2px 8px rgba(0, 0, 0, 0.1)'};
  color: ${(props: StyledFilterProps) => (props.isSelected ? 'white' : '#293b55')};
  cursor: pointer;
  display: flex;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  transition: 0.1s;
  margin: 1px 4px;
  justify-content: center;
  padding: 4px 24px;
  text-align: center;
  text-transform: capitalize;
  transition: 0.2s;
  user-select: none;
  width: 100px;

  :not(:hover) {
    box-shadow: inset 0px 2px 8px rgba(0, 0, 0, 0.01);
  }

  :hover {
    background: ${(props: StyledFilterProps) => !props.isSelected && '#fafafa'};
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.03);
  }
`;

const SidebarSVG = styled.svg`
  max-height: 100%;
  max-width: 100%;
  overflow: hidden;
  pointer-events: none;
  vertical-align: bottom;
`;

const ActivityList = styled.div`
  display: table;
  table-layout: fixed;
  width: 100%;
`;

const ActivitiesNotFound = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #495057;
  height: 50vh;

  :after {
    content: 'No activities found';
  }
`;

type ActivityCardContainerType = {
  isSelected: boolean;
};

const ActivityCardContainer = styled.div`
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  border-color: ${(props: ActivityCardContainerType) => (props.isSelected ? '#2196F3' : 'transparent')};
  border-style: solid;
  border-width: 2px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  min-height: 0px;
  margin-top: 8px;
  margin-bottom: 8px;
  min-width: 0;
  padding: 16px;
  transition: 0.1s;
  user-select: none;

  :hover {
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
  }
`;

const ActivityCardInfo = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 0px;
  justify-content: space-between;
`;

const FullActivityCardInfo = styled.div`
  display: flex;
  flex-direction: row;
  min-width: 0px;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`;

const ActivityCardHeader = styled.div`
  display: flex;
  min-width: 0;
  max-width: 100%;
`;

const ActivityTitle = styled.span`
  display: block;
  font-style: normal;
  font-weight: bold;
  font-size: 21px;
  margin-left: 8px;
  margin-right: 16px;
  max-height: 26px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const ActivityDescription = styled.div`
  color: #6c757d;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  margin: 8px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  @supports (-webkit-line-clamp: 2) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
`;

const ActivityImage = styled.img`
  align-self: center;
  border-radius: 4px;
  height: 88px;
  min-width: ${(props: ActivityImageProps) => (props.isSidebarOpen ? '88px' : '247px')};
  max-width: ${(props: ActivityImageProps) => (props.isSidebarOpen ? '88px' : '247px')};
  object-fit: cover;
`;

const DayActivityContainer = styled.div`
  display: flex;
  justify-content: space-around;
  margin-bottom: 80px;
`;

const Moment = styled.div`
  font-size: 16px;
  color: #777c7c;
  font-weight: 500;
  margin-top: 24px;
`;

const PDButtonBack = styled.div`
  color: #2c98f0;
  cursor: pointer;
  font-style: normal;
  font-size: 14px;
  font-weight: normal;
  line-height: 17px;
  align-items: center;
  display: flex;
  user-select: none;
  padding: 10px;
`;

type SearchBarProps = {
  value?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const SearchBar: React.FC<SearchBarProps> = ({value, onChange}) => {
  return (
    <StyledSearchBar>
      <label htmlFor='inputSearch'>
        <i className='pi pi-search'></i>
      </label>
      <input type='search' id='inputSearch' placeholder='Search' value={value} onChange={onChange} />
    </StyledSearchBar>
  );
};

const getColorByStatus = (status: ProgramStatus | undefined): string => {
  switch (status) {
    case 'archived':
      return '#D563FF';
    case 'draft':
      return '#038C8D';
    case 'published':
      return '#02ACEC';
    default:
      return '#02ACEC';
  }
};

type SelectedActivitySidebarProps = {
  isActive?: boolean;
};

type ActivityDetailsProps = {
  activity: any | null;
  activities: Array<ActivityDto[] | undefined>;
  weekValue: any;
};

const ActivityDetails: React.FC<ActivityDetailsProps> = ({activity, activities, weekValue}) => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const goToFirst = () => {
    let first = false;
    activities.map((x) => {
      if (!first) {
        let fact = x?.find((x) => x?.id == activity?.id);
        if (!!fact) {
          first = true;
          weekValue(fact?.timeDue?.week);
          dispatch(selectActivity(fact));
          setTimeout(() => {
            const element = document.getElementById(`${fact?.index}`);
            element?.scrollIntoView({behavior: 'smooth', block: 'center'});
          }, 100);
        }
      }
    });
  };

  return (
    <div>
      <div>
        <PDHeader>
          <PDTitle>Activity Info</PDTitle>
          <PDButtonLink onClick={() => history.push(`/therapy-programs/activity/edit/${activity.id}`)}>
            <i className='pi pi-external-link' style={{marginRight: '10px'}}></i>Edit
          </PDButtonLink>
        </PDHeader>
      </div>
      <div>
        <PDField>
          <PDFieldLabel>Type</PDFieldLabel>
          <PDFieldValueContainer>
            <PDFieldValue>{activity?.typeLabel}</PDFieldValue>
            {activity?.isMandatory && <Label color={'#F8A830'}>Mandatory</Label>}
            {activity?.isFree && <Label color={'#20C5A0'}>Free</Label>}
            {activity?.isLocked && <Label color={'#777C7C'}>Locked</Label>}
          </PDFieldValueContainer>
        </PDField>
        <PDField>
          <PDFieldLabel>{capitalize(activity.activityTool?.toolType)}</PDFieldLabel>
          <PDFieldValue>{activity.activityTool?.title}</PDFieldValue>
        </PDField>
        <PDField>
          <div style={{display: 'flex', flexDirection: 'row'}}>
            <div style={{display: 'flex', flexDirection: 'column'}}>
              <PDFieldLabel>Repeat</PDFieldLabel>
              <PDFieldValue>
                {activity?.repeatInterval && activity?.repeatInterval != 0
                  ? `Every ${activity?.repeatInterval / 86400} day` + (activity?.repeatInterval == 86400 ? '' : 's')
                  : 'No repeat'}
              </PDFieldValue>
            </div>
            <div style={{display: 'flex', flexDirection: 'column', marginLeft: '83px'}}>
              <PDFieldLabel>Priority</PDFieldLabel>
              <PDFieldValue>{activity?.priority ? activity?.priority : 'No priority'}</PDFieldValue>
            </div>
          </div>
        </PDField>
        {activity?.repeatInterval && activity?.repeatInterval != 0 && (
          <PDField>
            <PDButtonLink style={{justifyContent: 'flex-start'}} onClick={goToFirst}>
              <i className='pi pi-arrow-left' style={{marginRight: '10px'}}></i>
              Go to the first activity of this repetition
            </PDButtonLink>
          </PDField>
        )}
      </div>
    </div>
  );
};

type ActivityImageProps = {
  isSidebarOpen: boolean;
};

interface RouteParam {
  id: string;
}

export const TherapyProgramDetail: React.FC = () => {
  const [therapyProgram, setTherapyProgram] = useState<ProgramDto>();
  const [selectedFilters, setSelectedFilters] = useState<string[]>([]);
  const [searchValue, setSearchValue] = useState<string>('');
  const [weekValue, setWeekValue] = useState(1);
  const dispatch = useAppDispatch();

  const selectedActivity: ActivityDto | null = useAppSelector(
    (state: RootState) => state.activities.selectedActivity,
    shallowEqual
  );

  const params = useParams<RouteParam>();

  const filterByStatus = (activity: ActivityDto) =>
    !selectedFilters.length || selectedFilters.some((f) => f === activity.activityTool?.toolType);

  const filterBySearchValue = (activity: ActivityDto) =>
    !searchValue.toLowerCase() ||
    activity.activityTool?.title.toLowerCase().includes(searchValue.toLowerCase()) ||
    activity.activityTool?.description?.toLowerCase().includes(searchValue.toLowerCase()) ||
    activity.type?.toLowerCase().includes(searchValue.toLowerCase());

  const SelectedActivitySidebar: React.FC<SelectedActivitySidebarProps> = ({isActive, children}) => {
    const onClick = () => {
      dispatch(unselectActivity());
    };

    return (
      <SidebarContainer>
        <SidebarBorder onClick={onClick}>
          <SidebarMargin />
          <SidebarLine />
          <SidebarButton isActive={isActive}>
            <SidebarButtonSpan>
              <SidebarSVG>
                <path
                  d='M10.294 9.698a.988.988 0 0 1 0-1.407 1.01 1.01 0 0 1 1.419 0l2.965 2.94a1.09 1.09 0 0 1 0 1.548l-2.955 2.93a1.01 1.01 0 0 1-1.42 0 .988.988 0 0 1 0-1.407l2.318-2.297-2.327-2.307z'
                  fill='currentColor'
                  fillRule='evenodd'
                ></path>
              </SidebarSVG>
            </SidebarButtonSpan>
          </SidebarButton>
        </SidebarBorder>
        <SidebarContent isActive={isActive}>{isActive && children}</SidebarContent>
      </SidebarContainer>
    );
  };

  useEffect(() => {
    const fetchProgramActivities = async () => {
      try {
        const response = (await getProgramActivities(params.id)) as any;
        const therapyProgram = response.data.data;
        setTherapyProgram(therapyProgram);
      } catch (error) {
        throw error; // TODO
      }
    };
    fetchProgramActivities();
  }, []);

  const filters: string[] = ['session', 'exercise', 'quote', 'image', 'interactive'];

  const Filter = (filter: string) => {
    const toggleFilters = () => {
      selectedFilters.some((f) => f === filter)
        ? setSelectedFilters(selectedFilters.filter((f) => f !== filter)) // turn off
        : setSelectedFilters([...selectedFilters, filter]); // turn on
    };

    return (
      <StyledFilter key={filter} onClick={toggleFilters} isSelected={selectedFilters.some((f) => f === filter)}>
        {filter}
      </StyledFilter>
    );
  };

  const onSearchValueChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setSearchValue(e.target.value);
  };

  type Week = {
    name: string;
    value: number;
  };

  const dropdownValues = (): Week[] => {
    // let act = activities[activities.length - 1];
    let act: ActivityDto[] = [];
    activities.map((x) => {
      if (x && x?.length > 0) act = x;
    });
    let number = act && act[0] ? act[0].timeDue?.week : 0;
    let weeks: Week[] = [];
    for (let n = 1; n <= number; n++) {
      weeks.push({name: `Week ${n}`, value: n});
    }
    return weeks;
  };

  const WeekSelector = () => {
    const next = () => {
      if (weekValue < dropdownValues().length) {
        setWeekValue(weekValue + 1);
      }
    };

    const back = () => {
      if (weekValue > 1) {
        setWeekValue(weekValue - 1);
      }
    };
    if (dropdownValues().length > 0)
      return (
        <WeekSelectorContainer>
          <i className='pi pi-angle-left' style={{fontSize: '1.8rem', color: '#2196F3', marginRight: 16}} onClick={back}></i>
          <Dropdown value={weekValue} onChange={(e) => setWeekValue(e.value)} options={dropdownValues()} optionLabel='name' />
          <i className='pi pi-angle-right' style={{fontSize: '1.8rem', color: '#2196F3', marginLeft: 16}} onClick={next}></i>
        </WeekSelectorContainer>
      );
    else return null;
  };

  const ActivityCard = (activity: ActivityDto) => {
    const isSelected = selectedActivity?.index === activity.index;

    const onActivityClick = (): void => {
      if (isSelected) {
        dispatch(unselectActivity());
      } else {
        dispatch(selectActivity(activity));
      }
    };

    return (
      <ActivityCardContainer key={activity.index} id={`${activity.index}`} onClick={onActivityClick} isSelected={isSelected}>
        <ActivityCardInfo>
          <ActivityCardHeader>
            <ActivityTitle title={activity?.activityTool?.title}>{activity?.activityTool?.title}</ActivityTitle>

            {(activity?.isMandatory && activity?.isFree) ||
            (activity?.isMandatory && activity?.isLocked) ||
            (activity?.isFree && activity?.isLocked) ? null : (
              <>
                {activity?.isMandatory && <Label color={'#F8A830'}>Mandatory</Label>}
                {activity?.isFree && <Label color={'#20C5A0'}>Free</Label>}
                {activity?.isLocked && <Label color={'#777C7C'}>Locked</Label>}
              </>
            )}
          </ActivityCardHeader>
          <ActivityDescription title={activity.activityTool?.description}>
            {activity.activityTool?.description}
          </ActivityDescription>
        </ActivityCardInfo>
        <div style={{position: 'relative'}}>
          <ActivityImage isSidebarOpen={!!selectedActivity} src={activity.activityTool?.imageLandscapeUrl} />
          {activity.repeatInterval !== null && activity?.repeatInterval != 0 && (
            <div style={{position: 'absolute', top: '-9px', right: '-9px'}}>
              <svg width='23' height='24' viewBox='0 0 23 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path
                  d='M11.3672 23.3555C17.5781 23.3555 22.7227 18.2109 22.7227 12C22.7227 5.78906 17.5664 0.644531 11.3555 0.644531C5.14453 0.644531 0 5.78906 0 12C0 18.2109 5.15625 23.3555 11.3672 23.3555ZM4.20703 11.5664V11.0273C4.20703 9.10547 5.56641 7.875 7.69922 7.875H12.1992V6.71484C12.1992 6.28125 12.4922 5.98828 12.9492 5.98828C13.125 5.98828 13.3359 6.07031 13.4883 6.17578L15.9023 8.19141C16.2773 8.49609 16.2773 9 15.9023 9.30469L13.4766 11.3203C13.3242 11.4492 13.125 11.5312 12.9492 11.5312C12.4922 11.5312 12.1992 11.2266 12.1992 10.793V9.59766H7.57031C6.59766 9.59766 5.96484 10.207 5.96484 11.1328V11.5664C5.96484 12.0469 5.57812 12.4219 5.08594 12.4219C4.59375 12.4219 4.20703 12.0469 4.20703 11.5664ZM6.82031 15.8672C6.43359 15.5508 6.43359 15.0469 6.82031 14.7539L9.24609 12.7383C9.39844 12.6094 9.59766 12.5273 9.77344 12.5273C10.2305 12.5273 10.5234 12.832 10.5234 13.2656V14.4492H15.1406C16.125 14.4492 16.7461 13.8398 16.7461 12.9141V12.4805C16.7461 12 17.1445 11.6133 17.6367 11.6133C18.1289 11.6133 18.5273 12 18.5273 12.4805V13.0195C18.5273 14.9414 17.1562 16.1836 15.0234 16.1836H10.5234V17.3438C10.5234 17.7773 10.2305 18.0703 9.77344 18.0703C9.59766 18.0703 9.375 17.9883 9.23438 17.8828L6.82031 15.8672Z'
                  fill='#2C98F0'
                />
              </svg>
            </div>
          )}
        </div>
      </ActivityCardContainer>
    );
  };

  const FullActivityCard = (activity: ActivityDto) => {
    const isSelected = selectedActivity?.index === activity.index;

    const onActivityClick = (): void => {
      console.log({isSelected});
      if (isSelected) {
        dispatch(unselectActivity());
      } else {
        dispatch(selectActivity(activity));
      }
    };

    return (
      <ActivityCardContainer id={`${activity.index}`} key={activity.index} onClick={onActivityClick} isSelected={isSelected}>
        <FullActivityCardInfo>
          <div style={{display: 'flex'}}>
            <ActivityCardHeader>
              <ActivityTitle title={activity?.activityTool?.title}>{activity?.activityTool?.title}</ActivityTitle>
            </ActivityCardHeader>
            <ActivityDescription title={activity.activityTool?.description}>
              {activity.activityTool?.description}
            </ActivityDescription>
          </div>
          {activity.repeatInterval !== null && activity?.repeatInterval != 0 && (
            <div>
              <svg width='23' height='24' viewBox='0 0 23 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path
                  d='M11.3672 23.3555C17.5781 23.3555 22.7227 18.2109 22.7227 12C22.7227 5.78906 17.5664 0.644531 11.3555 0.644531C5.14453 0.644531 0 5.78906 0 12C0 18.2109 5.15625 23.3555 11.3672 23.3555ZM4.20703 11.5664V11.0273C4.20703 9.10547 5.56641 7.875 7.69922 7.875H12.1992V6.71484C12.1992 6.28125 12.4922 5.98828 12.9492 5.98828C13.125 5.98828 13.3359 6.07031 13.4883 6.17578L15.9023 8.19141C16.2773 8.49609 16.2773 9 15.9023 9.30469L13.4766 11.3203C13.3242 11.4492 13.125 11.5312 12.9492 11.5312C12.4922 11.5312 12.1992 11.2266 12.1992 10.793V9.59766H7.57031C6.59766 9.59766 5.96484 10.207 5.96484 11.1328V11.5664C5.96484 12.0469 5.57812 12.4219 5.08594 12.4219C4.59375 12.4219 4.20703 12.0469 4.20703 11.5664ZM6.82031 15.8672C6.43359 15.5508 6.43359 15.0469 6.82031 14.7539L9.24609 12.7383C9.39844 12.6094 9.59766 12.5273 9.77344 12.5273C10.2305 12.5273 10.5234 12.832 10.5234 13.2656V14.4492H15.1406C16.125 14.4492 16.7461 13.8398 16.7461 12.9141V12.4805C16.7461 12 17.1445 11.6133 17.6367 11.6133C18.1289 11.6133 18.5273 12 18.5273 12.4805V13.0195C18.5273 14.9414 17.1562 16.1836 15.0234 16.1836H10.5234V17.3438C10.5234 17.7773 10.2305 18.0703 9.77344 18.0703C9.59766 18.0703 9.375 17.9883 9.23438 17.8828L6.82031 15.8672Z'
                  fill='#2C98F0'
                />
              </svg>
            </div>
          )}
        </FullActivityCardInfo>
      </ActivityCardContainer>
    );
  };

  const activities: Array<ActivityDto[] | undefined> = new Array();

  for (let key in therapyProgram?.programActivities) {
    activities.push(therapyProgram?.programActivities[key]);
  }

  const programActivitiesResults = activities
    .map((v, k) => v?.filter(filterByStatus))
    .map((v, k) => v?.filter(filterBySearchValue))
    .filter((x) => x && x.length > 0);

  const history = useHistory();

  const back = () => {
    history.push('/therapy-programs');
  };

  const programActivitiesWeek = activities
    ?.map((v, k) => v?.filter((x) => x.timeDue?.week === weekValue))
    .filter((x) => x && x.length > 0);

  const redirectTo = () => {
    history.push(`/therapy-programs/edit/${params.id}`);
  };

  const overlayMenuItems = [
    {
      icon: 'pi pi-external-link',
      label: 'Edit Details',
      command: redirectTo,
    },
    {
      icon: 'pi pi-calendar',
      label: 'Manage Weeks',
    },
    {
      icon: 'pi pi-trash',
      label: 'Delete Programs',
      // style: { color: 'red' },
      className: 'selectRed',
    },
  ];

  const menu: React.MutableRefObject<any> = useRef(null);

  const toggleMenu = (event: any) => {
    menu.current.toggle(event);
  };

  const addActivity = (day: number | undefined) => {
    const activityDay = {
      timeDue: {
        day: day,
      },
    };
    dispatch(selectActivity(activityDay));
    history.push('/therapy-programs/activity/add');
  };

  return (
    <PageContainer>
      <ActivityListWrapper>
        <PageHeader style={{marginBottom: '0px'}}>
          <PageTitle>
            {/* Improve button settings v */}
            <div style={{display: 'flex', alignItems: 'center'}}>
              {therapyProgram?.name}
              <Menu ref={menu} model={overlayMenuItems} popup />
              <Button onClick={toggleMenu} className='p-button-text p-m-2'>
                <svg width='16' height='4' viewBox='0 0 16 4' fill='none' xmlns='http://www.w3.org/2000/svg'>
                  <path
                    d='M12 2C12 0.9 12.9 1.35505e-07 14 8.74228e-08C15.1 3.93402e-08 16 0.9 16 2C16 3.1 15.1 4 14 4C12.9 4 12 3.1 12 2ZM10 2C10 3.1 9.1 4 8 4C6.9 4 6 3.1 6 2C6 0.900001 6.9 3.97774e-07 8 3.49691e-07C9.1 3.01609e-07 10 0.9 10 2ZM4 2C4 3.1 3.1 4 2 4C0.9 4 -3.93403e-08 3.1 -8.74228e-08 2C-1.35505e-07 0.900001 0.899999 6.60042e-07 2 6.11959e-07C3.1 5.63877e-07 4 0.900001 4 2Z'
                    fill='#C2C9D1'
                  />
                  <path
                    d='M12 2C12 0.9 12.9 1.35505e-07 14 8.74228e-08C15.1 3.93402e-08 16 0.9 16 2C16 3.1 15.1 4 14 4C12.9 4 12 3.1 12 2ZM10 2C10 3.1 9.1 4 8 4C6.9 4 6 3.1 6 2C6 0.900001 6.9 3.97774e-07 8 3.49691e-07C9.1 3.01609e-07 10 0.9 10 2ZM4 2C4 3.1 3.1 4 2 4C0.9 4 -3.93403e-08 3.1 -8.74228e-08 2C-1.35505e-07 0.900001 0.899999 6.60042e-07 2 6.11959e-07C3.1 5.63877e-07 4 0.900001 4 2Z'
                    fill='black'
                    fill-opacity='0.25'
                  />
                </svg>
              </Button>
              {/* Improve button settings ^ */}
            </div>
            <PDButtonBack onClick={back}>
              <i className='pi pi-angle-left' style={{fontSize: '1.5rem'}}></i>
              Back to list
            </PDButtonBack>
          </PageTitle>
          <PageId>
            ID: {params.id} <Label color={getColorByStatus(therapyProgram?.status)}>{therapyProgram?.status}</Label>
          </PageId>
          <PageDescription>
            <PageDescriptionText>{therapyProgram?.description}</PageDescriptionText>
            <PageImg src={therapyProgram?.media?.thumbnailUrl}></PageImg>
          </PageDescription>
        </PageHeader>
        <TabView>
          <TabPanel
            header={
              <div>
                <i style={{marginRight: '5px'}} className='pi pi-bars'></i>Full Program
              </div>
            }
          >
            <ListControls>
              <Filters>{filters.map(Filter)}</Filters>
              <SearchBar value={searchValue} onChange={onSearchValueChange} />
            </ListControls>

            {programActivitiesResults?.length ? null : (
              <DayActivityContainer>
                <div style={{marginRight: '88px', width: '185px'}}>
                  <div style={{backgroundColor: ''}}>
                    <h1>Day 1</h1>
                    <Button
                      onClick={() => addActivity(1)}
                      style={{width: '100%'}}
                      label='+ Add activity'
                      className='p-button-outlined p-mr-2 p-mb-2'
                    />
                  </div>
                </div>
                <ActivityList>
                  <ActivitiesNotFound />
                </ActivityList>
              </DayActivityContainer>
            )}

            {programActivitiesResults?.map((v) => (
              <DayActivityContainer>
                <div style={{marginRight: '88px', width: '185px'}}>
                  <div style={{backgroundColor: ''}}>
                    <h1>Day {v && v[0]?.timeDue?.day}</h1>
                    <Button
                      onClick={() => addActivity(v && v[0]?.timeDue?.day)}
                      style={{width: '100%'}}
                      label='+ Add activity'
                      className='p-button-outlined p-mr-2 p-mb-2'
                    />
                  </div>
                </div>
                <ActivityList>
                  {v?.filter((x) => x.timeDue?.moment == 'morning').length ? (
                    <>
                      <Moment>Morning</Moment>
                      {v?.filter((x) => x.timeDue?.moment == 'morning').map(FullActivityCard)}
                    </>
                  ) : null}

                  {v?.filter((x) => x.timeDue?.moment == 'midday').length ? (
                    <>
                      <Moment>Midday</Moment>
                      {v?.filter((x) => x.timeDue?.moment == 'midday').map(FullActivityCard)}
                    </>
                  ) : null}

                  {v?.filter((x) => x.timeDue?.moment == 'afternoon').length ? (
                    <>
                      <Moment>Afternoon</Moment>
                      {v?.filter((x) => x.timeDue?.moment == 'afternoon').map(FullActivityCard)}
                    </>
                  ) : null}

                  {v?.filter((x) => x.timeDue?.moment == 'evening').length ? (
                    <>
                      <Moment>Evening</Moment>
                      {v?.filter((x) => x.timeDue?.moment == 'evening').map(FullActivityCard)}
                    </>
                  ) : null}
                </ActivityList>
              </DayActivityContainer>
            ))}
          </TabPanel>
          <TabPanel
            header={
              <div>
                <i style={{marginRight: '5px'}} className='pi pi-image'></i>Week View
              </div>
            }
          >
            <WeekSelector />
            {programActivitiesResults?.length ? null : (
              <DayActivityContainer>
                <div style={{marginRight: '88px', width: '185px'}}>
                  <div style={{backgroundColor: ''}}>
                    <h1>Day 1</h1>
                    <Button
                      onClick={() => addActivity(1)}
                      style={{width: '100%'}}
                      label='+ Add activity'
                      className='p-button-outlined p-mr-2 p-mb-2'
                    />
                  </div>
                </div>
                <ActivityList>
                  <ActivitiesNotFound />
                </ActivityList>
              </DayActivityContainer>
            )}
            {programActivitiesWeek?.map((v) => (
              <DayActivityContainer>
                {v?.length ? (
                  <div style={{marginRight: '88px', width: '185px'}}>
                    <div>
                      <h1>Day {v && v[0]?.timeDue?.day}</h1>
                      <Button
                        style={{width: '100%'}}
                        label='+ Add activity'
                        className='p-button-outlined p-mr-2 p-mb-2'
                        onClick={() => addActivity(v[0]?.timeDue?.day)}
                      />
                    </div>
                  </div>
                ) : null}
                <ActivityList>
                  {v?.filter((x) => x.timeDue?.moment == 'morning').length ? (
                    <>
                      <Moment>Morning</Moment>
                      {v?.filter((x) => x.timeDue?.moment == 'morning').map(ActivityCard)}
                    </>
                  ) : null}

                  {v?.filter((x) => x.timeDue?.moment == 'midday').length ? (
                    <>
                      <Moment>Midday</Moment>
                      {v?.filter((x) => x.timeDue?.moment == 'midday').map(ActivityCard)}
                    </>
                  ) : null}

                  {v?.filter((x) => x.timeDue?.moment == 'afternoon').length ? (
                    <>
                      <Moment>Afternoon</Moment>
                      {v?.filter((x) => x.timeDue?.moment == 'afternoon').map(ActivityCard)}
                    </>
                  ) : null}

                  {v?.filter((x) => x.timeDue?.moment == 'evening').length ? (
                    <>
                      <Moment>Evening</Moment>
                      {v?.filter((x) => x.timeDue?.moment == 'evening').map(ActivityCard)}
                    </>
                  ) : null}
                </ActivityList>
              </DayActivityContainer>
            ))}
          </TabPanel>
        </TabView>
      </ActivityListWrapper>
      <SelectedActivitySidebar isActive={!!selectedActivity}>
        <ActivityDetails activities={activities} activity={selectedActivity} weekValue={setWeekValue} />
      </SelectedActivitySidebar>
    </PageContainer>
  );
};
