import {DefaultProgramButtonHelpText} from 'components/page';
import React, {useState} from 'react';
import {
  DefaultProgramButton,
  DefaultProgramButtonContainer,
  DefaultProgramChoiceButtonsContainer,
  DefaultProgramSelectCancelButton,
  DefaultProgramSelectYesButton,
  modalStyle,
} from './styles';
import Modal from 'react-modal';

type DefaultProgramAssignmentProps = {
  isCurrentDefaultProgram: boolean;
  willBeDefaultProgram: boolean;
  setWillBeDefaultProgram: (willBeDefaultProgram: boolean) => void;
};

export const DefaultProgramAssignment: React.FC<DefaultProgramAssignmentProps> = (props) => {
  const {isCurrentDefaultProgram, willBeDefaultProgram, setWillBeDefaultProgram} = props;
  const [isDefaultProgramPromptOpen, setIsDefaultProgramPromptOpen] = useState(false);

  const toggleDefaultProgramPrompt = () => {
    setIsDefaultProgramPromptOpen(!isDefaultProgramPromptOpen);
  };

  const onButtonClick = () => {
    if (isCurrentDefaultProgram) {
      return; // do nothing
    } else if (willBeDefaultProgram) {
      setWillBeDefaultProgram(false);
    } else {
      toggleDefaultProgramPrompt();
    }
  };

  const onSelectCancelDefaultProgramPrompt = () => {
    setWillBeDefaultProgram(false);
    toggleDefaultProgramPrompt();
  };
  const onSelectYesDefaultProgramPrompt = () => {
    setWillBeDefaultProgram(true);
    toggleDefaultProgramPrompt();
  };

  return (
    <DefaultProgramButtonContainer>
      <DefaultProgramButton
        isCurrentDefaultProgram={isCurrentDefaultProgram}
        willBeDefaultProgram={willBeDefaultProgram}
        onClick={onButtonClick}
      >
        {isCurrentDefaultProgram
          ? 'Active Default Program'
          : willBeDefaultProgram
          ? 'Undo Default Program Settings'
          : 'Set as Default Program'}
      </DefaultProgramButton>
      <DefaultProgramButtonHelpText>
        The default program is assigned to all users after onboarding before they are assigned a personalized program after the
        assessment in the first session.
      </DefaultProgramButtonHelpText>
      <Modal
        isOpen={isDefaultProgramPromptOpen}
        onRequestClose={toggleDefaultProgramPrompt}
        contentLabel='Are you sure you want to set Default Program?'
        style={modalStyle}
      >
        <h5>Set as Default Program</h5>
        <p>
          You can only have one default program. By setting this as the default therapy program the previous one will be updated
          to a regular program. Are you sure you want to make this change?
        </p>
        <DefaultProgramChoiceButtonsContainer>
          <DefaultProgramSelectCancelButton onClick={onSelectCancelDefaultProgramPrompt}>Cancel</DefaultProgramSelectCancelButton>
          <DefaultProgramSelectYesButton onClick={onSelectYesDefaultProgramPrompt}>Yes</DefaultProgramSelectYesButton>
        </DefaultProgramChoiceButtonsContainer>
      </Modal>
    </DefaultProgramButtonContainer>
  );
};
