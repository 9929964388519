import styled from 'styled-components';

//Here are the common styles for the 3 types of media Details

export const InputButton = styled.div`
  width: 100%;
  position: relative;
`;

export const InputComplete = styled.input`
  margin: 0px;
  padding: 0px;
  width: 100%;
  outline: none;
  height: 38px;
  border-radius: 5px;
`;

export const AddInput = styled.div`
  position: absolute;
  top: 0;
  border-radius: 5px;
  right: 25px;
  z-index: 2;
  border: none;
  top: 25%;
  height: 30px;
  cursor: pointer;
  color: '#12C98F0';
  transform: translateX(2px);
`;

export const Tag = styled.span`
  align-items: center;
  background-color: ${(props) => props.color};
  border-radius: 3px;
  color: white;
  display: flex;
  font-style: normal;
  font-weight: bold;
  font-size: 10.5px;
  flex-direction: row;
  flex-shrink: 0;
  height: 18px;
  justify-content: center;
  left: 0px;
  line-height: 10px;
  margin: 5px 5px;
  padding: 4px 8px;
  text-transform: capitalize;
  top: 0px;
  user-select: none;
  width: 66px;
`;

type CursorLoadingProps = {
  disable?: boolean;
};

export const CursorLoading = styled.div`
  cursor: ${(props: CursorLoadingProps) => (props.disable ? 'wait' : 'default')};
`;
