import React, {useEffect, useRef, useState} from 'react';
import classNames from 'classnames';
import {Redirect, Route, Switch} from 'react-router-dom';
import AppMenu from './app.menu';
import {TherapyPrograms} from '../../pages/therapy-programs/therapy-programs';
import {TherapyProgramDetail} from '../../pages/therapy-programs/therapy-program-detail';
import {Signout} from 'components/signout';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import './app.scss';
import {Messaging} from 'pages/messaging';
import {BotChat} from 'pages/bot-chat';
import {MediaBank} from 'pages/media-bank/media-list';
import {MediaDetails} from 'pages/media-bank/media-details';
import {Exercises} from 'pages/tools/exercises/exercises';
import {AddExercise} from 'pages/tools/exercises/add-exercise';
import {Clients} from 'pages/clients';
import {AddProgram} from 'pages/therapy-programs/add-program';
import {EditProgram} from 'pages/therapy-programs/edit-program';
import {AddActivity} from '../../pages/therapy-programs/activities/add-activity';
import {EditActivity} from '../../pages/therapy-programs/activities/edit-activity';
import {Toast} from 'primereact/toast';
import {useAppSelector} from '../../hooks';
import {RootState} from '../../index';
import {shallowEqual} from 'react-redux';

const App = () => {
  const [menuActive, setMenuActive] = useState(false);
  const toast: React.MutableRefObject<any> = useRef(null);
  const toastMessage: ToastMessage | null = useAppSelector((state: RootState) => state.toast.toastInfo, shallowEqual);
  const toggleToast: boolean = useAppSelector((state: RootState) => state.toast.toggleToast, shallowEqual);
  useEffect(() => {
    toastMessage &&
      toast.current.show({severity: toastMessage.severity, summary: toastMessage.summary, detail: toastMessage.detail});
  }, [toastMessage, toggleToast]); //toggleToast alllows me send two equal Messages.

  let menuClick = false;

  const menu = [
    {
      label: '',
      icon: 'pi pi-fw pi-home',
      items: [
        {label: 'Therapy Programs', icon: 'pi pi-fw pi-list', to: '/therapy-programs/'},
        {
          label: 'Tools',
          icon: 'pi pi-fw pi-th-large',
          items: [
            {label: 'Sessions'},
            {label: 'Exercises', to: '/tools/exercises'},
            {label: 'Quotes'},
            {label: 'Images'},
            {label: 'Categories'},
          ],
        },
        {label: 'Messaging', icon: 'pi pi-fw pi-inbox', to: '/messaging/'},
        {label: 'Bot Chat', icon: 'pi pi-fw pi-comment', to: '/bot-chat/'},
        {label: 'Media Bank', icon: 'pi pi-fw pi-image', to: '/media-bank/'},
        {label: 'Clients', icon: 'pi pi-fw pi-users', to: '/clients/'},
      ],
    },
    {separator: true},
    {
      label: 'Administrator User',
      icon: 'pi pi-fw pi-user',
      items: [{label: 'Sign Out', icon: 'pi pi-fw pi-sign-out', to: '/signout/'}],
    },
  ];

  const routers = [
    {
      path: '/therapy-programs',
      exact: true,
      component: TherapyPrograms,
      meta: {breadcrumb: [{parent: 'Menu', label: 'Therapy Programs'}]},
    },
    {
      path: '/therapy-programs/add',
      component: AddProgram,
      meta: {breadcrumb: [{parent: 'Menu', label: 'Therapy Programs'}]},
    },
    {
      path: '/therapy-programs/edit/:id',
      component: EditProgram,
      meta: {breadcrumb: [{parent: 'Menu', label: 'Therapy Programs'}]},
    },

    {path: '/messaging', component: Messaging, meta: {breadcrumb: [{parent: 'Menu', label: 'Messaging'}]}},
    {path: '/bot-chat', component: BotChat, meta: {breadcrumb: [{parent: 'Menu', label: 'Bot Chat'}]}},
    {path: '/media-bank', exact: true, component: MediaBank, meta: {breadcrumb: [{parent: 'Menu', label: 'Media Bank'}]}},
    {
      path: '/media-bank/detail/:formType',
      exact: true,
      component: MediaDetails,
      meta: {breadcrumb: [{parent: 'Media Bank', label: 'Media Bank'}]},
    },
    {path: '/clients', component: Clients, meta: {breadcrumb: [{parent: 'Menu', label: 'Clients'}]}},
    {path: '/signout', component: Signout, meta: {breadcrumb: [{parent: 'Menu', label: 'Sign Out'}]}},
    {
      path: '/therapy-program/:id',
      component: TherapyProgramDetail,
      meta: {breadcrumb: [{parent: 'Therapy Programs', label: 'Therapy Program Detail'}]},
    },
    {
      path: '/therapy-programs/activity/add',
      component: AddActivity,
      meta: {breadcrumb: [{parent: 'Therapy Programs', label: 'Therapy Program Detail'}]},
    },
    {
      path: '/therapy-programs/activity/edit/:id',
      component: EditActivity,
      meta: {breadcrumb: [{parent: 'Therapy Programs', label: 'Therapy Program Detail'}]},
    },

    {
      path: '/tools/exercises',
      component: Exercises,
      exact: true,
      meta: {breadcrumb: [{parent: 'Tools', label: 'Exercises'}]},
    },

    {
      path: '/tools/exercises/:formType',
      component: AddExercise,
      exact: true,
      meta: {breadcrumb: [{parent: 'Exercises', label: 'Exercises'}]},
    },
  ];

  const onDocumentClick = () => {
    if (!menuClick) {
      unblockBodyScroll();
    }
    menuClick = false;
  };

  const onMenuClick = () => {
    menuClick = true;
  };

  const onRootMenuItemClick = () => {
    setMenuActive((prevMenuActive) => !prevMenuActive);
  };

  const unblockBodyScroll = () => {
    if (document.body.classList) {
      document.body.classList.remove('blocked-scroll');
    } else {
      document.body.className = document.body.className.replace(
        new RegExp('(^|\\b)' + 'blocked-scroll'.split(' ').join('|') + '(\\b|$)', 'gi'),
        ' '
      );
    }
  };

  const containerClassName = classNames(
    'layout-wrapper',
    {
      'layout-static': true,
      'p-ripple-disabled': true,
    },
    'layout-sidebar-darkgray'
  );

  return (
    <div className={containerClassName} data-theme='light' onClick={onDocumentClick}>
      <Toast ref={toast}></Toast>
      <div className='layout-content-wrapper'>
        <div>
          <Switch>
            <Route exact path='/'>
              <Redirect to='/therapy-programs' />
            </Route>

            {routers.map((router, index) => {
              if (router.exact) {
                return <Route key={`router${index}`} path={router.path} exact component={router.component} />;
              }
              return <Route key={`router${index}`} path={router.path} component={router.component} />;
            })}
            <Route>
              <Redirect to='/notfound' />
            </Route>
          </Switch>
        </div>
      </div>

      <AppMenu
        model={menu}
        menuMode='static'
        active={menuActive}
        mobileMenuActive={false}
        onMenuClick={onMenuClick}
        onMenuItemClick={() => {}}
        onRootMenuItemClick={onRootMenuItemClick}
      ></AppMenu>
      <div className='layout-mask modal-in'></div>
    </div>
  );
};

export default App;
