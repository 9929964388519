import {ActionReducerMapBuilder, createReducer} from '@reduxjs/toolkit';
import {selectActivity, unselectActivity} from './action-creators'; //Action creators for activityReducer
import {selectProgram, unselectProgram} from './action-creators'; //Action creators for programReducer
import {selectExercise, unselectExercise} from './action-creators'; //Action creators for exerciseReducer
import {selectMedia, unselectMedia} from './action-creators'; //Action creators for exerciseReducer
import {sendMessage} from './action-creators'; //Action creators for generalToast

const initialActivityState: ActivityState = {
  selectedActivity: null,
};

export const activityReducer = createReducer(initialActivityState, (builder: ActionReducerMapBuilder<ActivityState>) => {
  builder
    .addCase(selectActivity, (state: ActivityState, action: ActivityAction) => {
      state.selectedActivity = action.payload!;
    })
    .addCase(unselectActivity, (state: ActivityState) => {
      state.selectedActivity = null;
    });
});

const initialProgramState: ProgramState = {
  selectedProgram: null,
};

export const programReducer = createReducer(initialProgramState, (builder: ActionReducerMapBuilder<ProgramState>) => {
  builder
    .addCase(selectProgram, (state: ProgramState, action: ProgramAction) => {
      state.selectedProgram = action.payload!;
    })
    .addCase(unselectProgram, (state: ProgramState) => {
      state.selectedProgram = null;
    });
});

const initialExerciseState: ExerciseState = {
  selectedExercise: null,
};

export const exerciseReducer = createReducer(initialExerciseState, (builder: ActionReducerMapBuilder<ExerciseState>) => {
  builder
    .addCase(selectExercise, (state: ExerciseState, action: ExerciseAction) => {
      state.selectedExercise = action.payload!;
    })
    .addCase(unselectExercise, (state: ExerciseState) => {
      state.selectedExercise = null;
    });
});

const initialMediaBankState: MediaBankState = {
  selectedMedia: null,
};

export const mediaBankReducer = createReducer(initialMediaBankState, (builder: ActionReducerMapBuilder<MediaBankState>) => {
  builder
    .addCase(selectMedia, (state: MediaBankState, action: MediaBankAction) => {
      state.selectedMedia = action.payload!;
    })
    .addCase(unselectMedia, (state: MediaBankState) => {
      state.selectedMedia = null;
    });
});

const initialGeneralToast: GeneralToastState = {
  toastInfo: null,
  toggleToast: false,
};

export const generalToastReducer = createReducer(initialGeneralToast, (builder: ActionReducerMapBuilder<GeneralToastState>) => {
  builder.addCase(sendMessage, (state: GeneralToastState, action: GeneralToastAction) => {
    state.toastInfo = action.payload!;
    state.toggleToast = !state.toggleToast; //Always updating state.
  });
});
