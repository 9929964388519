import {
  AddProgramForm,
  CancelButton,
  PageBody,
  PageFooter,
  SaveButton,
  ArchiveButton as DeleteButton,
  Label,
} from 'components/page';
import {FormElement, StyledFormElement} from 'components/form-element';
import {Dropdown} from 'primereact/dropdown';
import React, {useEffect, useState} from 'react';
import {Link, useHistory} from 'react-router-dom';
import {ImageCropperManager} from '../../crop-manager';
import {MediaDetailsFormType} from '..';
import {useTags} from 'hooks';
import {createImage, updateImage, getImageMoods, deleteMedia} from 'api/chatowl.api';
import {AddInput, InputButton, InputComplete, Tag, CursorLoading} from '../styles';
import {useAppSelector, useAppDispatch} from '../../../../hooks';
import {sendMessage} from '../../../../store/action-creators';

import {RootState} from '../../../../index';
import {shallowEqual} from 'react-redux';
import {capitalize} from 'utils/helpers';

function validateSave(name: string, crops: number, imagesCrops: number): boolean {
  return !!name && (!!crops || !!imagesCrops);
}

export const ImageDetails: React.FC<{formType: MediaDetailsFormType}> = ({formType}) => {
  const history = useHistory();
  const [name, setName] = useState('');
  const tags = useTags();
  const [currentTag, setCurrentTag] = useState<Tag>();

  const [originalImage, setOriginalImage] = useState<Blob>();
  const [originalImageURL, setOriginalImageURL] = useState<string>();
  const [imageforSettingsURL, setImageForSettingsURL] = useState<string>();
  const [crops, setCrops] = useState<Crop[]>([]);
  const [imagesCrop, setImagesCrop] = useState<CropDto[]>([]);
  const [userClickedDelete, setUserClickedDelete] = useState<boolean>(false);

  const [saving, setSaving] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  const selectedMedia: MediaDto | null = useAppSelector((state: RootState) => state.mediaBank.selectedMedia, shallowEqual);

  useEffect(() => {
    if (formType === 'edit') {
      if (selectedMedia) {
        setName(selectedMedia.name);
        tags.initTag(selectedMedia.tags!);
        setOriginalImageURL(selectedMedia.url);
        setImageForSettingsURL(selectedMedia.originalImageUrl);
        setImagesCrop(selectedMedia.crops);
      }
    }
  }, []);

  useEffect(() => setCurrentTag(''), [tags.list]);

  const addCrop = (newCrop: Crop) => {
    const newCrops = crops.some((crop) => crop.type === newCrop.type) // if exist crop
      ? crops.map((crop) => (crop.type === newCrop.type ? newCrop : crop)) // replace crop
      : [...crops, newCrop]; // else push crop
    setCrops(newCrops);
  };

  const sendImage = async () => {
    if (validateSave(name, crops.length, imagesCrop.length)) {
      setSaving(true);

      if (formType === 'add') {
        const newImage: CreateMediaImageRequest = {
          name,
          originalImage: originalImage!,
          fullScreenLandscape: crops!.find((crop) => crop.type === 'full_screen_landscape')!.blob,
          fullScreenPortrait: crops!.find((crop) => crop.type === 'full_screen_portrait')!.blob,
          fullWidthRegular: crops!.find((crop) => crop.type === 'full_width_regular')!.blob,
          fullWidthTall: crops!.find((crop) => crop.type === 'full_width_tall')!.blob,
          squared: crops!.find((crop) => crop.type === 'squared')!.blob,
          crops: [],
          tags: tags.list,
        };
        try {
          const mediaImage = await createImage(newImage);
          dispatch(sendMessage({severity: 'success', summary: 'SUCCESS', detail: 'Image successfully created'}));

          history.push('/media-bank/');
        } catch (e) {
          dispatch(sendMessage({severity: 'error', summary: 'ERROR', detail: 'Something went wrong'}));
        }
      }

      if (formType === 'edit') {
        let newImage: UpdateMediaImageRequest;
        if (crops.length) {
          newImage = {
            name,
            tags: tags.list,
            crops: [],
            originalImage: originalImage!,
            fullScreenLandscape: crops!.find((crop) => crop.type === 'full_screen_landscape')!.blob,
            fullScreenPortrait: crops!.find((crop) => crop.type === 'full_screen_portrait')!.blob,
            fullWidthRegular: crops!.find((crop) => crop.type === 'full_width_regular')!.blob,
            fullWidthTall: crops!.find((crop) => crop.type === 'full_width_tall')!.blob,
            squared: crops!.find((crop) => crop.type === 'squared')!.blob,
          };
        } else {
          newImage = {
            name,
            tags: tags.list,
            crops: [],
          };
        }
        try {
          const mediaImage = await updateImage(selectedMedia!.id, newImage);
          dispatch(sendMessage({severity: 'success', summary: 'SUCCESS', detail: 'Image successfully edited'}));

          history.push('/media-bank/');
        } catch (e) {
          dispatch(sendMessage({severity: 'error', summary: 'ERROR', detail: 'Something went wrong'}));
        }
      }
    } else {
      dispatch(sendMessage({severity: 'warn', summary: 'WARNING', detail: 'Name and Image can not be empty.'}));
    }
  };

  const onCancelImageDetails = () => history.push('/media-bank');

  const onDeleteImage = async () => {
    if (!userClickedDelete) setUserClickedDelete(true);
    else {
      //hacer la llamada correspondiente
      try {
        await deleteMedia(selectedMedia!.id);
        dispatch(sendMessage({severity: 'success', summary: 'SUCCESS', detail: 'Image successfully deleted'}));
        history.push('/media-bank');
      } catch (e) {
        dispatch(sendMessage({severity: 'error', summary: 'ERROR', detail: 'Something went wrong'}));
      }
    }
  };

  return (
    <CursorLoading disable={saving}>
      <PageBody>
        <AddProgramForm>
          <FormElement type='input' label='Name' value={name} placeholder='Media name' onChange={setName} />
          <StyledFormElement>
            {/* <AutoComplete /> */}
            <label htmlFor={`input Tags`}>{'Tags'}</label>
            <InputButton>
              <InputComplete value={currentTag} onChange={(e) => setCurrentTag(e.target.value)} />
              <AddInput onClick={() => currentTag && tags.push(currentTag)}>Add</AddInput>
            </InputButton>
            <div style={{display: 'flex', flexDirection: 'row'}}>
              {tags.list?.map((tag) => (
                <Tag id={`${tag}_image`} color={'#008C8C'} onClick={() => tags.drop(tag)}>
                  {tag} x
                </Tag>
              ))}
            </div>
          </StyledFormElement>
        </AddProgramForm>
        <StyledFormElement>
          <StyledFormElement>
            {formType === 'edit' ? (
              <ImageCropperManager
                mediaType='image'
                setOriginalImage={setOriginalImage}
                addCrop={addCrop}
                imagesCrop={imagesCrop}
                image={originalImageURL}
                imageForSettings={imageforSettingsURL}
              />
            ) : (
              <ImageCropperManager mediaType='image' setOriginalImage={setOriginalImage} addCrop={addCrop} />
            )}
          </StyledFormElement>
        </StyledFormElement>
      </PageBody>
      <PageFooter>
        {formType === 'edit' && (
          <DeleteButton onClick={onDeleteImage} userClicked={userClickedDelete}>
            {userClickedDelete ? 'Delete ?' : 'Delete'}
          </DeleteButton>
        )}
        <CancelButton onClick={onCancelImageDetails}>
          <Link to='/media-bank/'>Cancel</Link>
        </CancelButton>
        <SaveButton disabled={saving} onClick={sendImage} disable={saving}>
          {saving ? 'Saving...' : 'Save'}
        </SaveButton>
      </PageFooter>
    </CursorLoading>
  );
};
