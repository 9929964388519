import React, {useContext, useEffect} from 'react';
import {Redirect, Route, useLocation, withRouter} from 'react-router-dom';
import App from './app';
import {Login} from '../../pages/login';
import {Error} from '../../pages/error';
import {NotFound} from '../../pages/not-found';
import {Access} from '../../pages/access';
import {AuthContext} from 'contexts/auth.context';

const AppWrapper: React.FC = () => {
  let location = useLocation();
  const {isAuthenticated} = useContext(AuthContext);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  switch (location.pathname) {
    case '/login':
      return <Route path='/login' component={Login} />;
    case '/error':
      return <Route path='/error' component={Error} />;
    case '/notfound':
      return <Route path='/notfound' component={NotFound} />;
    case '/access':
      return <Route path='/access' component={Access} />;
    default: {
      return isAuthenticated ? (
        <App />
      ) : (
        <Redirect
          to={{
            pathname: '/login',
            state: {from: location},
          }}
        />
      );
    }
  }
};

export default withRouter(AppWrapper);
