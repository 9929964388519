import React from 'react';

type VideoPlayerProps = {
  videoSrc: string;
};

const VideoPlayer: React.FC<VideoPlayerProps> = ({videoSrc}) => {
  return (
    <video width='390' controls>
      <source src={videoSrc} type='video/mp4' />
      Your browser does not support the video tag.
    </video>
  );
};

export default VideoPlayer;
