import React, {useState, useEffect} from 'react';
import {
  PageContainer as GlobalPageContaner,
  PageHeader,
  PageTitle,
  PageBody,
  AddProgramForm,
  PageFooter,
  CancelButton,
  SaveButton,
  DeleteButton,
  Label,
} from 'components/page';
import styled from 'styled-components';
import {getActivityTypesTools, patchActivityProgram} from 'api/chatowl.api';
import {Link, useHistory} from 'react-router-dom';
import {Dropdown} from 'primereact/dropdown';
import {StyledFormElement} from 'components/form-element';
import {InputSwitch} from 'primereact/inputswitch';
import {repeatValues, priorityValues} from '../../../utils/values';
import {useAppSelector} from 'hooks';
import {RootState} from 'index';
import {shallowEqual} from 'react-redux';

const PageContainer = styled(GlobalPageContaner)`
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  padding-left: 60px;
`;

const SwitchContainer = styled(StyledFormElement)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-right: 25%;
`;

export const EditActivity: React.FC = () => {
  const [savingProgram, setSavingProgram] = useState(false);
  const [type, setType] = useState<ActivityType | undefined>(undefined);
  const [tool, setTool] = useState<number | undefined>(1);
  const [priority, setPriority] = useState<number | undefined>(1);
  const [free, setFree] = useState<boolean | undefined>(true);
  const [locked, setLocked] = useState<boolean | undefined>(true);
  const [notifications, setNotifications] = useState<boolean | undefined>(true);
  const [emails, setEmails] = useState<boolean | undefined>(true);
  const [repeat, setRepeat] = useState<number | undefined>(86400);
  const [mandatory, setMandatory] = useState<boolean | undefined>(false);
  const [toolValues, setToolValues] = useState<ComboOptionDto[]>([]);
  const [typeValues, setTypeValues] = useState<ComboOptionDto[]>([]);

  const selectedActivity: ActivityDto | null = useAppSelector(
    (state: RootState) => state.activities.selectedActivity,
    shallowEqual
  );

  const selectedProgram: ProgramDto | null = useAppSelector((state: RootState) => state.programs.selectedProgram, shallowEqual);

  const history = useHistory();

  const fetchActivityTypesTools = async () => {
    try {
      const response = (await getActivityTypesTools()) as any;
      const types: ComboOptionDto[] = response.data.data;

      setTypeValues(types);
      setType(selectedActivity?.type);

      types.map((x) => {
        if (x.value === selectedActivity?.type && x.labels) {
          x.labels.map((y) => {
            if (y.value === selectedActivity?.activityTool?.id) {
              setToolValues(x.labels!);
              setTool(selectedActivity?.activityTool?.id);
            }
          });
        }
      });
    } catch (error) {
      throw error; // TODO
    }
  };

  useEffect(() => {
    fetchActivityTypesTools();

    setType(selectedActivity?.type);
    setTool(selectedActivity?.activityTool?.id);
    setPriority(selectedActivity?.priority ? selectedActivity?.priority : 1);
    setFree(selectedActivity?.isFree);
    setLocked(selectedActivity?.isLocked);
    setNotifications(selectedActivity?.sendsPushNotification);
    setEmails(selectedActivity?.sendsEmail);
    setRepeat(selectedActivity?.repeatInterval);
    setMandatory(selectedActivity?.isMandatory);
  }, [selectedActivity]);

  const onCancelProgram = () => {};

  const onSaveProgram = async () => {
    setSavingProgram(true);

    const activity = {
      toolId: tool,
      type,
      isFree: free,
      isLocked: locked,
      isMandatory: mandatory,
      repeatInterval: repeat,
      priority,
      sendsEmail: emails,
      sendsPushNotification: notifications,
      timeDue: {
        day: selectedActivity?.timeDue.day,
      },
    };

    const programId = selectedProgram?.id;

    if (!programId) throw Error();

    const activityId = selectedActivity?.id;

    if (!activityId) throw Error();

    await patchActivityProgram(programId, activityId, activity);

    setSavingProgram(false);
    history.push('/therapy-programs');
  };

  const onChangeType = (e: any) => {
    setType(e.value);
    const type = typeValues.find((x) => x.value === e.value);
    if (!!type && type.labels) {
      setToolValues(type.labels);
      setTool(parseInt(type.labels[0].value.toString()));
    }
  };

  return (
    <PageContainer>
      <PageHeader>
        <PageTitle>Edit Activity</PageTitle>
      </PageHeader>
      <PageBody>
        <AddProgramForm>
          <StyledFormElement>
            <Label>Type</Label>
            <Dropdown value={type} onChange={onChangeType} options={typeValues} optionLabel='name' />
          </StyledFormElement>
          <StyledFormElement>
            <Label>Tool</Label>
            <Dropdown value={tool} onChange={(e) => setTool(e.value)} options={toolValues} optionLabel='name' />
          </StyledFormElement>
          <StyledFormElement>
            <Label>Priority</Label>
            <Dropdown value={priority} onChange={(e) => setPriority(e.value)} options={priorityValues} optionLabel='name' />
          </StyledFormElement>
          {type === 'due' && (
            <>
              <StyledFormElement>
                <Label>Repeat</Label>
                <Dropdown value={repeat} onChange={(e) => setRepeat(e.value)} options={repeatValues} optionLabel='name' />
              </StyledFormElement>
              <SwitchContainer>
                <Label>Mandatory</Label>
                <InputSwitch checked={mandatory} onChange={(e) => setMandatory(e.value)} />
              </SwitchContainer>
            </>
          )}
          {type === 'add' && (
            <>
              <SwitchContainer>
                <Label>Free</Label>
                <InputSwitch checked={free} onChange={(e) => setFree(e.value)} />
              </SwitchContainer>
              <SwitchContainer>
                <Label>Locked</Label>
                <InputSwitch checked={locked} onChange={(e) => setLocked(e.value)} />
              </SwitchContainer>
            </>
          )}
          <SwitchContainer>
            <Label>Notifications</Label>
            <InputSwitch checked={notifications} onChange={(e) => setNotifications(e.value)} />
          </SwitchContainer>
          <SwitchContainer>
            <Label>Emails</Label>
            <InputSwitch checked={emails} onChange={(e) => setEmails(e.value)} />
          </SwitchContainer>
        </AddProgramForm>
        <StyledFormElement></StyledFormElement>
      </PageBody>
      <PageFooter>
        <DeleteButton>Delete</DeleteButton>
        <CancelButton onClick={onCancelProgram}>
          <Link to='/therapy-programs/'>Cancel</Link>
        </CancelButton>
        <SaveButton onClick={onSaveProgram}>Save</SaveButton>
      </PageFooter>
    </PageContainer>
  );
};
